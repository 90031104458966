import React from 'react'

export interface IRwTableCell {
  as?: 'td' | 'th'
  expand?: boolean | string | 'sm' | 'md' | 'lg' | 'xl' | 'xxl'
  align?: 'start' | 'center' | 'end'
  className?: string
  [x: string]: any
}

const RwTableCell: React.FC<IRwTableCell> = ({
  children,
  as = 'td',
  expand = false,
  align = 'start',
  className = '',
  ...rest
}) => {
  const displayClass = expand ? `d-none d-${expand}-table-cell` : ''
  const alignClass = align ? `text-${align}` : ''

  const isNegative = (value: any) => typeof value === 'number' && value < 0
  const cellStyle = isNegative(children)
    ? { color: 'red', fontWeight: 'bold' }
    : {}

  return (
    <>
      {as === 'td' ? (
        <td
          className={`${className} ${displayClass} ${alignClass}`}
          style={cellStyle}
          {...rest}
        >
          {children}
        </td>
      ) : (
        <th
          className={`${className} ${displayClass} ${alignClass}`}
          style={cellStyle}
          {...rest}
        >
          {children}
        </th>
      )}
    </>
  )
}

export default RwTableCell
