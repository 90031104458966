import { useQuery, useQueryClient } from '@tanstack/react-query'
import { AUTH_URL, API_CUSTOMERS_URL } from '../../context/apiconfig'
import toast from '../../components/molecules/RwToast'
import { ILoginData, IQueryClient, IUpdatedProfileInfo } from './types'
import { userKeys } from './keyFactory'
import { useUserContext } from '../../context/user/UserProvider'
import { documentKeys } from '../documents/keyFactory'
import { isDev } from '../../helpers/EnvDetect'
import { api } from '../../helpers/Api'

export function useGetAuthUser() {
  const { initializeUserState } = useUserContext()

  const queryClient = useQueryClient()
  const isDevEnv = isDev()

  return useQuery({
    queryKey: userKeys.user,
    queryFn: async () => {
      const response = await api.get(`${AUTH_URL}me`)
      return response?.data !== undefined ? response?.data : null
    },
    onError: (error) => {
      //NOTE: By clearing user and related data, it's like a logout.  ...so loss of signal will cause this quasi-logout.  So, keep stale time high.

      // axios 401 error (which this api returns if error) and no connection errors here

      // todo: test this... I think we want to clear user and customer if no response.

      // clean client
      if (!isDevEnv) {
        // I think we want to clear user and customer if no response / 401 in isDev.  but dev hot-updates also reload and throws error, so the condition is necessary (due to placement of CacheInitialization).

        queryClient.setQueryData(userKeys.user, null)
        queryClient.setQueryData(userKeys.status(), false)

        localStorage.removeItem('user')
        document.cookie = `document_token= ; path=/; max-age=-1;`

        queryClient.removeQueries(documentKeys.accountDocuments)

        queryClient.setQueryData(userKeys.userProfile(), null)
        initializeUserState()
      }
    },
    onSuccess: (data) => {
      if (data !== undefined && data) {
        queryClient.setQueryData(userKeys.user, data.user)
        queryClient.setQueryData(userKeys.status(), true)
        if (data.access_token) {
          localStorage.setItem('user', JSON.stringify(data))
        }
        document.cookie = `document_token=${data.document_token}; path=/`
      }
    },
    staleTime: 5 * 60 * 1000 * 60, //! 5 hours // keep high. see Note above.
  })
}

export function useStatusWatcher() {
  return useQuery({
    queryKey: userKeys.status(),
    queryFn: async () => {
      return null // initialize, so mutation is possible
    },
    staleTime: 5 * 60 * 1000 * 60, //! 5 hours
  })
}

//TODO fix this to use staff login
export function useGetUserProfile() {
  const queryClient = useQueryClient()

  return useQuery({
    queryKey: userKeys.userProfile(),
    queryFn: async () => {
      const response = await api.get(`${API_CUSTOMERS_URL}select-profile`)
      return response.data //! NEED TO CHECK, is this getting through? need the .data?
      //! ALSO what about this:
      // if (response?.status === 200) {
      //   return true
      // } else {
      //   return false
      // } // perhaps a hack. but need to use bc api response currently lacks "success: true/false". and using success response to pass on so a promise can be awaited.
    },
    onError: (error) => {
      // axios 401 error (which this api returns if error) and no connection errors here

      queryClient.setQueryData(userKeys.userProfile(), null)
    },
    onSuccess: (data) => {
      queryClient.setQueryData(userKeys.userProfile(), data)
    },
    staleTime: 5 * 60 * 1000 * 60, //! 5 hours
  })
}

export const updateUserProfile = async (variables: IUpdatedProfileInfo) => {
  const { updatedProfileInfo, queryClient } = variables

  const response = await api.post(
    `${API_CUSTOMERS_URL}update-profile`,
    updatedProfileInfo,
  )

  if (!response.data) {
    // toast.error(`${response.data.errors.join(' ') || 'An error occurred.'}`)
    toast.error(`${'An error occurred.'}`)
  } else {
    toast.success(`${response?.data?.message || 'Updated.'}`)

    queryClient.setQueryData(userKeys.userProfile(), response.data) //!<< this really needs to be checked
  }

  // if (!response?.data?.success) {
  // toast.error(`${response.data.errors.join(' ') || 'An error occurred.'}`)
  // } else if (response?.data?.success) {
  //   toast.success('Password changed.')
  // } //!the API isn't set up to give a success message yet. // OLDer CODE NOTES

  return response.data
}

export const loginUser = async (variables: ILoginData) => {
  const { username, password, otp, queryClient } = variables

  const response = await api.post(`${AUTH_URL}login`, {
    username: username,
    password: password,
    otp: otp,
  })

  if (!response?.data?.success) {
    const toastMsg =
      response.data.errors.join(' ') === 'Unauthorized'
        ? 'Your password and email do not match.'
        : response.data.errors.join(' ')
    toast.error(`${toastMsg || 'An error occurred.'}`)
    queryClient.setQueryData(userKeys.user, null)
    queryClient.setQueryData(userKeys.status(), false)

    // clean client
    localStorage.removeItem('user')
    // document.cookie = `document_token= ; path=/; max-age=-1;`
  } else if (response?.data?.success) {
    queryClient.setQueryData(userKeys.user, response.data.user)
    queryClient.setQueryData(userKeys.status(), true)
    if (response.data.access_token) {
      localStorage.setItem('user', JSON.stringify(response.data))
      // document.cookie = `document_token=${response.data.document_token}; path=/`
    }
    queryClient.invalidateQueries(userKeys.userProfile())
  }
  return response.data
}

export const logoutUser = async (variables: IQueryClient) => {
  const { queryClient } = variables

  const response = await api.post(`${AUTH_URL}logout`, null)

  if (!response || response?.status !== 200) {
    toast.error(`${'An error occurred.'}`)

    queryClient.setQueryData(userKeys.user, null)
    queryClient.setQueryData(userKeys.status(), false)
    queryClient.setQueryData(userKeys.userProfile(), null)

    localStorage.removeItem('user')
    // document.cookie = `document_token= ; path=/; max-age=-1;`
  } else {
    toast.success(`${response?.data?.message || 'Success.'}`)

    queryClient.setQueryData(userKeys.user, null)
    queryClient.setQueryData(userKeys.status(), false)
    queryClient.setQueryData(userKeys.userProfile(), null)

    localStorage.removeItem('user')
    // document.cookie = `document_token= ; path=/; max-age=-1;`
  }
  return response
}
