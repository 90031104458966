import { AxiosRequestHeaders } from 'axios'

export default function authHeader(): AxiosRequestHeaders {
  const userStr = localStorage.getItem('user')
  let user = null
  if (userStr) user = JSON.parse(userStr)

  if (user && user['access_token']) {
    return { Authorization: 'Bearer ' + user['access_token'] }
  } else {
    return {}
  }
}

export function authString(): string {
  const userStr = localStorage.getItem('user')
  let user = null
  if (userStr) user = JSON.parse(userStr)

  if (user && user['access_token']) {
    return 'Bearer ' + user['access_token']
  } else {
    return ''
  }
}
