import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useProductContext } from '../../../../context/products/ProductProvider'
import RwNavBar from '../../../molecules/RwNav/RwNavBar'
import RwNavItem from '../../../molecules/RwNav/RwNavItem'
import { Card, Col, Row } from 'react-bootstrap'
import ProductSearch from '../../../organisms/Product/ProductSearch'
import RwTable from '../../../molecules/RwTable'
import RwTableHead from '../../../molecules/RwTable/RwTableHead'
import RwTableRow from '../../../molecules/RwTable/RwTableRow'
import RwTableCell from '../../../molecules/RwTable/RwTableCell'
import DisplayCheck from '../../../atoms/DisplayCheck'
import RwTableCellCenter from '../../../molecules/RwTable/RwTableCellCenter'
import {
  IProductsListProduct,
  IProductListProductFilter,
} from '../../../../context/products/types'
import CdnImg from '../../../organisms/Common/CdnImg'
import RwTableBody from '../../../molecules/RwTable/RwTableBody'
import ProductListDetailForm from './detail-form'
import RwCard from '../../../molecules/RwCard'
import RowContextMenu from '../../../molecules/RwTable/RowContextMenu'
import RowContextMenuItem from '../../../molecules/RwTable/RowContextMenuItem'
import toast from '../../../molecules/RwToast'
import { useNavigate } from 'react-router-dom'
import {
  emptyOptionSelect,
  productListTypes,
  statusFilterOptions,
} from '../../../../constants/constants'
import SearchInput from '../../../molecules/SearchInput'
import useDebounce from '../../../../hooks/useDebounce'
import { DEFAULT_DEBOUNCE_DELAY } from '../../../../helpers'
import CustomSelect from '../../../molecules/Select/CustomSelect'
import { useCagetoriesForFilter } from '../../../../queries/products'
import RwNav from '../../../molecules/RwNav'

export const ProductListBreadcrumb: React.FC = () => {
  const { productsList } = useProductContext()

  return <span>{productsList?.label ? productsList.label : 'Loading...'}</span>
}

const ProductListDetail: React.FC = () => {
  const { id } = useParams()
  const navigate = useNavigate()

  const [productSearchTerm, setProductSearchTerm] = useState<string>('')
  const [searchParams, setSearchParams] = useState<IProductListProductFilter>({
    product_search_term: '',
    status: 'All',
    category_id: 'All',
  })
  const [isExplicitList, setIsExplicitList] = useState<boolean>(false)
  const [editMessage, setEditMessage] = useState<string>('')

  const debouncedProductSearchTerm = useDebounce(
    productSearchTerm,
    DEFAULT_DEBOUNCE_DELAY,
  )

  const { data: categoriesForFilter } = useCagetoriesForFilter()

  const {
    getProductsListDetail,
    productsList,
    clearProductsListContext,
    addProductToProductList,
    removeProductFromProductList,
  } = useProductContext()

  useEffect(() => {
    if (productsList && productsList.list_type_id) {
      switch (productsList?.list_type_id.toString()) {
        case productListTypes.explicit:
          setIsExplicitList(true)
          break
        case productListTypes.just_arrived:
          setIsExplicitList(false)
          setEditMessage(
            'The Just Arrived list is automatically generated based on product properties, modify the product in Product Editor instead.',
          )
          break
        case productListTypes.coming_soon:
          setIsExplicitList(false)
          setEditMessage(
            'The Coming Soon list is automatically generated based on product stock and cannot have products added or removed manually.',
          )
          break
        case productListTypes.deal:
          setIsExplicitList(false)
          setEditMessage(
            'Deal lists are automatically generated based on products in the deal. To add or remove from this list, you should edit the deal.',
          )
          break
        case productListTypes.closeout:
          setIsExplicitList(false)
          setEditMessage(
            'The Closeout list is based on closeout products and cannot have products added or removed. To add or remove from this list, please either put the product on closeout or remove it from closeout.',
          )
          break
        case productListTypes.product_year_added:
          setIsExplicitList(false)
          setEditMessage(
            "This list is based on the year the product was added and cannot have products added or removed. Either update the product year or create a new list for the product's year.",
          )
          break
      }
    }
  }, [productsList])

  useEffect(() => {
    if (id) {
      getProductsListDetail(id, searchParams)
    }

    return () => {
      clearProductsListContext()
    }
  }, [id, searchParams])

  useEffect(() => {
    if (
      debouncedProductSearchTerm.length > 2 ||
      debouncedProductSearchTerm.length === 0
    ) {
      setSearchParams({
        ...searchParams,
        product_search_term: debouncedProductSearchTerm,
      })
    }
  }, [debouncedProductSearchTerm])

  const handleClose = () => {
    navigate(-1)
  }

  // const handleDownloadSpreadsheet = () => {}

  // const handleUploadClick = () => {}

  const handleAddToList = (products_id: string) => {
    if (productsList && isExplicitList) {
      addProductToProductList(products_id, productsList.id, searchParams)
    } else {
      toast.error(editMessage)
    }
  }

  const handleRemoveFromList = (id: string) => {
    if (productsList && isExplicitList) {
      removeProductFromProductList(id, productsList.id, searchParams)
    } else {
      toast.error(editMessage)
    }
  }

  const initialValues = {
    id: productsList?.id || '',
    label: productsList?.label || '',
    seo_meta: productsList?.seo_meta || '',
    seo_title: productsList?.seo_title || '',
    is_active: productsList?.is_active || false,
    url: productsList?.url || '',
    start_date: productsList?.start_date || '',
    end_date: productsList?.end_date || '',
    list_type_id: productsList?.list_type_id || '',
    products_deal_id: productsList?.products_deal_id || '',
    products_list_year: productsList?.products_list_year || '',
  }

  const handleResetFilters = () => {
    setProductSearchTerm('')
    setSearchParams({
      product_search_term: '',
      status: 'All',
      category_id: '',
    })

    let searchInput = document.getElementById('searchInput') as HTMLInputElement
    searchInput.value = ''
  }

  const handleProductStatusFilter = (status: string) => {
    setSearchParams({ ...searchParams, status: status })
  }

  const handleProductCategoryFilter = (category_id: string) => {
    setSearchParams({ ...searchParams, category_id: category_id })
  }

  return (
    <>
      <RwNavBar>
        <RwNavItem variant="close" onClick={handleClose} />
        {/* <RwNavItem
          variant="excel"
          title="Product List Spreadsheet"
          onClick={handleDownloadSpreadsheet}
          fill
        />
        <RwNavItem variant="upload-file" onClick={handleUploadClick} /> */}
      </RwNavBar>

      <Row>
        <Col lg={3}>
          <RwCard className="m-3">
            <Card.Body>
              <ProductListDetailForm initialValues={initialValues} />
            </Card.Body>
          </RwCard>
          <ProductSearch
            handleButtonClick={handleAddToList}
            handleRowClick={handleAddToList}
            disabledProducts={productsList?.all_products}
            maxHeight="51vh"
            hideButton={!isExplicitList}
          />
        </Col>
        <Col lg={9}>
          <Card className="overflow-auto m-3" style={{ maxHeight: '85vh' }}>
            <RwNavBar expand="xxl">
              <RwNav className="mt-1">
                <SearchInput
                  id="searchInput"
                  handleChange={(text) => setProductSearchTerm(text)}
                />
              </RwNav>
              <RwNav>
                <CustomSelect
                  initialValue={searchParams.status}
                  title="Product Status"
                  width="180px"
                  handleSelect={handleProductStatusFilter}
                  options={statusFilterOptions}
                  hideClear
                />
              </RwNav>
              <RwNav>
                <CustomSelect
                  initialValue={searchParams.category_id}
                  title="Product Category"
                  width="300px"
                  handleSelect={handleProductCategoryFilter}
                  options={categoriesForFilter || emptyOptionSelect}
                  unknownValueDisplay="All"
                  hideClear
                />
              </RwNav>
              <RwNavItem
                variant="reset-filters"
                onClick={handleResetFilters}
                fill
              />
            </RwNavBar>
            <RwTable>
              <RwTableHead>
                <RwTableRow>
                  <RwTableCell as="th" />
                  <RwTableCell as="th">Model</RwTableCell>
                  <RwTableCell as="th">Name</RwTableCell>
                  <RwTableCell as="th">Primary Category</RwTableCell>
                  <RwTableCellCenter as="th">Active</RwTableCellCenter>
                  <RwTableCellCenter as="th" className="border-start">
                    <RowContextMenu rootClose>
                      <RowContextMenuItem
                        variant="remove all"
                        handleClick={() => handleRemoveFromList('all')}
                      />
                    </RowContextMenu>
                  </RwTableCellCenter>
                </RwTableRow>
              </RwTableHead>
              <RwTableBody>
                {productsList && productsList.filtered_products ? (
                  productsList.filtered_products.map(
                    (product: IProductsListProduct, index: number) => {
                      return (
                        <RwTableRow key={product.products_id}>
                          <RwTableCell>
                            <CdnImg
                              path={product.image_url}
                              height="50px"
                              width="50px"
                            />
                          </RwTableCell>
                          <RwTableCell>{product.products_model}</RwTableCell>
                          <RwTableCell>{product.products_name}</RwTableCell>
                          <RwTableCell>{product.categories_name}</RwTableCell>
                          <RwTableCellCenter>
                            <DisplayCheck value={product.products_status} />
                          </RwTableCellCenter>
                          <RwTableCellCenter className="border-start">
                            <RowContextMenu rootClose>
                              <RowContextMenuItem
                                variant="remove"
                                handleClick={() =>
                                  handleRemoveFromList(product.products_id)
                                }
                              />
                            </RowContextMenu>
                          </RwTableCellCenter>
                        </RwTableRow>
                      )
                    },
                  )
                ) : (
                  <RwTableRow>
                    <RwTableCell colSpan={4}>
                      No products found. Try adding some on the left or
                      adjusting product filters.
                    </RwTableCell>
                  </RwTableRow>
                )}
              </RwTableBody>
            </RwTable>
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default ProductListDetail
