import React from 'react'
import Modal from 'react-bootstrap/Modal'
import { IUserProfileData } from '../../../../context/iam/types'
import UserProfileSearch from '../../Iam/UserProfileSearch'

interface IUserProfileSearchModal {
  showModal: boolean
  setShowModal: (newShowValue: boolean) => void
  title?: string
  showClose?: boolean
  handleAdd: (item: IUserProfileData) => void
}

const UserProfileSearchModal: React.FC<IUserProfileSearchModal> = ({
  showModal,
  setShowModal,
  title = 'Select User Profile',
  showClose = true,
  handleAdd,
}) => {
  const handleCancelClick = () => {
    setShowModal(false)
  }

  return (
    <Modal
      className="p-0 m-0"
      show={showModal}
      onHide={handleCancelClick}
      animation={false}
    >
      {title || showClose ? (
        <Modal.Header closeButton={showClose}>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
      ) : (
        <></>
      )}

      <Modal.Body className="m-0 p-0">
        <UserProfileSearch handleAdd={handleAdd} />
      </Modal.Body>
    </Modal>
  )
}
export default UserProfileSearchModal
