import React from 'react'
import { Card, Form } from 'react-bootstrap'
import RwButton from '../../molecules/RwButton/RwButton'
import RwFormikInput from '../../molecules/RwFormikInput'
import RwFormikHiddenInput from '../../molecules/RwFormikHiddenInput'
import RwFormikCheckbox from '../../molecules/RwFormikCheckbox'
import RwFormikInputGroup from '../../molecules/RwFormikInputGroup'
import RwCard from '../../molecules/RwCard'
import RwNavBar from '../../molecules/RwNav/RwNavBar'
import * as Yup from 'yup'
import { Formik, FormikHelpers } from 'formik'
import toast from '../../molecules/RwToast'
import { api } from '../../../helpers/Api'
import { API_PRODUCTS_URL } from '../../../context/apiconfig'
import { useProductContext } from '../../../context/products/ProductProvider'
import { useNavigate } from 'react-router-dom'

interface IAssortmentDetailsFormProps {
  caseId: string | null
  version: string | null
}

const AssortmentDetailsForm: React.FC<IAssortmentDetailsFormProps> = ({
  caseId,
  version,
}) => {
  const navigate = useNavigate()
  const { customAssortmentDetails, getCustomAssortmentDetails } =
    useProductContext()

  const initialValues = {
    assortment_products_model:
      customAssortmentDetails?.assortment_products_model || '',
    assortment_products_name:
      customAssortmentDetails?.assortment_products_name || '',
    assortment_products_id:
      customAssortmentDetails?.assortment_products_id || '',
    assortment_is_active:
      customAssortmentDetails?.assortment_is_active || false,
    is_on_proline_site: customAssortmentDetails?.is_on_proline_site || false,
    is_on_wholesale_site:
      customAssortmentDetails?.is_on_wholesale_site || false,
    products_short_description:
      customAssortmentDetails?.products_short_description || '',
    products_description: customAssortmentDetails?.products_description || '',
    products_notes: customAssortmentDetails?.products_notes || '',
    products_url: customAssortmentDetails?.products_url || '',
  }

  const validationSchema = Yup.object({
    assortment_products_model: Yup.string()
      .required('Required')
      .max(12, 'Max 12 characters.'),
    assortment_products_name: Yup.string()
      .required('Required')
      .max(64, 'Max 64 characters.'),
    products_url: Yup.string()
      .required('Required')
      .matches(/^[a-zA-Z0-9-]+$/, 'URL must be alphanumeric with dashes'),
  })

  const generateUrl = (model: string, name: string) => {
    model = model.toUpperCase()
    name = name
      .toLowerCase()
      .replace(/[^a-zA-Z0-9-]+/g, '-')
      .replace(/^-+|-+$/g, '')
    return `${model}-${name}`
  }

  const onSubmit = async (values: any, submitProps: FormikHelpers<any>) => {
    if (caseId && version) {
      const response = await api.post(
        `${API_PRODUCTS_URL}save-custom-assortment-details`,
        {
          values,
        },
      )
      if (response.data.success) {
        toast.success(response.data.message)
        getCustomAssortmentDetails(caseId, version)
      }
    } else {
      const response = await api.post(
        `${API_PRODUCTS_URL}create-custom-assortment`,
        { values },
      )
      if (response.data.success) {
        toast.success(response.data.message)
        navigate(
          `/products/custom-assortment/${response.data.case_id}/${response.data.version_id}`,
        )
      } else {
        toast.error(
          response.data.errors
            ? response.data.errors.join('\n')
            : 'Error creating assortment',
        )
      }
    }
  }

  return (
    <RwCard className={'m-3'}>
      <RwNavBar title="Product Details" />
      <Card.Body className="flex-grow-1">
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
          enableReinitialize
        >
          {({ handleSubmit, values, setFieldValue }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <RwFormikHiddenInput name="assortment_products_id" />
              <RwFormikInput name="assortment_products_model" label="Model" />
              <RwFormikInput name="assortment_products_name" label="Name" />
              <RwFormikInput
                name="products_short_description"
                label="Short Description"
              />
              <RwFormikInput
                as="textarea"
                style={{ height: '167px' }}
                name="products_description"
                label="Long Description"
              />
              <RwFormikInput
                as="textarea"
                style={{ height: '167px' }}
                name="products_notes"
                label="Notes"
              />
              <RwFormikInputGroup
                name="products_url"
                label="URL (MODEL-product-name)"
                id="products_url"
                prefix={
                  <RwButton
                    variant="primary"
                    onClick={(e: any) => {
                      const url = generateUrl(
                        values.assortment_products_model,
                        values.assortment_products_name,
                      )
                      setFieldValue('products_url', url)
                    }}
                  >
                    Generate URL
                  </RwButton>
                }
              />
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  padding: '10px 30px 15px 30px',
                }}
              >
                <RwFormikCheckbox
                  type="switch"
                  name="assortment_is_active"
                  label="Active"
                />
                <RwFormikCheckbox
                  type="switch"
                  name="is_on_wholesale_site"
                  label="On Wholesale Site"
                />
                <RwFormikCheckbox
                  type="switch"
                  name="is_on_proline_site"
                  label="On Proline Site"
                />
              </div>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <RwButton
                  type="submit"
                  variant="primary"
                  style={{ fontWeight: 'bold', width: '80%' }}
                >
                  Save
                </RwButton>
              </div>
            </Form>
          )}
        </Formik>
      </Card.Body>
    </RwCard>
  )
}

export default AssortmentDetailsForm
