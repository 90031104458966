import { Field } from 'formik'
import React from 'react'
import { FloatingLabel, Form } from 'react-bootstrap'
import RwFormInput from './RwFormInput'

export interface RwFormikInputProps {
  name: string
  id?: string
  [x: string]: any
}

const RwFormikHiddenInput: React.FC<RwFormikInputProps> = (
  props: RwFormikInputProps,
) => {
  const { name, id = props.name, ...rest } = props

  return (
    <Field name={name}>
      {({ field, form, meta }: any) => {
        return (
          <Form.Control
            {...field}
            type="hidden"
            name={name}
            id={id}
            {...rest}
          />
        )
      }}
    </Field>
  )
}

export default RwFormikHiddenInput
