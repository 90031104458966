import React from 'react'

export interface IColorSwatch {
  color: string
  size?: string | null
  label?: string
  inline?: boolean
  className?: string | null
  [x: string]: any
}

const ColorSwatch: React.FC<IColorSwatch> = ({
  color,
  size = null,
  label = null,
  className,
  inline = false,
  ...rest
}) => {
  const labelClass = inline ? 'ps-1' : ''
  const swatchClass = inline ? 'd-flex' : 'text-center'
  const margin = inline ? '' : '0 auto'

  if (!size) {
    size = '15px'
  }

  return (
    <div className={` ${swatchClass}`}>
      <div
        {...rest}
        className="my-1 border rounded-circle"
        style={{
          width: size,
          height: size,
          backgroundColor: color,
          margin: margin,
        }}
      />
      {label ? (
        <div className={`text-nowrap my-auto ${labelClass}`}>{label}</div>
      ) : null}
    </div>
  )
}

export default ColorSwatch
