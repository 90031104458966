import { useLocation } from 'react-router-dom'
import HeaderNavBar from './HeaderNavBar'
import LogoHeader from './LogoHeader'
import { isHideNavPath } from '../../../helpers'

const Header = () => {
  const location = useLocation()

  return (
    <>{isHideNavPath(location.pathname) ? <LogoHeader /> : <HeaderNavBar />}</>
  )
}

export default Header
