import React from 'react'
import { stringToBoolean } from '../../../helpers'
import { BsCheck, BsX } from 'react-icons/bs'
import { useUserContext } from '../../../context/user/UserProvider'

export interface IDisplayCheck {
  value: boolean | string
  label?: string
  className?: string
  showFail?: boolean
  [x: string]: any
}

const DisplayCheck: React.FC<IDisplayCheck> = ({
  value,
  label,
  className,
  showFail = false,
  ...rest
}) => {
  const { isDarkMode } = useUserContext()
  const boolValue = typeof value == 'string' ? stringToBoolean(value) : value
  const icon = boolValue ? (
    <BsCheck
      style={{ fontSize: '30px' }}
      className={`${isDarkMode ? '' : 'text-success'} ${className}`}
    />
  ) : showFail ? (
    <BsX
      style={{ fontSize: '30px' }}
      className={`${isDarkMode ? '' : 'text-accent'} ${className}`}
    />
  ) : (
    <></>
  )

  return (
    <>
      {label ? (
        <div className="d-flex align-items-center justify-content-between">
          <span className="mr-1">{label}</span>
          {icon}
        </div>
      ) : (
        icon
      )}
    </>
  )
}

export default DisplayCheck
