import React, { useEffect } from 'react'
import RwTable from '../../../../molecules/RwTable'
import RwTableCell from '../../../../molecules/RwTable/RwTableCell'
import RwTableHead from '../../../../molecules/RwTable/RwTableHead'
import RwTableBody from '../../../../molecules/RwTable/RwTableBody'
import RwTableRow from '../../../../molecules/RwTable/RwTableRow'
import { usePricingContext } from '../../../../../context/pricing/PricingProvider'
import { useParams } from 'react-router-dom'
import { IProductPricingItem } from '../../../../../queries/pricing/types'

export interface PricingAlgorithmProps {
  product: IProductPricingItem
}

const PricingAlgorithm: React.FC<PricingAlgorithmProps> = ({ product }) => {
  return (
    <>
      <h1>Pricing Algorithm</h1>
      <RwTable hover>
        <RwTableHead>
          <RwTableRow>
            <RwTableCell as="th">Variable</RwTableCell>
            <RwTableCell as="th">Equation</RwTableCell>
            <RwTableCell as="th">Values</RwTableCell>
            <RwTableCell as="th">Result</RwTableCell>
          </RwTableRow>
        </RwTableHead>
        <RwTableBody>
          <RwTableRow>
            <RwTableCell>Shipping Allocation</RwTableCell>
            <RwTableCell>
              Product CBM x (Sum of Inbound Template Items / Inbound Template
              CBM)
            </RwTableCell>
            <RwTableCell>
              {product.products_cbm} m<sup>3</sup> x ({product.total_amount} /{' '}
              {product.cbm} m<sup>3</sup>)
            </RwTableCell>
            <RwTableCell>{product.shipping_allocation}</RwTableCell>
          </RwTableRow>
          <RwTableRow>
            <RwTableCell>Customs Allocation</RwTableCell>
            <RwTableCell>Product FOB Cost x Supplier FOB Customs</RwTableCell>
            <RwTableCell>
              {product.products_fob} x {product.fob_customs}
            </RwTableCell>
            <RwTableCell>{product.customs_allocation}</RwTableCell>
          </RwTableRow>
          <RwTableRow>
            <RwTableCell>AFSL Fee</RwTableCell>
            <RwTableCell>Supplier Per Case AFSL</RwTableCell>
            <RwTableCell>{product.per_case_afsl}</RwTableCell>
            <RwTableCell>{product.per_case_afsl}</RwTableCell>
          </RwTableRow>
          <RwTableRow>
            <RwTableCell>ForEx Factor</RwTableCell>
            <RwTableCell>
              Supplier Quoted ForEx Rate / ForEx Estimate for Pricing
            </RwTableCell>
            <RwTableCell>
              {product.quoted_forex_rate} / {product.estimate_for_pricing}
            </RwTableCell>
            <RwTableCell>{product.forex_factor} </RwTableCell>
          </RwTableRow>
          <RwTableRow>
            <RwTableCell>FOB Factor</RwTableCell>
            <RwTableCell>1 - Supplier/Manufacturer FOB Discount</RwTableCell>
            <RwTableCell>1 - {product.fob_discount}</RwTableCell>
            <RwTableCell>{product.fob_factor}</RwTableCell>
          </RwTableRow>
          <RwTableRow>
            <RwTableCell>Raw Material Adjustment Factor</RwTableCell>
            <RwTableCell>1 + Supplier Raw Material Adjustment</RwTableCell>
            <RwTableCell>1 + {product.raw_materials_adjustment}</RwTableCell>
            <RwTableCell>{product.raw_mat_adjustment}</RwTableCell>
          </RwTableRow>
          <RwTableRow>
            <RwTableCell>Adjusted FOB</RwTableCell>
            <RwTableCell>
              Product FOB Cost x ForEx Factor x Raw Material Adjustment Factor x
              FOB Factor
            </RwTableCell>
            <RwTableCell>
              {product.products_fob} x {product.forex_factor} x{' '}
              {product.raw_mat_adjustment} x {product.fob_factor}
            </RwTableCell>
            <RwTableCell>{product.adjusted_fob}</RwTableCell>
          </RwTableRow>
          <RwTableRow>
            <RwTableCell>Estimated Landed Cost</RwTableCell>
            <RwTableCell>
              Adjusted FOB + Shipping Allocation + Customs Allocation + AFSL Fee
            </RwTableCell>
            <RwTableCell>
              {product.adjusted_fob} + {product.shipping_allocation} +{' '}
              {product.customs_allocation} + {product.per_case_afsl}
            </RwTableCell>
            <RwTableCell>{product.estimated_landed_cost}</RwTableCell>
          </RwTableRow>
          <RwTableRow>
            <RwTableCell>Effective Multiplier</RwTableCell>
            <RwTableCell>
              Product Multiplier, <br />
              if 0 then Manufacturer Multiplier, <br />
              if 0 then Category Multiplier, <br />
              if 0 then 1
            </RwTableCell>
            <RwTableCell>
              Product Multiplier: {product.product_multiplier} <br />
              Manufacturer Multiplier: {product.manufacturer_multiplier} <br />
              Category Multiplier: {product.category_multiplier} <br />
            </RwTableCell>
            <RwTableCell>{product.effective_multiplier}</RwTableCell>
          </RwTableRow>
          <RwTableRow>
            <RwTableCell>Suggested Base Price</RwTableCell>
            <RwTableCell>
              Estimated Landed Cost x Effective Multiplier
            </RwTableCell>
            <RwTableCell>
              {product.estimated_landed_cost} x {product.effective_multiplier}
            </RwTableCell>
            <RwTableCell>{product.suggested_base_price}</RwTableCell>
          </RwTableRow>
          <RwTableRow>
            <RwTableCell>Estimated Selling Price</RwTableCell>
            <RwTableCell>Suggested Base Price x Realization Ratio</RwTableCell>
            <RwTableCell>
              {product.suggested_base_price} x {product.realization_ratio}
            </RwTableCell>
            <RwTableCell>{product.estimated_selling_price}</RwTableCell>
          </RwTableRow>
          <RwTableRow>
            <RwTableCell>Estimated Gross Profit</RwTableCell>
            <RwTableCell>
              Estimated Selling Price - Estimated Landed Cost
            </RwTableCell>
            <RwTableCell>
              {product.estimated_selling_price} - {product.estimated_landed_cost}
            </RwTableCell>
            <RwTableCell>{product.gross_profit}</RwTableCell>
          </RwTableRow>
          <RwTableRow>
            <RwTableCell>Estimated Gross Margin</RwTableCell>
            <RwTableCell>
              Estimated Gross Profit / Estimated Selling Price
            </RwTableCell>
            <RwTableCell>
              {product.gross_profit} / {product.estimated_selling_price}
            </RwTableCell>
            <RwTableCell>{product.margin}</RwTableCell>
          </RwTableRow>
        </RwTableBody>
      </RwTable>
    </>
  )
}

export default PricingAlgorithm
