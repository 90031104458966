import React from 'react'
import {
  generalBsBreakpoint,
  generalBsBreakpointValue,
} from '../../../constants/constants'

type Props = {
  children: JSX.Element
}

export const isMobile = window.innerWidth <= generalBsBreakpointValue // could try adding a listener if changing on resize is important: https://stackoverflow.com/a/60811141/10120439

export const Desktop: React.FC<Props> = ({ children }) => {
  return (
    <>
      <div className={`d-none d-${generalBsBreakpoint}-block`}>{children}</div>
    </>
  )
}

export const Mobile: React.FC<Props> = ({ children }) => {
  return (
    <>
      <div className={`d-${generalBsBreakpoint}-none`}>{children}</div>
    </>
  )
}

export const DesktopRender: React.FC<Props> = ({ children }) => {
  return isMobile ? (
    <></>
  ) : (
    <>
      <div className={`d-none d-${generalBsBreakpoint}-block`}>{children}</div>
    </>
  )
}

export const MobileRender: React.FC<Props> = ({ children }) => {
  return isMobile ? (
    <>
      <div className={`d-${generalBsBreakpoint}-none`}>{children}</div>
    </>
  ) : (
    <></>
  )
}
