import React, { useState } from 'react'
import UpdateHistoryIcon from '../Common/Icon/UpdateHistoryIcon'
import AuditLogModal from '../Common/Modal/AuditLogModal'

export interface IAuditLog {
  table: string
  keyValue: string
  fieldMap?: any
  hideFields?: Array<string>
  [x: string]: any
}

const AuditLog: React.FC<IAuditLog> = ({
  table,
  keyValue,
  fieldMap = null,
  hideFields = [],
  ...rest
}) => {
  const [showModal, setShowModal] = useState<boolean>(false)
  if (!keyValue || keyValue.toLowerCase() === 'new') {
    return <></>
  }

  return (
    <>
      <div
        className="ms-auto"
        onClick={() => {
          setShowModal(true)
        }}
      >
        <UpdateHistoryIcon />
      </div>
      <AuditLogModal
        showModal={showModal}
        handleClose={() => {
          setShowModal(false)
        }}
        table={table}
        keyValue={keyValue}
        title={`Update History`}
        fieldMap={fieldMap}
        hideFields={hideFields}
      />
    </>
  )
}

export default AuditLog
