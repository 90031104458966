import React, { useEffect } from 'react'
import CdnImg from '../Common/CdnImg'
import { useUserContext } from '../../../context/user/UserProvider'
import { Link } from 'react-router-dom'

const LogoHeader: React.FC = () => {
  const { isDarkMode, getDarkMode } = useUserContext()

  useEffect(() => {
    getDarkMode()
  }, [])

  const brandLogoPath = isDarkMode
    ? '_images/LOGO-SO76-002B-2022-compressed-cropped.png'
    : '_images/LOGO-SO76-002B-2022-compressed-cropped.png'

  return (
    <div className="text-center">
      <Link to="/">
        <CdnImg
          path={brandLogoPath}
          alt="logo"
          width="200"
          className="ps-2 pt-2 mb-4"
        />
      </Link>
    </div>
  )
}

export default LogoHeader
