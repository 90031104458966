import { createContext, useReducer, useContext } from 'react'
import {
  SET_SHOW_QUOTES_UPDATED,
  SET_SHOW_EDIT_SHIPPING_MODAL,
} from './constants'
import showHideReducer from './ShowHideReducer'
import { IContextProviderProps } from '../../types/interfaces'
import { IShowHideState } from './types'
import { uniqueId } from 'lodash'
import moment from 'moment'

export const initialState: IShowHideState = {
  showQuotesUpdated: false,
  setShowQuotesUpdated: () => {},
  showEditShippingModal: false,
  setShowEditShippingModal: () => {},
}

export const ShowHideContext = createContext<IShowHideState>(initialState)

export const useShowHideContext = () => useContext(ShowHideContext)

export const ShowHideProvider = (props: IContextProviderProps): JSX.Element => {
  const { children } = props

  const [state, dispatch] = useReducer(showHideReducer, initialState)

  const setShowQuotesUpdated = (showQuotesUpdated: boolean) => {
    dispatch({
      type: SET_SHOW_QUOTES_UPDATED,
      payload: showQuotesUpdated,
    })
  }

  const setShowEditShippingModal = (showEditShippingModal: boolean) => {
    dispatch({
      type: SET_SHOW_EDIT_SHIPPING_MODAL,
      payload: showEditShippingModal,
    })
  }

  const value = {
    showQuotesUpdated: state.showQuotesUpdated,
    setShowQuotesUpdated,
    showEditShippingModal: state.showEditShippingModal,
    setShowEditShippingModal,
  }

  return (
    <ShowHideContext.Provider value={value}>
      {children}
    </ShowHideContext.Provider>
  )
}
