import {
  GET_PERMISSION,
  SET_PERMISSIONS_FILTER,
  GET_ROLE,
  SET_ROLES_FILTER,
  GET_USER,
  SET_USER_PROFILES_FILTER,
  GET_USER_PROFILE,
} from './constants'
import { initialState } from './IamProvider'
import { IIamAction, IIamState } from './types'

export default function productReducer(
  state: IIamState = initialState,
  action: IIamAction,
): IIamState {
  const payload = action.payload

  switch (action.type) {
    case GET_PERMISSION:
      return {
        ...state,
        permission: payload,
      }

    case SET_PERMISSIONS_FILTER:
      return {
        ...state,
        permissionsFilter: payload,
      }

    case GET_ROLE:
      return {
        ...state,
        role: payload,
      }

    case SET_ROLES_FILTER:
      return {
        ...state,
        rolesFilter: payload,
      }

    case GET_USER:
      return {
        ...state,
        user: payload,
      }

    case GET_USER_PROFILE:
      return {
        ...state,
        userProfile: payload,
      }

    case SET_USER_PROFILES_FILTER:
      return {
        ...state,
        userProfilesFilter: payload,
      }

    default:
      return state
  }
}
