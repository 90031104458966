import React, { lazy, Suspense } from 'react'
import Loading from './'

const loadable = (importFunc) => {
  const LazyComponent = lazy(importFunc)

  return (props) => (
    // <Suspense fallback={<Loading />}>
    <Suspense>
      <LazyComponent {...props} />
    </Suspense>
  )
}

export default loadable
