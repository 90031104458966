import { createContext, useReducer, useContext } from 'react'
import {
  GET_PRICE_SUPPLIER,
  GET_PRICE_PRODUCTS_MULTIPLIER_SETTINGS,
  GET_PRICE_PRODUCT_MULTIPLIER_SETTINGS,
  GET_PRICE_FOREX_DETAIL_ITEM,
  GET_PRICE_INBOUND_TEMPLATE_ITEMS_CONTAINER_CHARGES,
  GET_PRICING_NOTES,
  GET_INBOUND_OCEAN_FREIGHT_OPTIONS,
  SET_MANUFACTURERS_FILTERS,
  SET_SUPPLIER_MANUFACTURER_DISCOUNT_FILTERS,
  SET_VERSION_FILTERS,
  PRICING_STATUSES,
  MANUFACTURERS_STATUSES,
  GET_MANUFACTURERS_PRICING,
  VERSION_STATUSES,
  GET_PRICE_INBOUND_FREIGHT_DETAIL_ITEM,
  GET_BASKET_OF_GOODS_ITEM,
  GET_VERSION,
  GET_PRODUCT_FOR_PRICING,
  GET_PRICING_CATEGORIES,
  GET_PRICING_MANUFACTURERS,
  GET_PRICING_SUPPLIERS,
  SET_CATEGORY_MULTIPLIERS_FILTERS,
} from './constants'
import pricingReducer from './PricingReducer'
import {
  IProductForPricing,
  IPricingState,
  IPricingProductMultiplierSettingsItem,
  IPriceInboundFreightDetailItem,
} from './types'
import { INoteData } from '../site/types'
import { API_PRICING_URL } from '../apiconfig'
import toast from '../../components/molecules/RwToast'
import { api } from '../../helpers/Api'
import {
  IManufacturersFilters,
  ISupplierManufacturerDiscountFilters,
  IPriceSupplierData,
  IPricingVersionData,
  IVersionFilters,
  IPricingForexListItem,
  IPricingForexDetailData,
  IPricingInboundTemplateItemsData,
  IPricingInboundTemplateItemsContainer,
  IPricingInboundTemplateItemsCharge,
  IPricingInboundTemplateItemsCharges,
  IContainerCharge,
  ICategoryMultipliersFilter,
} from '../../queries/pricing/types'
import axios from 'axios'

const PRICE_PRODUCT_MULTIPLIER_SETTINGS_API_URL =
  'price-product-multiplier/price-product-multiplier-settings'

const PRICE_FOREX_API_URL = 'price-forex/detail'
const PRICE_INBOUND_TEMPLATE_ITEMS_API_URL =
  'price-inbound-template-items/detail'

const PRICE_NOTES_API_URL = 'notes'

export const defaultSupplierManufacturerDiscountFilters: ISupplierManufacturerDiscountFilters =
  {
    suppliers_id: '',
    manufacturers_id: '',
  }

export const defaultManufacturersFilters: IManufacturersFilters = {
  pricing_status: PRICING_STATUSES.ALL,
  manufacturers_status: MANUFACTURERS_STATUSES.ACTIVE,
}

export const defaultVersionFilters: IVersionFilters = {
  status: VERSION_STATUSES.ALL,
  search_text: '',
  start_date: undefined,
  end_date: undefined,
}

export const defaultCategoryMultipliersFilters: ICategoryMultipliersFilter = {
  categories_id: '',
  pricing_status: PRICING_STATUSES.ALL,
}

export const initialState: IPricingState = {
  productForPricing: null,
  priceSupplier: null,
  priceProductMultiplierSettings: null,
  priceProductMultiplierSettingsItem: null,
  priceForexDetail: null,
  pricingSuppliers: [],
  pricingManufacturers: [],
  priceForexDetailItem: null,
  priceInboundTemplateItemsData: null,
  priceInboundTemplateItemsCharge: null,
  priceInboundTemplateItemsContainer: null,
  pricingInboundTemplateItemsContainerChargesData: null,
  pricingInboundTemplateItemsContainerChargesCharges: null,
  pricingInboundTemplateItemsContainerChargesChargeOptions: null,
  manufacturersFilters: defaultManufacturersFilters,
  supplierManufacturerDiscountFilters:
    defaultSupplierManufacturerDiscountFilters,
  versionFilters: defaultVersionFilters,
  categoryMultipliersFilter: defaultCategoryMultipliersFilters,
  pricingNotes: null,
  pricingCategories: [],
  inboundOceanFreightOptions: [],
  version: null,
  manufacturersPricing: [],
  priceInboundFreightDetailItem: null,
  basketOfGoodsItem: null,
  getProductForPricing: () => {},
  activateVersion: () => {},
  clearProductForPricing: () => {},
  updateProductBasePriceAndCosts: () => {},
  getPriceSupplier: () => {},
  savePriceSupplier: () => {},
  cleanupPriceSupplier: () => {},
  getPriceProductMultiplierSettings: () => {},
  downloadReviewExcel: () => {},
  savePriceProductMultiplierSettings: () => {},
  getPricingSuppliers: () => {},
  getPricingManufacturers: () => {},
  getPriceForexDetailItem: () => {},
  savePriceForexDetailItem: () => {},
  getPriceInboundTemplateItemsCharge: () => {},
  savePriceInboundTemplateItemsCharge: () => {},
  getPriceInboundTemplateItemsCharges: () => {},
  getPriceInboundTemplateItemsChargeOptions: () => {},
  getPriceInboundTemplateItemsContainerChargeData: () => {},
  savePriceInboundTemplateItemsContainerChargeData: () => {},
  getPricingNotes: () => {},
  // savePricingNotes: () => {},
  // updatePricingNote: () => {},
  savePricingNote: () => {},
  deletePricingNote: () => {},
  cleanupPricingNotes: () => {},
  clearPricingContext: () => {},
  getVersion: () => {},
  getBasketOfGoodsItem: () => {},
  getPriceInboundFreightDetailItem: () => {},
  clearInboundFreightDetailItem: () => {},
  setManufacturersFilters: () => {},
  setCategoryMultipliersFilter: () => {},
  setSupplierManufacturerDiscountFilters: () => {},
  setVersionFilters: () => {},
  savePriceInboundFreightDetailItem: () => {},
  getInboundOceanFreightOptions: () => {},
  getManufacturersPricing: () => {},
  getPricingCategories: () => {},
}

export const PricingContext = createContext(initialState)

export const usePricingContext = () => useContext(PricingContext)

export interface IPricingProviderProps {
  children?: React.ReactNode
}

export const PricingProvider = (props: IPricingProviderProps): JSX.Element => {
  const { children } = props

  const [state, dispatch] = useReducer(pricingReducer, initialState)

  const activateVersion = async (version_id: string | undefined) => {
    const response = await api.get(
      `${API_PRICING_URL}activate-version/${version_id}`,
    )

    if (response.data.success) {
      toast.success(response.data.message)
    } else {
      toast.error('Failed to activate.')
    }
  }

  const getPricingCategories = async () => {
    const response = await api.get(`${API_PRICING_URL}get-categories`)
    dispatch({
      type: GET_PRICING_CATEGORIES,
      payload: response.data,
    })
  }

  const downloadReviewExcel = async (version_id: string) => {
    try {
      const response = await axios.get(
        `${API_PRICING_URL}review/export/${version_id}`,
        {
          responseType: 'blob',
        },
      )

      const blob = new Blob([response.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      })

      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = `pricing_review_${version_id}_${new Date()
        .toLocaleString('en-US', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        })
        .replace(/\//g, '-')}.xlsx`
      link.click()
    } catch (error) {
      console.error('Error downloading review data:', error)
    }
  }

  const getProductForPricing = async (products_id: string) => {
    const response = await api.get(
      `${API_PRICING_URL}base-and-cost?products_id=${products_id}`,
    )

    if (response.data.success) {
      dispatch({
        type: GET_PRODUCT_FOR_PRICING,
        payload: response.data.data,
      })
    } else {
      dispatch({
        type: GET_PRODUCT_FOR_PRICING,
        payload: null,
      })
      toast.error(response.data.message)
    }
  }

  const updateProductBasePriceAndCosts = async (item: IProductForPricing) => {
    const response = await api.post(`${API_PRICING_URL}base-and-cost/save`, {
      item,
    })
    if (response.data.success) {
      toast.success(response.data.message)
      dispatch({
        type: GET_PRODUCT_FOR_PRICING,
        payload: response.data.data,
      })
    } else {
      toast.error(response.data.message)
    }
  }

  const clearProductForPricing = () => {
    dispatch({
      type: GET_PRODUCT_FOR_PRICING,
      payload: null,
    })
  }

  const getPricingSuppliers = async () => {
    const response = await api.get(`${API_PRICING_URL}get-suppliers`)
    dispatch({
      type: GET_PRICING_SUPPLIERS,
      payload: response.data,
    })
  }

  const getPricingManufacturers = async () => {
    const response = await api.get(`${API_PRICING_URL}get-manufacturers`)
    dispatch({
      type: GET_PRICING_MANUFACTURERS,
      payload: response.data,
    })
  }

  const getPriceSupplier = async (id: string | null) => {
    if (!id) return
    const response = await api.get(
      `${API_PRICING_URL}price-suppliers/detail?id=${id}`,
    )
    if (response.data.success) {
      dispatch({
        type: GET_PRICE_SUPPLIER,
        payload: response.data.data,
      })
    } else {
      toast.error(response.data.errors.join(' ') || 'An error occurred.')
    }
  }

  const savePriceSupplier = async (item: IPriceSupplierData) => {
    const response = await api.post(
      `${API_PRICING_URL}price-suppliers/detail/save`,
      {
        item,
      },
    )
    if (response.data.success) {
      toast.success(response.data.message)
      dispatch({
        type: GET_PRICE_SUPPLIER,
        payload: response.data.data,
      })
    } else {
      toast.error(response.data.message)
    }
  }

  const cleanupPriceSupplier = () => {
    dispatch({
      type: GET_PRICE_SUPPLIER,
      payload: null,
    })
  }

  const getPriceInboundFreightDetailItem = async (id: string) => {
    const response = await api.get(
      `${API_PRICING_URL}price-inbound-freight/details?detailItemId=${id}`,
    )
    dispatch({
      type: GET_PRICE_INBOUND_FREIGHT_DETAIL_ITEM,
      payload: response.data.data,
    })
  }

  const savePriceInboundFreightDetailItem = async (
    item: IPriceInboundFreightDetailItem,
  ) => {
    const response = await api.post(
      `${API_PRICING_URL}price-inbound-freight/details/save`,
      item,
    )

    if (!response?.data) {
      toast.error(`${response.data.errors.join(' ') || 'An error occurred.'}`)
      toast.error(`${'An error occurred.'}`)
    } else {
      toast.success(`${response?.data?.message || 'Saved.'}`)
      dispatch({
        type: GET_PRICE_FOREX_DETAIL_ITEM,
        payload: response?.data,
      })
    }
  }

  const clearInboundFreightDetailItem = async () => {
    dispatch({
      type: GET_PRICE_INBOUND_FREIGHT_DETAIL_ITEM,
      payload: null,
    })
  }

  const getInboundOceanFreightOptions = async () => {
    const response = await api.get(
      `${API_PRICING_URL}get-inbound-ocean-freight-options`,
    )
    dispatch({
      type: GET_INBOUND_OCEAN_FREIGHT_OPTIONS,
      payload: response.data,
    })
  }

  const getManufacturersPricing = async (version_id: string) => {
    const response = await api.get(
      `${API_PRICING_URL}get-manufacturers-pricing/${version_id}`,
    )
    dispatch({
      type: GET_MANUFACTURERS_PRICING,
      payload: response.data,
    })
  }

  //   const useGetPricingVersion(id: string) {
  //   return useQuery<IPricingVersionData, Error>({
  //     queryKey: pricingKeys.version(id),
  //     queryFn: async () => {
  //       let response = await api.get(`${API_PRICING_URL}version/${id}`)
  //       return response.data
  //     },
  //     keepPreviousData: true,
  //     staleTime: 3 * 1000,
  //   })
  // }

  const getVersion = async (version_id: string) => {
    const response = await api.get(`${API_PRICING_URL}version/${version_id}`)

    dispatch({
      type: GET_VERSION,
      payload: response.data,
    })
  }

  const getBasketOfGoodsItem = async (basket_of_goods_id: string) => {
    const response = await api.get(
      `${API_PRICING_URL}basket-of-goods/get-one/${basket_of_goods_id}`,
    )

    dispatch({
      type: GET_BASKET_OF_GOODS_ITEM,
      payload: response.data,
    })
  }

  const getPriceProductMultiplierSettings = async (id: string) => {
    const response = await api.get(
      `${API_PRICING_URL}${PRICE_PRODUCT_MULTIPLIER_SETTINGS_API_URL}/read/${id}`,
    )

    dispatch({
      type: GET_PRICE_PRODUCT_MULTIPLIER_SETTINGS,
      payload: response.data,
    })
  }

  const savePriceProductMultiplierSettings = async (
    priceProductMultiplierSettingsItem: IPricingProductMultiplierSettingsItem,
  ) => {
    const response = await api.post(
      `${API_PRICING_URL}${PRICE_PRODUCT_MULTIPLIER_SETTINGS_API_URL}/save`,
      priceProductMultiplierSettingsItem,
    )

    if (!response?.data) {
      toast.error(`${response.data.errors.join(' ') || 'An error occurred.'}`)
      toast.error(`${'An error occurred.'}`)
    } else {
      toast.success(`${response?.data?.message || 'Saved.'}`)
      dispatch({
        type: GET_PRICE_PRODUCT_MULTIPLIER_SETTINGS,
        payload: response?.data,
      })
    }
  }

  //TODO REMOVE BEGIN RICKS CODE
  const getPriceForexDetailItem = async (id: string) => {
    const response = await api.get(
      `${API_PRICING_URL}${PRICE_FOREX_API_URL}/read/${id}`,
    )
    dispatch({
      type: GET_PRICE_FOREX_DETAIL_ITEM,
      payload: response.data,
    })
  }

  const savePriceForexDetailItem = async (
    priceForexDetailItem: IPricingForexListItem,
  ) => {
    const response = await api.post(
      `${API_PRICING_URL}${PRICE_FOREX_API_URL}/save`,
      priceForexDetailItem,
    )

    if (!response?.data) {
      toast.error(`${response.data.errors.join(' ') || 'An error occurred.'}`)
      toast.error(`${'An error occurred.'}`)
    } else {
      toast.success(`${response?.data?.message || 'Saved.'}`)
      dispatch({
        type: GET_PRICE_FOREX_DETAIL_ITEM,
        payload: response?.data,
      })
    }
  }

  const getPriceInboundTemplateItemsCharge = async (id: string) => {
    const response = await api.get(
      `${API_PRICING_URL}${PRICE_INBOUND_TEMPLATE_ITEMS_API_URL}/read/${id}`,
    )
    dispatch({
      type: GET_PRICE_FOREX_DETAIL_ITEM,
      payload: response.data,
    })
  }

  const savePriceInboundTemplateItemsCharge = async (
    priceInboundTemplateItemsCharge: IPricingInboundTemplateItemsCharge,
  ) => {
    const response = await api.post(
      `${API_PRICING_URL}${PRICE_INBOUND_TEMPLATE_ITEMS_API_URL}/save`,
      priceInboundTemplateItemsCharge,
    )

    if (!response?.data) {
      toast.error(`${response.data.errors.join(' ') || 'An error occurred.'}`)
      toast.error(`${'An error occurred.'}`)
    } else {
      toast.success(`${response?.data?.message || 'Saved.'}`)
      dispatch({
        type: GET_PRICE_FOREX_DETAIL_ITEM,
        payload: response?.data,
      })
    }
  }

  const getPriceInboundTemplateItemsContainerChargeData = async (
    containerId: string,
  ) => {
    const response = await api.get(
      `${API_PRICING_URL}${PRICE_INBOUND_TEMPLATE_ITEMS_API_URL}/container-charges/read/${containerId}`,
    )
    dispatch({
      type: GET_PRICE_INBOUND_TEMPLATE_ITEMS_CONTAINER_CHARGES,
      payload: response.data,
    })
  }

  const savePriceInboundTemplateItemsContainerChargeData = async (
    versionId: string,
    containerId: string,
    updatedChargeInfo: any[],
  ) => {
    const data = { versionId, containerId, updatedChargeInfo }

    const response = await api.post(
      `${API_PRICING_URL}${PRICE_INBOUND_TEMPLATE_ITEMS_API_URL}/container-charges/save`,
      data,
    )
    if (!response?.data) {
      toast.error(`${response.data.errors.join(' ') || 'An error occurred.'}`)
      toast.error(`${'An error occurred.'}`)
    } else {
      toast.success(`${response?.data?.message || 'Saved.'}`)
      dispatch({
        type: GET_PRICE_INBOUND_TEMPLATE_ITEMS_CONTAINER_CHARGES,
        payload: response.data,
      })
    }
  }

  //TODO REMOVE END RICKS CODE

  const getPricingNotes = async (
    uiID: string,
    versionID: string,
    detailID: string | null,
  ) => {
    const response = await api.get(
      `${API_PRICING_URL}notes?ui_id=${uiID}&version_id=${versionID}&detail_id=${detailID}`,
    )

    if (response.data) {
      dispatch({
        type: GET_PRICING_NOTES,
        payload: response.data,
      })
    }
  }

  // const savePricingNotes = async (
  //   uiID: string,
  //   versionID: string,
  //   detailID: string | null,
  //   notes: string,
  // ) => {
  //   try {
  //     const response = await api.post(
  //       `${API_PRICING_URL}${PRICE_NOTES_API_URL}/save`,
  //       { ui_id: uiID, version_id: versionID, detail_id: detailID, notes },
  //     )
  //     if (response.data.success) {
  //       toast.success(response.data.message)
  //       dispatch({
  //         type: GET_PRICING_NOTES,
  //         payload: response.data.data,
  //       })
  //     } else {
  //       toast.error(response.data.message)
  //     }
  //   } catch (error) {
  //     toast.error('An error occurred. Your note was not saved.')
  //   }
  // }

  // const updatePricingNote = async (note: INoteData) => {
  //   try {
  //     const response = await api.post(
  //       `${API_PRICING_URL}${PRICE_NOTES_API_URL}/update`,
  //       { note },
  //     )
  //     if (response.data.success) {
  //       toast.success(response.data.message)
  //       dispatch({
  //         type: GET_PRICING_NOTES,
  //         payload: response.data.data,
  //       })
  //     } else {
  //       toast.error(response.data.message)
  //     }
  //   } catch (error) {
  //     toast.error('An error occurred.')
  //   }
  // }

  const savePricingNote = async (note: INoteData) => {
    try {
      const response = await api.post(
        `${API_PRICING_URL}${PRICE_NOTES_API_URL}/save`,
        note,
      )
      if (response.data.success) {
        toast.success(response.data.message)
        dispatch({
          type: GET_PRICING_NOTES,
          payload: response.data.data,
        })
      } else {
        toast.error(response.data.message)
      }
    } catch (error) {
      toast.error('An error occurred.')
    }
  }

  const deletePricingNote = async (note: INoteData) => {
    try {
      const response = await api.post(
        `${API_PRICING_URL}${PRICE_NOTES_API_URL}/delete`,
        { note },
      )
      if (response.data.success) {
        toast.success(response.data.message)
        dispatch({
          type: GET_PRICING_NOTES,
          payload: response.data.data,
        })
      } else {
        toast.error(response.data.message)
      }
    } catch (error) {
      toast.error('An error occurred.')
    }
  }

  const cleanupPricingNotes = () => {
    dispatch({
      type: GET_PRICING_NOTES,
      payload: null,
    })
  }

  const clearPricingContext = () => {
    dispatch({
      type: GET_PRICE_PRODUCT_MULTIPLIER_SETTINGS,
      payload: null,
    })
    dispatch({
      type: GET_PRICING_NOTES,
      payload: null,
    })
    dispatch({
      type: GET_PRICE_INBOUND_FREIGHT_DETAIL_ITEM,
      payload: null,
    })
    dispatch({
      type: GET_PRICE_SUPPLIER,
      payload: null,
    })
  }

  const setVersionFilters = async (versionFilters: IVersionFilters) => {
    dispatch({
      type: SET_VERSION_FILTERS,
      payload: versionFilters,
    })
  }

  const setCategoryMultipliersFilter = async (
    categoryMultipliersFilter: ICategoryMultipliersFilter,
  ) => {
    dispatch({
      type: SET_CATEGORY_MULTIPLIERS_FILTERS,
      payload: categoryMultipliersFilter,
    })
  }

  const setManufacturersFilters = async (
    manufacturersFilters: IManufacturersFilters,
  ) => {
    dispatch({
      type: SET_MANUFACTURERS_FILTERS,
      payload: manufacturersFilters,
    })
  }

  const setSupplierManufacturerDiscountFilters = async (
    supplierManufacturerDiscountFilters: ISupplierManufacturerDiscountFilters,
  ) => {
    dispatch({
      type: SET_SUPPLIER_MANUFACTURER_DISCOUNT_FILTERS,
      payload: supplierManufacturerDiscountFilters,
    })
  }

  const value = {
    ...state,
    getProductForPricing,
    clearProductForPricing,
    updateProductBasePriceAndCosts,
    getPriceSupplier,
    savePriceSupplier,
    cleanupPriceSupplier,
    getPriceProductMultiplierSettings,
    savePriceProductMultiplierSettings,
    getPriceForexDetailItem,
    savePriceForexDetailItem,
    savePriceInboundTemplateItemsContainerChargeData,
    getPricingNotes,
    // savePricingNotes,
    // updatePricingNote,
    savePricingNote,
    setVersionFilters,
    getVersion,
    getBasketOfGoodsItem,
    getPricingSuppliers,
    getPricingManufacturers,
    deletePricingNote,
    cleanupPricingNotes,
    getPriceInboundFreightDetailItem,
    clearInboundFreightDetailItem,
    savePriceInboundFreightDetailItem,
    setManufacturersFilters,
    setCategoryMultipliersFilter,
    activateVersion,
    setSupplierManufacturerDiscountFilters,
    getInboundOceanFreightOptions,
    getManufacturersPricing,
    clearPricingContext,
    getPricingCategories,
    downloadReviewExcel,
  }

  return (
    <PricingContext.Provider value={value}>{children}</PricingContext.Provider>
  )
}
