import React from 'react'
import RwCard from '../../molecules/RwCard'
import { Card, OverlayTrigger, Tooltip } from 'react-bootstrap'
import RwCloseButton from '../../molecules/RwButton/RwCloseButton'
import RwNavBar from '../../molecules/RwNav/RwNavBar'
import RwNavItem from '../../molecules/RwNav/RwNavItem'
import { BsX } from 'react-icons/bs'
import RwTable from '../../molecules/RwTable'
import RwTableCell from '../../molecules/RwTable/RwTableCell'
import RwTableCellCenter from '../../molecules/RwTable/RwTableCellCenter'
import RwTableHead from '../../molecules/RwTable/RwTableHead'
import RwTableBody from '../../molecules/RwTable/RwTableBody'
import RwTableRow from '../../molecules/RwTable/RwTableRow'
import toast from '../../molecules/RwToast'
import CdnImg from '../../organisms/Common/CdnImg'
import InputQuantityControl from '../../organisms/InputQuantityControl'
import { IAssortmentComponentData } from '../../../context/products/types'
import { useProductContext } from '../../../context/products/ProductProvider'
import { useUserContext } from '../../../context/user/UserProvider'
import { useNavigate } from 'react-router-dom'
import { api } from '../../../helpers/Api'
import { API_PRODUCTS_URL } from '../../../context/apiconfig'

const AssortmentComponentsList: React.FC = () => {
  const {
    customAssortmentDetails,
    componentsForAssortment,
    updateAssortmentComponent,
  } = useProductContext()
  const navigate = useNavigate()
  const { isDarkMode } = useUserContext()
  const navTitle =
    customAssortmentDetails?.assortment_products_model &&
    customAssortmentDetails?.assortment_products_name
      ? `${customAssortmentDetails?.assortment_products_model} ${customAssortmentDetails?.assortment_products_name}`
      : ''

  const handleCloneButtonClick = async () => {
    const response = await api.post(`${API_PRODUCTS_URL}clone-assortment`, {
      componentsForAssortment,
      customAssortmentDetails,
    })
    if (response.data.success) {
      toast.success('Assortment Cloned.')
      navigate(
        `/products/custom-assortment/${response.data.case_id}/${response.data.version_id}`,
      )
    } else {
      toast.error(response.data.errors.join('\n'))
    }
  }

  const handleComponentQuantityChange = (
    component: IAssortmentComponentData,
  ) => {
    updateAssortmentComponent(component)
  }

  const handleComponentExcludeFromPricingChange = (
    component: IAssortmentComponentData,
    exclude: boolean,
  ) => {
    component.exclude_from_pricing = exclude
    updateAssortmentComponent(component)
  }

  return (
    <RwCard className={'m-3'}>
      <RwNavBar title={navTitle}>
        {navTitle != '' ? (
          <RwNavItem
            variant="clone"
            onClick={() => {
              handleCloneButtonClick()
            }}
          />
        ) : (
          <></>
        )}
        <RwCloseButton
          style={{ marginLeft: 'auto' }}
          onClick={() => {
            navigate('/products/custom-assortments')
          }}
        />
      </RwNavBar>

      <Card.Body
        style={{
          maxHeight: '50vh',
          overflowY: 'auto',
        }}
      >
        <RwTable hover data={componentsForAssortment}>
          <RwTableHead>
            <RwTableRow>
              <RwTableCell as="th" style={{ width: '1%' }}></RwTableCell>
              <RwTableCell as="th">Product Model</RwTableCell>
              <RwTableCell as="th">Product Name</RwTableCell>
              <RwTableCell as="th">Base Price</RwTableCell>
              <RwTableCellCenter as="th">Quantity</RwTableCellCenter>
              <RwTableCellCenter as="th">
                Exclude From Pricing
              </RwTableCellCenter>
            </RwTableRow>
          </RwTableHead>
          <RwTableBody>
            {componentsForAssortment &&
              (componentsForAssortment as unknown as any[]).map(
                (component: IAssortmentComponentData, index: any) => {
                  return (
                    <RwTableRow key={index}>
                      <RwTableCell>
                        <CdnImg
                          height="50px"
                          path={
                            component.image_url
                              ? `_store/images/75/${component.image_url}`
                              : `_store/images/no_photo.png`
                          }
                        />
                      </RwTableCell>
                      <RwTableCell>{component.products_model}</RwTableCell>
                      <RwTableCell>{component.products_name}</RwTableCell>
                      <RwTableCell>
                        <div>
                          {component.base_price == '0.00'
                            ? 'Not Set'
                            : `$${component.base_price}`}
                        </div>
                      </RwTableCell>
                      {!(
                        customAssortmentDetails?.is_on_order &&
                        customAssortmentDetails?.ghost_order_id
                      ) ? (
                        <RwTableCellCenter
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                          align="center"
                        >
                          <OverlayTrigger
                            placement="left"
                            delay={{ show: 300, hide: 500 }}
                            overlay={<Tooltip>Remove From Assortment</Tooltip>}
                          >
                            <span
                              onClick={() => {
                                component.quantity = 0
                                handleComponentQuantityChange(component)
                              }}
                            >
                              <BsX
                                className={`fs-2 mt-1 ${
                                  isDarkMode ? 'text-light' : 'text-primary'
                                }`}
                              />
                            </span>
                          </OverlayTrigger>
                          <InputQuantityControl
                            key={component.products_id}
                            maxQuantity={99}
                            data={component}
                            handleQuantityChange={handleComponentQuantityChange}
                          />
                        </RwTableCellCenter>
                      ) : (
                        <RwTableCellCenter
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <InputQuantityControl
                            key={component.products_id}
                            maxQuantity={99}
                            data={component}
                            handleQuantityChange={handleComponentQuantityChange}
                            disabled={true}
                          />
                        </RwTableCellCenter>
                      )}
                      <RwTableCellCenter>
                        <input
                          type="checkbox"
                          onChange={(event) => {
                            handleComponentExcludeFromPricingChange(
                              component,
                              event.target.checked,
                            )
                          }}
                          checked={component.exclude_from_pricing}
                        />
                      </RwTableCellCenter>
                    </RwTableRow>
                  )
                },
              )}
          </RwTableBody>
        </RwTable>
      </Card.Body>
    </RwCard>
  )
}

export default AssortmentComponentsList
