import React from 'react'
import RwTableCell, { IRwTableCell } from './RwTableCell'

interface IRwTableCellEnd extends IRwTableCell {}

const RwTableCellEnd: React.FC<IRwTableCellEnd> = ({ children, ...rest }) => {
  return (
    <RwTableCell align="end" {...rest}>
      {children}
    </RwTableCell>
  )
}

export default RwTableCellEnd
