import { useQuery } from '@tanstack/react-query'
import { API_INVOICE_URL } from '../../context/apiconfig'
import { invoiceExportInvoicesKeys } from './keyFactory'
import { useQueryClient } from '@tanstack/react-query'
import { userKeys } from '../user/keyFactory'
import { IInvoiceExportData } from './types'
import { api } from '../../helpers/Api'

const INVOICE_EXPORT_API_URL = 'export/read'

export function useInvoiceExportInvoicesList() {
  const queryClient = useQueryClient()

  return useQuery<IInvoiceExportData, Error>({
    queryKey: invoiceExportInvoicesKeys.invoiceExportInvoices(),
    queryFn: async () => {
      let response = await api.get(
        `${API_INVOICE_URL}${INVOICE_EXPORT_API_URL}`,
      )
      return response.data
    },
    keepPreviousData: true,
    staleTime: 3 * 1000,
  })
}
