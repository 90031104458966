import { createContext, useReducer, useContext } from 'react'
import { GET_INVOICE_EXPORT_INVOICES } from './constants'
import accountingReducer from './AccountingReducer'
import { IInvoiceExportData, IAccountingState } from './types'
import { API_INVOICE_URL } from '../apiconfig'
import toast from '../../components/molecules/RwToast'
import { api } from '../../helpers/Api'
import axios from 'axios'

const EXPORT_API_URL = 'export'
const READ_API_URL = 'read'
const UPDATE_API_URL = 'update'

export const initialState: IAccountingState = {
  invoiceExportInvoices: null,
  getInvoiceExportInvoices: () => {},
  exportSelectedInvoiceExportInvoices: () => {},
  exportSelectedInvoicePdf: () => {},
  exportHighlightedInvoicePdf: () => {},
  markSelectedExportedVerified: () => {},
  markSelectedExportedUnverified: () => {},
  unmarkSelectedExportedUnverified: () => {},
  markSelectedNotProcessing: () => {},
  exportCheckedInvoicesPdfSingle: () => {},
  exportCheckedInvoicesPdf: () => {},
 

  clearAccountingContext: () => {},
}

export const AccountingContext = createContext(initialState)

export const useAccountingContext = () => useContext(AccountingContext)

export interface IAccountingProviderProps {
  children?: React.ReactNode
}

export const AccountingProvider = (
  props: IAccountingProviderProps,
): JSX.Element => {
  const { children } = props

  const [state, dispatch] = useReducer(accountingReducer, initialState)

  const getInvoice = async (id: string) => {
    const response = await api.get(`${API_INVOICE_URL}${READ_API_URL}/${id}`)

    dispatch({
      type: GET_INVOICE_EXPORT_INVOICES,
      payload: response.data,
    })
  }

  const getInvoiceExportInvoices = async () => {
    dispatch({
      type: GET_INVOICE_EXPORT_INVOICES,
      payload: null,
    })
  }

  const exportSelectedInvoiceExportInvoices = async (
    selectedInvoices: string[],
  ) => {
    const response = await api.post(
      `${API_INVOICE_URL}${EXPORT_API_URL}/exporttoqbo`,
      { selectedInvoices },
    )

    if (!response?.data) {
      toast.error(`${response.data.errors.join(' ') || 'An error occurred.'}`)
      toast.error(`${'An error occurred.'}`)
    } else {
      toast.success(`${response?.data?.message || 'Exported.'}`)
      getInvoiceExportInvoices()
    }
  }

  const markSelectedExportedVerified = async (selectedInvoices: string[]) => {
    const response = await api.post(
      `${API_INVOICE_URL}${EXPORT_API_URL}/markexportedverified`,
      { selectedInvoices },
    )

    if (!response?.data) {
      toast.error(`${response.data.errors.join(' ') || 'An error occurred.'}`)
      toast.error(`${'An error occurred.'}`)
    } else {
      toast.success(`${response?.data?.message || 'Marked.'}`)
      getInvoiceExportInvoices()
    }
  }

  const markSelectedExportedUnverified = async (selectedInvoices: string[]) => {
    const response = await api.post(
      `${API_INVOICE_URL}${EXPORT_API_URL}/markexportedunverified`,
      { selectedInvoices },
    )

    if (!response?.data) {
      toast.error(`${response.data.errors.join(' ') || 'An error occurred.'}`)
      toast.error(`${'An error occurred.'}`)
    } else {
      toast.success(`${response?.data?.message || 'Marked.'}`)
      getInvoiceExportInvoices()
    }
  }

  const unmarkSelectedExportedUnverified = async (
    selectedInvoices: string[],
  ) => {
    const response = await api.post(
      `${API_INVOICE_URL}${EXPORT_API_URL}/markprocessing`,
      { selectedInvoices },
    )

    if (!response?.data) {
      toast.error(`${response.data.errors.join(' ') || 'An error occurred.'}`)
      toast.error(`${'An error occurred.'}`)
    } else {
      toast.success(`${response?.data?.message || 'Marked.'}`)
      getInvoiceExportInvoices()
    }
  }

  const markSelectedNotProcessing = async (selectedInvoices: string[]) => {
    const response = await api.post(
      `${API_INVOICE_URL}${EXPORT_API_URL}/markpending`,
      { selectedInvoices },
    )

    if (!response?.data) {
      toast.error(`${response.data.errors.join(' ') || 'An error occurred.'}`)
      toast.error(`${'An error occurred.'}`)
    } else {
      toast.success(`${response?.data?.message || 'Marked.'}`)
      getInvoiceExportInvoices()
    }
  }

  const exportSelectedInvoicePdf = async () => {
    dispatch({
      type: GET_INVOICE_EXPORT_INVOICES,
      payload: null,
    })
  }

  const exportCheckedInvoicesPdfSingle = async (orders_ids: string) => {

    try {
      const response = await axios.get(
        `${API_INVOICE_URL}${EXPORT_API_URL}/exportpdfs?orders_ids=${orders_ids}`,
        {
          responseType: 'blob',
        },
      )

      const blob = new Blob([response.data], {
        type: 'application/pdf',
      })

      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = `invoice_report_${new Date()
        .toLocaleString('en-US', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        })
        .replace(/\//g, '-')}.pdf`
      link.click()
    } catch (error) {
      console.error('Error downloading invoice report:', error)
    }
  }

  const exportCheckedInvoicesPdf = async (orders_ids: string) => {

    try {
      const response = await axios.get(
        `${API_INVOICE_URL}${EXPORT_API_URL}/exportpdfsbatch?orders_ids=${orders_ids}`,
      )
    } catch (error) {
      console.error('Error downloading invoice reports:', error)
    }
  }

  const clearAccountingContext = async () => {
    dispatch({
      type: GET_INVOICE_EXPORT_INVOICES,
      payload: null,
    })
  }

  const value = {
    ...state,
    getInvoiceExportInvoices,
    exportSelectedInvoiceExportInvoices,
    markSelectedExportedVerified,
    markSelectedExportedUnverified,
    unmarkSelectedExportedUnverified,
    markSelectedNotProcessing,
    clearAccountingContext,
    exportCheckedInvoicesPdfSingle,
    exportCheckedInvoicesPdf,
  }

  return (
    <AccountingContext.Provider value={value}>
      {children}
    </AccountingContext.Provider>
  )
}
