import React, { ReactNode, useState } from 'react'
import { Container, Row, Col, Stack, Badge } from 'react-bootstrap'
import RwFormikCheckbox from '../../molecules/RwFormikCheckbox'
import { IProductEffect } from '../../../context/products/types'
import { useUserContext } from '../../../context/user/UserProvider'

export interface IProductEffects {
  effects: Array<IProductEffect>
  prefix: string
  label?: string | ReactNode
  [x: string]: any
}

const ProductEffects: React.FC<IProductEffects> = ({
  effects,
  prefix,
  label = null,
  edit = false,
  values,
}) => {
  const { isDarkMode } = useUserContext()

  if (!effects) {
    return <></>
  }

  return (
    <>
      {label && <div className="mb-1">{label}</div>}
      <div
        className={`border rounded p-3 mb-3 ${
          isDarkMode ? 'bg-dark' : 'bg-white'
        }`}
      >
        <Container fluid>
          <Row>
            {effects.map((effect: IProductEffect) => {
              return (
                <Col sm={12} md={6} key={effect.id}>
                  <RwFormikCheckbox
                    name={`${prefix}${effect.id}`}
                    label={effect.effect_name}
                  />
                </Col>
              )
            })}
          </Row>
        </Container>
      </div>
    </>
  )
}

export default ProductEffects
