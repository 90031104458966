import { createContext, useReducer, useContext } from 'react'
import { GET_QUOTE_MONTH_SETTINGS, GET_QUOTE_MILEAGE_DISCOUNTS_SETTINGS, GET_QUOTE_PALLET_DISCOUNTS_SETTINGS, GET_PICKUP_DATE_TIMES_SETTINGS } from './constants'
import deliveryReducer from './DeliveryReducer'
import { IDeliveryMonthSettingsData, IDeliveryState, IDeliveryMileageDiscountsSettingsData, IDeliveryPalletDiscountsSettingsData, IPickupDateTimesSettingsData } from './types'
import { API_FULFILLMENT_URL } from '../apiconfig'
import toast from '../../components/molecules/RwToast'
import { api } from '../../helpers/Api'

const QUOTE_MONTH_SETTINGS_API_URL = 'spirit-quote/month-settings'
const QUOTE_MONTHS_SETTINGS_API_URL = 'spirit-quote/months-settings'

const PICKUP_DATE_TIMES_SETTINGS_API_URL = 'pickup-date-times/month-settings'

const QUOTE_MILEAGE_DISCOUNTS_SETTINGS_API_URL = 'spirit-quote/mileage-discounts-settings'
const QUOTE_PALLET_DISCOUNTS_SETTINGS_API_URL = 'spirit-quote/pallet-discounts-settings'

export const initialState: IDeliveryState = {
  deliveryMonthSettings: null,
  getDeliveryMonthSettings: () => {},
  saveDeliveryMonthSettings: () => {},
  deleteDeliveryMonthSettings: () => {},
  cloneDeliveryMonthsSettings: () => {},
  newDeliveryMonthsSettings: () => {},

  deliveryMileageDiscountsSettings: null,
  getDeliveryMileageDiscountsSettings: () => {},
  saveDeliveryMileageDiscountsSettings: () => {},
  cloneDeliveryMileageDiscountsSettings: () => {},
  newDeliveryMileageDiscountsSettings: () => {},

  deliveryPalletDiscountsSettings: null,
  getDeliveryPalletDiscountsSettings: () => {},
  saveDeliveryPalletDiscountsSettings: () => {},
  cloneDeliveryPalletDiscountsSettings: () => {},
  newDeliveryPalletDiscountsSettings: () => {},

  pickupDateTimesSettings: null,
  getPickupDateTimesSettings: () => {},
  savePickupDateTimesSettings: () => {},

  clearDeliveryContext: () => {},

}

export const DeliveryContext = createContext(initialState)

export const useDeliveryContext = () => useContext(DeliveryContext)

export interface IDeliveryProviderProps {
  children?: React.ReactNode
}

export const DeliveryProvider = (props: IDeliveryProviderProps): JSX.Element => {
  const { children } = props

  const [state, dispatch] = useReducer(deliveryReducer, initialState)

    const getDeliveryMonthSettings = async (id: string) => {
       const response = await api.get(
         `${API_FULFILLMENT_URL}${QUOTE_MONTH_SETTINGS_API_URL}/${id}`,
       )

       dispatch({
         type: GET_QUOTE_MONTH_SETTINGS,
         payload: response.data,
       })
     }

   const cloneDeliveryMonthSettings = async (id: string) => {
      const response = await api.get(
        `${API_FULFILLMENT_URL}${QUOTE_MONTH_SETTINGS_API_URL}/clone/${id}`,
      )

      dispatch({
        type: GET_QUOTE_MONTH_SETTINGS,
        payload: response.data,
      })
    }

   const deleteDeliveryMonthSettings = async (id: string) => {
      const response = await api.get(
        `${API_FULFILLMENT_URL}${QUOTE_MONTH_SETTINGS_API_URL}/delete/${id}`,
      )

      dispatch({
        type: GET_QUOTE_MONTH_SETTINGS,
        payload: response.data,
      })
    }

  const saveDeliveryMonthSettings = async (deliveryMonthSettings: IDeliveryMonthSettingsData) => {
    const response = await api.post(
      `${API_FULFILLMENT_URL}${QUOTE_MONTH_SETTINGS_API_URL}/save`,
      deliveryMonthSettings,
    )

    if (!response?.data) {
      toast.error(`${response.data.errors.join(' ') || 'An error occurred.'}`)
      toast.error(`${'An error occurred.'}`)
    } else {
      toast.success(`${response?.data?.message || 'Saved.'}`)
      dispatch({
        type: GET_QUOTE_MONTH_SETTINGS,
        payload: response?.data,
      })
    }
  }

  const cloneDeliveryMonthsSettings = async (year: string) => {
    const response = await api.get(
      `${API_FULFILLMENT_URL}${QUOTE_MONTHS_SETTINGS_API_URL}/clone/${year}`,
    )
  }

  const newDeliveryMonthsSettings = async (year: string) => {
    const response = await api.get(
      `${API_FULFILLMENT_URL}${QUOTE_MONTHS_SETTINGS_API_URL}/new/${year}`,
    )
  }

  const saveDeliveryMileageDiscountsSettings = async (deliveryMileageDiscountsSettings: IDeliveryMileageDiscountsSettingsData) => {
    const response = await api.post(
      `${API_FULFILLMENT_URL}${QUOTE_MILEAGE_DISCOUNTS_SETTINGS_API_URL}/save`,
      deliveryMileageDiscountsSettings,
    )

    if (!response?.data) {
      toast.error(`${response.data.errors.join(' ') || 'An error occurred.'}`)
      toast.error(`${'An error occurred.'}`)
    } else {
      toast.success(`${response?.data?.message || 'Saved.'}`)
      dispatch({
        type: GET_QUOTE_MILEAGE_DISCOUNTS_SETTINGS,
        payload: response?.data,
      })
    }
  }

  const cloneDeliveryMileageDiscountsSettings = async (year: string) => {
    const response = await api.get(
      `${API_FULFILLMENT_URL}${QUOTE_MILEAGE_DISCOUNTS_SETTINGS_API_URL}/clone/${year}`,
    )
  }

  const getDeliveryMileageDiscountsSettings = async (year: string) => {
    const response = await api.get(
      `${API_FULFILLMENT_URL}${QUOTE_MILEAGE_DISCOUNTS_SETTINGS_API_URL}/get/${year}`,
    )
  }

  const newDeliveryMileageDiscountsSettings = async (year: string) => {
    const response = await api.get(
      `${API_FULFILLMENT_URL}${QUOTE_MILEAGE_DISCOUNTS_SETTINGS_API_URL}/new/${year}`,
    )
  }

  const saveDeliveryPalletDiscountsSettings = async (deliveryPalletDiscountsSettings: IDeliveryPalletDiscountsSettingsData) => {
    const response = await api.post(
      `${API_FULFILLMENT_URL}${QUOTE_PALLET_DISCOUNTS_SETTINGS_API_URL}/save`,
      deliveryPalletDiscountsSettings,
    )

    if (!response?.data) {
      toast.error(`${response.data.errors.join(' ') || 'An error occurred.'}`)
      toast.error(`${'An error occurred.'}`)
    } else {
      toast.success(`${response?.data?.message || 'Saved.'}`)
      dispatch({
        type: GET_QUOTE_PALLET_DISCOUNTS_SETTINGS,
        payload: response?.data,
      })
    }
  }

  const cloneDeliveryPalletDiscountsSettings = async (year: string) => {
    const response = await api.get(
      `${API_FULFILLMENT_URL}${QUOTE_PALLET_DISCOUNTS_SETTINGS_API_URL}/clone/${year}`,
    )
  }

  const getDeliveryPalletDiscountsSettings = async (year: string) => {
    const response = await api.get(
      `${API_FULFILLMENT_URL}${QUOTE_PALLET_DISCOUNTS_SETTINGS_API_URL}/get/${year}`,
    )
  }

  const newDeliveryPalletDiscountsSettings = async (year: string) => {
    const response = await api.get(
      `${API_FULFILLMENT_URL}${QUOTE_PALLET_DISCOUNTS_SETTINGS_API_URL}/new/${year}`,
    )
  }

  const clearDeliveryContext = async () => {
    dispatch({
      type: GET_QUOTE_MONTH_SETTINGS,
      payload: null,
    })
  }

  const getPickupDateTimesSettings = async (year: string, month: string) => {
    const response = await api.get(
      `${API_FULFILLMENT_URL}${PICKUP_DATE_TIMES_SETTINGS_API_URL}/${year}/${month}`,
    )

    dispatch({
      type: GET_PICKUP_DATE_TIMES_SETTINGS,
      payload: response.data.data,
    })
  }

const savePickupDateTimesSettings = async (pickupDateTimesSettings: IPickupDateTimesSettingsData) => {
 const response = await api.post(
   `${API_FULFILLMENT_URL}${PICKUP_DATE_TIMES_SETTINGS_API_URL}/save`,
   pickupDateTimesSettings,
 )

 if (!response?.data) {
   toast.error(`${response.data.errors.join(' ') || 'An error occurred.'}`)
   toast.error(`${'An error occurred.'}`)
 } else {
   toast.success(`${response?.data?.message || 'Saved.'}`)
   dispatch({
     type: GET_QUOTE_MONTH_SETTINGS,
     payload: response?.data,
   })
 }
}

  const value = {
    ...state,
    getDeliveryMonthSettings,
    saveDeliveryMonthSettings,
    deleteDeliveryMonthSettings,
    cloneDeliveryMonthsSettings,
    newDeliveryMonthsSettings,

    getDeliveryMileageDiscountsSettings,
    saveDeliveryMileageDiscountsSettings,
    cloneDeliveryMileageDiscountsSettings,
    newDeliveryMileageDiscountsSettings,

    getDeliveryPalletDiscountsSettings,
    saveDeliveryPalletDiscountsSettings,
    cloneDeliveryPalletDiscountsSettings,
    newDeliveryPalletDiscountsSettings,

    getPickupDateTimesSettings,
    savePickupDateTimesSettings,

    clearDeliveryContext,
  }

  return <DeliveryContext.Provider value={value}>{children}</DeliveryContext.Provider>
}
