import React from 'react'
import { IWarehousePallet } from '../../../context/inventory/types'

interface IPalletCard {
  pallet: IWarehousePallet
}

const PalletCard: React.FC<IPalletCard> = ({ pallet }) => {
  return (
    <div className="rw-inv-taker-pallet text-center p-1 bg-light border rounded">
      <div>{pallet.qty_cases_on_pallet}</div>
      <div>{pallet.upc_4}</div>
    </div>
  )
}

export default PalletCard
