const development: boolean =
  !process.env.NODE_ENV || process.env.NODE_ENV === 'development'
const prod: boolean = process.env.ENV_NAME === 'PROD'

export function isDev(): boolean {
  return development
}

export function isProd(): boolean {
  return prod
}
