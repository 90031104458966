import React from 'react'
import { Button } from 'react-bootstrap'
import { useUserContext } from '../../../context/user/UserProvider'

export interface IRwCloseButton {
  [x: string]: any
}

const RwCloseButton: React.FC<IRwCloseButton> = ({ children, ...rest }) => {
  const { isDarkMode } = useUserContext()

  return (
    <div
      className={`rw-close-button${isDarkMode ? '-dm' : ''} px-3 py-2 rounded`}
      {...rest}
    >
      {children ? children : 'Close'}
    </div>
  )
}

export default RwCloseButton
