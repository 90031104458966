import React from 'react'
import img from '../../../../assets/img/mock_loading_gif.gif'
const Loader = () => {
  return (
    <>
      <div className="loader-wrapper">
        <img width="200px" src={img} alt="loader" />
      </div>
    </>
  )
}

export default Loader
