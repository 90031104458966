import { useEffect, useState } from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import { useSiteContext } from '../../../../context/site/SiteProvider'
import RwIcon from '../../../molecules/RwIcon'
import { projectId } from '../../../../constants/db'

interface IModalDeadFish {}

const ModalDeadFish: React.FC<IModalDeadFish> = ({ ...rest }) => {
  const [showModal, setShowModal] = useState(false)
  const { appVersionCurrent, getAppInfo, setAppVersionCurrentStatus } =
    useSiteContext()
  const modalTitle = 'New Version Available'
  const modalBody = 'Please refresh your browser to get the latest version.'
  const modalConfirmButtonText = 'Refresh'

  useEffect(() => {
    setAppVersionCurrentStatus(true) // removes version from local storage
    getAppInfo(projectId) // grab once here, interval is in provider
  }, [])

  useEffect(() => {
    setShowModal(!appVersionCurrent)
  }, [appVersionCurrent])

  const handleConfirm = () => {
    window.location.reload()
  }

  // note: no handleCancel nor onHide in Modal bc we want to force the user to refresh
  return (
    <Modal show={showModal} animation={false}>
      <Modal.Header>
        <Modal.Title>{modalTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {modalBody}
        {/* <div className="d-flex flex-column align-items-center justify-content-center">
          <RwIcon icon="DeadFish" style={{ fontSize: '250px' }} />
        </div> */}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleConfirm}>
          {modalConfirmButtonText || 'Confirm'}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
export default ModalDeadFish
