import React, { useState, useEffect, useRef } from 'react'
import RwCard from '../../molecules/RwCard'
import RwTable from '../../molecules/RwTable'
import RwTableCell from '../../molecules/RwTable/RwTableCell'
import RwTableCellCenter from '../../molecules/RwTable/RwTableCellCenter'
import RwTableHead from '../../molecules/RwTable/RwTableHead'
import RwTableBody from '../../molecules/RwTable/RwTableBody'
import RwTableRow from '../../molecules/RwTable/RwTableRow'
import { Card, Form } from 'react-bootstrap'
import RwNavBar from '../../molecules/RwNav/RwNavBar'
import RwNavItem from '../../molecules/RwNav/RwNavItem'
import RwButton from '../../molecules/RwButton/RwButton'
import { Formik, FormikHelpers } from 'formik'
import RwFormikInputGroup from '../../molecules/RwFormikInputGroup'
import toast from '../../molecules/RwToast'
import InputQuantityControl from '../InputQuantityControl'
import {
  ICustomAssortmentGhostOrderData,
  IGhostOrderProduct,
} from '../../../context/products/types'
import { useProductContext } from '../../../context/products/ProductProvider'
import { api } from '../../../helpers/Api'
import { API_ORDERS_URL, API_PRODUCTS_URL } from '../../../context/apiconfig'
import { LONG_DEBOUNCE_DELAY } from '../../../helpers'

interface IAssortmentGhostOrderCardProps {
  caseId: string | null
  version: string | null
  handleAddButtonClick: (products_id: string) => void
}

const AssortmentGhostOrderCard: React.FC<IAssortmentGhostOrderCardProps> = ({
  caseId,
  version,
  handleAddButtonClick,
}) => {
  const {
    customAssortmentDetails,
    customAssortmentGhostOrder,
    componentsForAssortment,
    getCustomAssortmentDetails,
    getCustomAssortmentGhostOrder,
  } = useProductContext()

  const [maxAllowedReserve, setMaxAllowedReserve] = useState(0)
  const [initialReservedQuantity, setInitialReservedQuantity] = useState(0)
  const [resetFieldKey, setResetFieldKey] = useState(0)

  const initialReservedQuantityRef = useRef(0)
  const maxAllowedReserveRef = useRef(0)

  useEffect(() => {
    if (customAssortmentGhostOrder) {
      setMaxAllowedReserve(
        Math.min(
          ...customAssortmentGhostOrder.products.map((product) => {
            return (
              product.available_to_sell / product.quantity_on_assortment +
              customAssortmentGhostOrder.quantity
            )
          }),
        ),
      )
      setInitialReservedQuantity(customAssortmentGhostOrder.quantity)
    }
  }, [customAssortmentGhostOrder])

  useEffect(() => {
    initialReservedQuantityRef.current = initialReservedQuantity
    maxAllowedReserveRef.current = maxAllowedReserve
  }, [initialReservedQuantity, maxAllowedReserve])

  const initialValues = {
    ghost_order_id: customAssortmentDetails?.ghost_order_id || '',
  }

  const onSubmit = async (values: any, submitProps: FormikHelpers<any>) => {
    if (customAssortmentDetails?.assortment_products_id) {
      const response = await api.post(
        `${API_ORDERS_URL}assign-assortment-ghost-order`,
        {
          orders_id: values.ghost_order_id,
          product: customAssortmentDetails,
          components: componentsForAssortment,
        },
      )

      if (response.data.success) {
        toast.success(response.data.message)
        getCustomAssortmentDetails(caseId, version)
      }
    } else {
      toast.error('Please save the product details first')
    }
  }

  const handleGenerateGhostOrder = async () => {
    const response = await api.post(
      `${API_ORDERS_URL}assign-assortment-ghost-order`,
      {
        orders_id: null,
        product: customAssortmentDetails,
        components: componentsForAssortment,
      },
    )

    if (response.data.success) {
      toast.success(response.data.message)
      getCustomAssortmentDetails(caseId, version)
    }
  }

  const handleReservedQuantityChange = async (
    customAssortmentGhostOrder: ICustomAssortmentGhostOrderData,
  ) => {
    const newQuantityReserved = customAssortmentGhostOrder.quantity
    const quantityChange = newQuantityReserved - initialReservedQuantity
    let isChangeValid = true

    if (quantityChange > 0) {
      if (newQuantityReserved > maxAllowedReserveRef.current) {
        toast.error(
          'Quantity reserved exceeds available stock on one or more components',
        )
        isChangeValid = false
      }
    }

    if (!isChangeValid) {
      customAssortmentGhostOrder.quantity = initialReservedQuantityRef.current
      setResetFieldKey((prev) => prev + 1)
      return
    }

    const response = await api.post(
      `${API_PRODUCTS_URL}update-assortment-product-reserve`,
      {
        ghost_order: customAssortmentGhostOrder,
        current_qty_reserved: initialReservedQuantityRef.current,
      },
    )

    if (response.data.success) {
      toast.success(response.data.message)
      getCustomAssortmentDetails(caseId, version)
    } else {
      toast.error(response.data.message)
      customAssortmentGhostOrder.quantity = initialReservedQuantityRef.current
      setResetFieldKey((prev) => prev + 1)
    }
  }

  const handleRefresh = () => {
    if (customAssortmentDetails && caseId && version) {
      getCustomAssortmentGhostOrder({
        products_id: customAssortmentDetails?.assortment_products_id,
        caseId: caseId,
        version: version,
      })
    }
  }

  return (
    <RwCard className={'m-3'}>
      <RwNavBar title="Reserved Stock">
        {customAssortmentDetails?.ghost_order_id ? (
          <RwNavItem
            variant="refresh"
            onClick={() => {
              handleRefresh()
            }}
          />
        ) : (
          <></>
        )}
      </RwNavBar>
      <Card.Body
        style={{
          maxHeight: '35vh',
          overflowY: 'auto',
        }}
      >
        <div style={{ display: 'flex' }}>
          <span
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '20%',
              alignItems: 'center',
            }}
          >
            <Formik
              initialValues={initialValues}
              onSubmit={onSubmit}
              enableReinitialize
            >
              {({ handleSubmit }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <RwFormikInputGroup
                      name="ghost_order_id"
                      label="Order #"
                      id="ghost_order_id"
                      prefix={
                        !customAssortmentDetails?.ghost_order_id ? (
                          <RwButton
                            variant="primary"
                            onClick={handleGenerateGhostOrder}
                          >
                            Generate Order
                          </RwButton>
                        ) : (
                          <></>
                        )
                      }
                    />
                  </div>
                  {!customAssortmentDetails?.ghost_order_id ? (
                    <RwButton
                      type="submit"
                      variant="primary"
                      style={{ fontWeight: 'bold', width: '100%' }}
                    >
                      Save
                    </RwButton>
                  ) : (
                    <></>
                  )}
                </Form>
              )}
            </Formik>
            <br />
            {customAssortmentGhostOrder && (
              <div style={{ textAlign: 'center' }}>
                Quantity Reserved
                <InputQuantityControl
                  key={resetFieldKey}
                  maxQuantity={maxAllowedReserve}
                  data={customAssortmentGhostOrder}
                  handleQuantityChange={handleReservedQuantityChange}
                  debounceDelay={LONG_DEBOUNCE_DELAY}
                />
              </div>
            )}
          </span>
          <span
            style={{
              paddingLeft: '20px',
              display: 'flex',
              width: '80%',
            }}
          >
            {customAssortmentGhostOrder && (
              <RwTable hover data={customAssortmentGhostOrder.products}>
                <RwTableHead>
                  <RwTableRow>
                    <RwTableCell as="th">Product Model</RwTableCell>
                    <RwTableCell as="th">Product Name</RwTableCell>
                    <RwTableCellCenter as="th">
                      Stock Available
                    </RwTableCellCenter>
                    <RwTableCellCenter as="th">
                      <>
                        On Reserve Order
                        <br />
                        (Includes Ordered Assortments)
                      </>
                    </RwTableCellCenter>
                    <RwTableCell as="th"></RwTableCell>
                  </RwTableRow>
                </RwTableHead>
                <RwTableBody>
                  {customAssortmentGhostOrder.products &&
                    customAssortmentGhostOrder.products.map(
                      (product: IGhostOrderProduct, index: any) => {
                        return (
                          <RwTableRow key={index}>
                            <RwTableCell>{product.products_model}</RwTableCell>
                            <RwTableCell>{product.products_name}</RwTableCell>
                            <RwTableCellCenter
                              className={`${
                                product.available_to_sell <
                                product.quantity_on_assortment
                                  ? 'fw-bold text-accent'
                                  : ''
                              }`}
                            >
                              {product.available_to_sell}
                            </RwTableCellCenter>
                            <RwTableCellCenter>
                              {product.products_quantity}
                            </RwTableCellCenter>
                            <RwTableCell>
                              {!componentsForAssortment?.some(
                                (obj) =>
                                  obj.products_id === product.products_id,
                              ) ? (
                                <RwButton
                                  variant="primary"
                                  onClick={() =>
                                    handleAddButtonClick(product.products_id)
                                  }
                                >
                                  Add
                                </RwButton>
                              ) : (
                                <></>
                              )}
                            </RwTableCell>
                          </RwTableRow>
                        )
                      },
                    )}
                </RwTableBody>
              </RwTable>
            )}
          </span>
        </div>
      </Card.Body>
    </RwCard>
  )
}

export default AssortmentGhostOrderCard
