import React, { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { usePricingContext } from '../../../../context/pricing/PricingProvider'
import { IProductForPricing } from '../../../../context/pricing/types'
import ProductPricingSummary from '../../../../components/pages/pricing/summary/basket-of-goods/product-pricing-summary'

function Detail() {
  const { products_id } = useParams()
  const navigate = useNavigate()
  const [product, setProduct] = useState<IProductForPricing | null>(null)

  const handleClose = () => {
    navigate('/accounting/products-pricing')
  }

  const { productForPricing, getProductForPricing, clearProductForPricing } =
    usePricingContext()

  useEffect(() => {
    return () => {
      clearProductForPricing()
    }
  }, [])

  useEffect(() => {
    if (products_id) {
      getProductForPricing(products_id)
    }
  }, [products_id])

  useEffect(() => {
    if (productForPricing) {
      setProduct(productForPricing)
    }
  }, [productForPricing])

  if (!product) {
    return <></>
  }

  return (
    <div>
      <ProductPricingSummary title={'Pricing Projection'} product={product} handleClose={handleClose} />
    </div>
  )
}

export default Detail
