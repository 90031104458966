import { createContext, useReducer, useContext } from 'react'
import {
  GET_STAFF_ACCOUNT,
  GET_SALES_STAFF_SELECT_OPTIONS,
  GET_WAREHOUSE_ROLES,
} from './constants'
import staffReducer from './StaffReducer'
import { IStaffData, IStaffState, IUploadAvatar } from './types'
import { API_SETTINGS_URL } from '../apiconfig'
import toast from '../../components/molecules/RwToast'
import { api } from '../../helpers/Api'
import { IChangePasswordData } from '../user/types'

const STAFF_API_URL = 'staff-accounts'

export const initialState: IStaffState = {
  staffAccount: null,
  salesStaffSelectOptions: [],
  warehouseRoles: [],
  getWarehouseRoles: () => {},
  getStaffAccount: () => {},
  updateStaffAccount: () => {},
  deleteStaffAccount: () => {},
  clearStaffAccountContext: () => {},
  uploadAvatar: () => {},
  getSalesStaffSelectOptions: () => {},
  changePassword: () => {},
}

export const StaffContext = createContext(initialState)

export const useStaffContext = () => useContext(StaffContext)

export interface IStaffProviderProps {
  children?: React.ReactNode
}

export const StaffProvider = (props: IStaffProviderProps): JSX.Element => {
  const { children } = props

  const [state, dispatch] = useReducer(staffReducer, initialState)

  const getStaffAccount = async (staff_id: string) => {
    const response = await api.get(
      `${API_SETTINGS_URL}${STAFF_API_URL}/${staff_id}`,
    )

    dispatch({
      type: GET_STAFF_ACCOUNT,
      payload: response.data,
    })
  }

  const getWarehouseRoles = async () => {
    const response = await api.get(
      `${API_SETTINGS_URL}${STAFF_API_URL}/warehouse-roles`,
    )
    dispatch({
      type: GET_WAREHOUSE_ROLES,
      payload: response.data,
    })
  }

  const updateStaffAccount = async (staffAccount: IStaffData) => {
    try {
      const response = await api.post(
        `${API_SETTINGS_URL}${STAFF_API_URL}/update`,
        staffAccount,
      );
  
      if (!response?.data) {
        // Handle error case
        throw new Error('An error occurred.');
      } else {
        if (response.data.errors) {
          response.data.errors.forEach((error: string) => {
            toast.error(error);
          });
          throw new Error('Validation errors occurred.');
        } else {
          toast.success(`${response?.data?.message || 'Updated.'}`);
          dispatch({
            type: GET_STAFF_ACCOUNT,
            payload: response?.data,
          });
          
          // Return the new or updated staff_id
          if (response.data.password == "" || response.data.password == null) {
            return response.data.staff_id * -1;
          } else {
            return response.data.staff_id;
          }
          
        }
      }
    } catch (error) {
      throw error; // Re-throw the error to be handled by the caller
    }
  };

  const deleteStaffAccount = async (staff_id: string) => {
    const response = await api.delete(
      `${API_SETTINGS_URL}${STAFF_API_URL}/${staff_id}`,
    )

    if (!response?.data) {
      // toast.error(`${response.data.errors.join(' ') || 'An error occurred.'}`)
      // toast.error(`${'An error occurred.'}`)
    } else {
      if (response.data.errors) {
        response.data.errors.forEach((error: string) => {
          toast.error(error)
        })
      } else {
        toast.success(`${response?.data?.message || 'Deleted.'}`)
      }
    }
  }

  const clearStaffAccountContext = async () => {
    dispatch({
      type: GET_STAFF_ACCOUNT,
      payload: null,
    })
  }

  const uploadAvatar = async (uploadData: IUploadAvatar) => {
    // const formData = new FormData()
    // formData.append('filepath', uploadData.fileData)
    // formData.append('staff_id', uploadData.staff_id?.toString() || '')

    const response = await api.post(
      `${API_SETTINGS_URL}${STAFF_API_URL}/upload/avatar`,
      uploadData,
    )
    await getStaffAccount(uploadData.staff_id)
  }

  const getSalesStaffSelectOptions = async () => {
    const response = await api.get(
      `${API_SETTINGS_URL}${STAFF_API_URL}/sales/select-options`,
    )
    dispatch({
      type: GET_SALES_STAFF_SELECT_OPTIONS,
      payload: response.data,
    })
  }

  const changePassword = async (data: IChangePasswordData) => {
    const response = await api.post(
      `${API_SETTINGS_URL}${STAFF_API_URL}/change-staff-password`,
      data,
    )
  }

  const value = {
    ...state,
    getStaffAccount,
    getWarehouseRoles,
    updateStaffAccount,
    deleteStaffAccount,
    clearStaffAccountContext,
    uploadAvatar,
    getSalesStaffSelectOptions,
    changePassword,
  }

  return <StaffContext.Provider value={value}>{children}</StaffContext.Provider>
}
