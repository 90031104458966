import React, { useEffect, useState } from 'react'
import {
  Button,
  Card,
  ListGroup,
  ListGroupItem,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap'
import RwButton from '../../molecules/RwButton/RwButton'
import { useUserContext } from '../../../context/user/UserProvider'
import RwCard from '../../molecules/RwCard'
import useDebounce from '../../../hooks/useDebounce'
import { DEFAULT_DEBOUNCE_DELAY } from '../../../helpers'
import { ISearchProductsData } from '../../../context/products/types'
import CdnImg from '../../organisms/Common/CdnImg'
import { api } from '../../../helpers/Api'
import { API_PRODUCTS_URL } from '../../../context/apiconfig'
import RwInputGroup from '../../molecules/RwInputGroup'
import { BsX } from 'react-icons/bs'

interface IProductSearch {
  emptyText?: string
  minSearchLength?: number
  disabledProducts?: Array<any>
  hideImage?: boolean
  hideButton?: boolean
  buttonText?: string
  showAll?: boolean
  maxHeight?: string
  handleRowClick?: (products_id: string) => void
  handleRowDoubleClick?: (products_id: string) => void
  handleButtonClick?: (products_id: string) => void
}

const ProductSearch: React.FC<IProductSearch> = ({
  emptyText = 'No Products',
  minSearchLength = 3,
  disabledProducts = [],
  hideImage = false,
  hideButton = false,
  buttonText = 'Add',
  showAll = false,
  maxHeight = '',
  handleRowClick,
  handleRowDoubleClick,
  handleButtonClick,
}) => {
  const [products, setProducts] = useState<Array<ISearchProductsData>>([])
  const [searchTerm, setSearchTerm] = useState<string>('')

  const debouncedSearchTerm = useDebounce(searchTerm, DEFAULT_DEBOUNCE_DELAY)

  const { isDarkMode } = useUserContext()

  useEffect(() => {
    if (showAll) {
      searchProducts('')
    }
  }, [])

  useEffect(() => {
    if (
      debouncedSearchTerm.length >= minSearchLength ||
      debouncedSearchTerm.length === 0
    ) {
      searchProducts(debouncedSearchTerm)
    }
  }, [debouncedSearchTerm])

  const searchProducts = async (searchTerm: string) => {
    if ((searchTerm === '' && !showAll) || searchTerm === null) {
      setProducts([])
      return
    }

    const response = await api.get(
      `${API_PRODUCTS_URL}search-products?searchTerm=${searchTerm}&showAll=${showAll}`,
    )
    setProducts(response.data.data)
  }

  const handleClearSearch = () => {
    const elem = document.getElementById('search_text') as HTMLInputElement
    elem.value = ''
    setSearchTerm('')
    if (showAll) {
      searchProducts('')
    }
  }

  let currentCategory = ''

  const clearButton = (
    <Button variant="outline-secondary" onClick={handleClearSearch}>
      <OverlayTrigger
        placement={'right'}
        overlay={<Tooltip id={`tooltip-right`}>Clear</Tooltip>}
      >
        <div>
          <BsX />
        </div>
      </OverlayTrigger>
    </Button>
  )

  return (
    <RwCard className={'m-3'}>
      <Card.Header>
        <RwInputGroup
          id="search_text"
          name="search_text"
          placeholder="Search"
          suffix={clearButton}
          onChange={(e: any) => {
            if (
              e.target.value.length >= minSearchLength ||
              e.target.value.length === 0
            ) {
              setSearchTerm(e.target.value)
            }
          }}
        />
      </Card.Header>
      <Card.Body
        className="p-0"
        style={{
          maxHeight: maxHeight,
          overflowY: 'auto',
        }}
      >
        <ListGroup>
          {products.length === 0 ? (
            <div className="d-flex justify-content-center p-3">{emptyText}</div>
          ) : (
            products?.map((product: ISearchProductsData) => {
              let categoryHeader = null
              const addDisabled =
                product.is_multiple_products ||
                disabledProducts?.some(
                  (e) => e.products_id === product.products_id,
                )

              const opacity = addDisabled ? 0.5 : 1.0
              if (product.categories_name !== currentCategory) {
                currentCategory = product.categories_name
                  ? product.categories_name
                  : ''
                categoryHeader = (
                  <div className="text-center py-2 fw-bold">
                    {product.categories_name}
                  </div>
                )
              }
              return (
                <div key={product.products_id}>
                  {categoryHeader && (
                    <ListGroupItem className="border-bottom">
                      {categoryHeader}
                    </ListGroupItem>
                  )}
                  <ListGroupItem
                    className="border-bottom"
                    action
                    disabled={addDisabled}
                    onDoubleClick={() => {
                      handleRowDoubleClick &&
                        !addDisabled &&
                        handleRowDoubleClick(product.products_id)
                    }}
                    onClick={() => {
                      handleRowClick &&
                        !addDisabled &&
                        handleRowClick(product.products_id)
                    }}
                  >
                    <div className="d-flex" style={{ opacity: opacity }}>
                      {!hideImage && (
                        <span
                          style={{ width: '70px', display: 'inline-block' }}
                        >
                          <CdnImg
                            height="50px"
                            path={
                              product.image_url
                                ? `_store/images/75/${product.image_url}`
                                : `_store/images/no_photo.png`
                            }
                          />
                        </span>
                      )}
                      <div className="w-100 d-flex overflow-hidden">
                        <div className="overflow-hidden pe-2">
                          <div className="fw-bold">
                            {product.products_model}
                          </div>
                          <div className="text-truncate">
                            {product.products_name}
                          </div>
                        </div>
                      </div>
                      {handleButtonClick && !hideButton && (
                        <RwButton
                          disabled={addDisabled}
                          onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                            e.stopPropagation()
                            handleButtonClick(product.products_id)
                          }}
                        >
                          {buttonText}
                        </RwButton>
                      )}
                    </div>
                  </ListGroupItem>
                </div>
              )
            })
          )}
        </ListGroup>
      </Card.Body>
    </RwCard>
  )
}

export default ProductSearch
