import React from 'react'
import { Container, Navbar } from 'react-bootstrap'

export interface IRwNavBar {
  title?: string | null
  expand?: string | boolean
  [x: string]: any
}

const RwNavBar: React.FC<IRwNavBar> = ({
  children,
  title = null,
  expand = 'lg', // Change to desired breakpoint
  ...rest
}) => {
  return (
    <div className="border-bottom" {...rest}>
      <Navbar expand={expand}>
        <Container fluid>
          {title && <Navbar.Brand>{title}</Navbar.Brand>}
          {children ? (
            <>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                {children}
              </Navbar.Collapse>
            </>
          ) : null}
        </Container>
      </Navbar>
    </div>
  )
}

export default RwNavBar
