import React, { useEffect, useState } from 'react'
import { Button, Collapse, Stack } from 'react-bootstrap'
import { useSiteContext } from '../../../context/site/SiteProvider'
import { useUserContext } from '../../../context/user/UserProvider'
import { BsFillMoonFill, BsSunFill } from 'react-icons/bs'
import { INavMenuItem } from '../../../context/site/types'
import {
  BsChevronDown,
  BsChevronUp,
  BsFillLockFill,
  BsFillPeopleFill,
} from 'react-icons/bs'
import { Desktop } from '../../atoms/responsive'
import { useNavigate, useLocation } from 'react-router-dom'
import {
  hasActiveItem,
  isActiveItem,
  isEmbedded,
  isHideNavPath,
  truncateFilePath,
} from '../../../helpers'
import { isAuthorized } from '../../../helpers/Authorization'
import { useQueryClient } from '@tanstack/react-query'
import { userKeys } from '../../../queries/user/keyFactory'
import { IUserData } from '../../../queries/user/types'
import HeaderSpacer from '../Header/HeaderSpacer'
import Breadcrumbs from '../../molecules/Breadcrumbs'
import Routes from '../../../routes'

const MainContent: React.FC = () => {
  let freds = []
  for (let index = 0; index < 50; ++index) {
    freds.push(`FRED ${index}`)
  }
  const fredsList = freds.map((fred: string) => <div key={fred}>{fred}</div>)
  return (
    <div className="rw-main-content-div">
      <div className="rw-main-content-inner-container">
        <HeaderSpacer />
        <div className="rw-main-content-upper-div">
          <Breadcrumbs />
          <Routes />
        </div>
        <div className="rw-main-content-lower-div">
          <div className=""></div>
        </div>
      </div>
    </div>
  )
}

export default MainContent
