export const inventoryKeys = {
  all: ['inventoryList'] as const,
  inventoryList: (
    products_status: string | undefined,
    search_text: string | undefined,
    sort_by: string | undefined,
  ) => [...inventoryKeys.all, products_status, search_text, sort_by] as const,
  inventoryTakerList: (
    scanned: string | undefined,
    mismatch: string | undefined,
    verified: string | undefined,
    is_update_pushed: string | undefined,
    search_text: string | undefined,
    even_odd: string | undefined,
    sort_by: string | undefined,
  ) =>
    [
      ...inventoryKeys.all,
      scanned,
      mismatch,
      verified,
      is_update_pushed,
      search_text,
      even_odd,
      sort_by,
    ] as const,
  inventoryTakerLogList: (
    physical_inventory_id: string | undefined,
    search_text: string | undefined,
    even_odd: string | undefined,
    sort_by: string | undefined,
  ) =>
    [
      ...inventoryKeys.all,
      physical_inventory_id,
      search_text,
      even_odd,
      sort_by,
    ] as const,
}
