import {
  GET_MAIN_NAV_MENU_ITEMS,
  GET_TOP_NAV_MENU_ITEMS,
  GET_STORE_SETTINGS,
  GET_APP_INFO,
  SET_APP_VERSION_CURRENT,
  GET_APP_SETTINGS,
  TOGGLE_MENU_ITEM_OPEN,
  SET_SHOW_TOP_NAV,
  SET_SHOW_SIDE_NAV,
  SET_USER_SHOW_TOP_NAV,
  SET_USER_SHOW_SIDE_NAV,
  SET_USER_SHOW_CRUMBS,
  GET_AUDIT_LIST,
  GET_WAREHOUSE_PRINTERS,
} from './constants'
import { INavMenuItem, ISiteAction, ISiteState } from './types'

export default function siteReducer(state: ISiteState, action: ISiteAction) {
  const payload = action.payload

  switch (action.type) {
    case GET_MAIN_NAV_MENU_ITEMS:
      return {
        ...state,
        mainNavMenuItems: payload,
      }
    case GET_TOP_NAV_MENU_ITEMS:
      return {
        ...state,
        topNavMenuItems: payload,
      }
    case TOGGLE_MENU_ITEM_OPEN:
      return {
        ...state,
        mainNavMenuItems: state.mainNavMenuItems?.map((item: INavMenuItem) => {
          return item.id == payload ? { ...item, open: !item.open } : item
        }),
      }
    case GET_STORE_SETTINGS:
      return {
        ...state,
        storeSettings: payload,
      }
    case GET_APP_INFO:
      return {
        ...state,
        appInfo: payload,
      }
    case SET_APP_VERSION_CURRENT:
      return {
        ...state,
        appVersionCurrent: payload,
      }
    case GET_APP_SETTINGS:
      return {
        ...state,
        appSettings: payload,
      }

    case SET_SHOW_TOP_NAV:
      return {
        ...state,
        showTopNav: payload,
      }

    case SET_SHOW_SIDE_NAV:
      return {
        ...state,
        showSideNav: payload,
      }

    case SET_USER_SHOW_TOP_NAV:
      return {
        ...state,
        userShowTopNav: payload,
      }

    case SET_USER_SHOW_SIDE_NAV:
      return {
        ...state,
        userShowSideNav: payload,
      }

    case SET_USER_SHOW_CRUMBS:
      return {
        ...state,
        userShowCrumbs: payload,
      }

    case GET_AUDIT_LIST:
      return {
        ...state,
        auditList: payload,
      }
    case GET_WAREHOUSE_PRINTERS:
      return {
        ...state,
        warehousePrinters: payload,
      }
    default:
      return state
  }
}
