import React from 'react';

interface ErrorComponentProps {
  errors: any; // This should match the type of your Formik errors
}

const ErrorComponent: React.FC<ErrorComponentProps> = ({ errors }) => {
  const renderError = (error: any) => {
    if (typeof error === 'string') {
      return error;
    } else if (Array.isArray(error)) {
      return error.map((err, index) => (
        <ul key={index}>
          <li>{renderError(err)}</li>
        </ul>
      ));
    } else if (typeof error === 'object') {
      return Object.entries(error).map(([key, value]) => (
        <div key={key}>
          {key}: {renderError(value)}
        </div>
      ));
    }
    return null;
  };

  return (
    <div className="alert alert-danger">
      Please correct the following errors:
      <ul>
        {Object.entries(errors).map(([key, value]) => (
          <li key={key}>{renderError(value)}</li>
        ))}
      </ul>
    </div>
  );
};

export default ErrorComponent;
