import { createContext, useReducer, useContext } from 'react'
import {
  SET_INVENTORY_FILTER,
  SET_INVENTORY_TAKER_FILTER,
  SET_INVENTORY_TAKER_LOG_FILTER,
  PRODUCT_STATUSES,
  GET_PHYSICAL_INVENTORY,
  GET_PHYSICAL_INVENTORY_LIST,
} from './constants'
import { IInventoryState, IWarehouseLocationStage } from './types'
import inventoryReducer from './InventoryReducer'
import { IContextProviderProps } from '../../types/interfaces'
import {
  IInventoryFilter,
  IInventoryTakerFilter,
  IInventoryTakerLogFilter,
} from '../../queries/inventory/types'
import { API_INVENTORY_URL } from '../../context/apiconfig'
import axios from 'axios'
import { api } from '../../helpers/Api'
import toast from '../../components/molecules/RwToast'

export const defaultInventoryFilter: IInventoryFilter = {
  products_status: PRODUCT_STATUSES.ACTIVE,
  search_text: '',
  sort_by: 'products_model',
  limit: true,
}

export const defaultInventoryTakerFilter: IInventoryTakerFilter = {
  scanned: '',
  search_text: '',
  sort_by: 'location',
  limit: true,
}

export const defaultInventoryTakerLogFilter: IInventoryTakerFilter = {
  search_text: '',
  sort_by: 'location',
}

export const initialState: IInventoryState = {
  inventoryFilter: defaultInventoryFilter,
  inventoryTakerFilter: defaultInventoryTakerFilter,
  inventoryTakerLogFilter: defaultInventoryTakerLogFilter,
  physicalInventory: null,
  physicalInventoryList: [],
  setInventoryFilter: () => {},
  setInventoryTakerFilter: () => {},
  setInventoryTakerLogFilter: () => {},
  downloadInventoryReport: () => {},
  updateWarehouseLocation: () => {},
  pushStagingUpdates: () => {},
  getPhysicalInventoryList: () => {},
  getCurrentPhysicalInventory: () => {},
  physicalInventoryUpdateReport: () => {},
  resetLocation: () => {},
}

const downloadInventoryReport = async (filter: IInventoryFilter) => {
  try {
    const queryParams = Object.entries(filter)
      .filter(([_, value]) => value !== undefined)
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value as string)}`,
      )
      .join('&')

    const response = await axios.get(
      `${API_INVENTORY_URL}report?${queryParams}`,
      {
        responseType: 'blob',
      },
    )

    const blob = new Blob([response.data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    })

    const link = document.createElement('a')
    link.href = window.URL.createObjectURL(blob)
    link.download = `inventory_report_${new Date()
      .toLocaleString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      })
      .replace(/\//g, '-')}.xlsx`
    link.click()
  } catch (error) {
    console.error('Error downloading inventory report:', error)
  }
}

export const InventoryContext = createContext<IInventoryState>(initialState)

export const useInventoryContext = () => useContext(InventoryContext)

export const InventoryProvider = (
  props: IContextProviderProps,
): JSX.Element => {
  const { children } = props
  const [state, dispatch] = useReducer(inventoryReducer, initialState)

  const setInventoryFilter = async (inventoryFilter: IInventoryFilter) => {
    dispatch({
      type: SET_INVENTORY_FILTER,
      payload: inventoryFilter,
    })
  }

  const setInventoryTakerFilter = async (
    inventoryTakerFilter: IInventoryTakerFilter,
  ) => {
    dispatch({
      type: SET_INVENTORY_TAKER_FILTER,
      payload: inventoryTakerFilter,
    })
  }

  const setInventoryTakerLogFilter = async (
    inventoryTakerLogFilter: IInventoryTakerLogFilter,
  ) => {
    dispatch({
      type: SET_INVENTORY_TAKER_LOG_FILTER,
      payload: inventoryTakerLogFilter,
    })
  }

  const updateWarehouseLocation = async (
    location: IWarehouseLocationStage,
    callback: () => void,
  ) => {
    const response = await api.post(
      `${API_INVENTORY_URL}taker-update-location`,
      { ...location },
    )
    callback()
  }

  const pushStagingUpdates = async (callback: () => void) => {
    const response = await api.post(
      `${API_INVENTORY_URL}taker-push-updates`,
      {},
    )
    if (!response.data.success) {
      toast.error(response?.data?.message)
      callback()
    } else {
      toast.success(`${response?.data?.message || 'Updated.'}`)
      callback()
    }
  }

  const getCurrentPhysicalInventory = async () => {
    const response = await api.get(
      `${API_INVENTORY_URL}current-physical-inventory`,
    )
    dispatch({
      type: GET_PHYSICAL_INVENTORY,
      payload: response.data,
    })
  }

  const getPhysicalInventoryList = async () => {
    const response = await api.get(
      `${API_INVENTORY_URL}physical-inventory-list`,
    )
    dispatch({
      type: GET_PHYSICAL_INVENTORY_LIST,
      payload: response.data,
    })
  }

  const resetLocation = async (
    id: string,
    physical_inventory_id: string,
    callback: () => void,
  ) => {
    const response = await api.post(
      `${API_INVENTORY_URL}taker-reset-location`,
      { id, physical_inventory_id },
    )
    callback()
  }

  const physicalInventoryUpdateReport = async (
    physical_inventory_id: string,
  ) => {
    const response = await axios.get(
      `${API_INVENTORY_URL}taker-list-update-report/${physical_inventory_id}`,
      { responseType: 'arraybuffer' },
    )

    if (response.data) {
      const blob = new Blob([response.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      })
      const link = document.createElement('a')
      const filename = response.headers['content-disposition']
        .split('filename=')[1]
        .replace(/['"]+/g, '')

      link.href = window.URL.createObjectURL(blob)
      link.download = filename
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    } else {
      toast.error(response.data.message)
    }
  }

  const value = {
    ...state,
    setInventoryFilter,
    setInventoryTakerFilter,
    setInventoryTakerLogFilter,
    downloadInventoryReport,
    updateWarehouseLocation,
    pushStagingUpdates,
    getPhysicalInventoryList,
    getCurrentPhysicalInventory,
    physicalInventoryUpdateReport,
    resetLocation,
  }

  return (
    <InventoryContext.Provider value={value}>
      {children}
    </InventoryContext.Provider>
  )
}
