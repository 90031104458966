//! note: new one. as of rw-238
import React from 'react'
import { Form, FloatingLabel } from 'react-bootstrap'
import { Field } from 'formik'

export interface RwFormikOptionSelectProps {
  label: string
  hint?: string
  name?: string
  errors?: any
  options: string[] | object[]
  noErrorShift?: boolean
  hideErrors?: boolean
  flush?: boolean
  floatingLabel?: boolean
  [x: string]: any
}

const RwFormikOptionSelect: React.FC<RwFormikOptionSelectProps> = (
  props: RwFormikOptionSelectProps,
) => {
  const {
    placeholder,
    label,
    options,
    hint,
    name,
    noErrorShift = false,
    showFieldErrors = true,
    flush = false,
    floatingLabel = false,
    ...rest
  } = props

  const marginClass = flush ? '' : 'mb-2'

  return (
    <div className={marginClass}>
      <Field name={name}>
        {({ field, form, meta }: any) => {
          const isValid = !form.errors[field.name]
          const isInvalid = form.touched[field.name] && !isValid
          return label && floatingLabel ? (
            <Form.Group controlId={`${name}_form_group`}>
              <FloatingLabel label={label} className={`mb-2`}>
                <Form.Select
                  {...field}
                  isInvalid={isInvalid}
                  feedback={form.errors[field.name]}
                  aria-label="state-select"
                  name={name}
                  {...rest}
                >
                  {placeholder ? (
                    <option value="" disabled hidden>
                      {placeholder}
                    </option>
                  ) : (
                    <option value="" disabled hidden>
                      select option
                    </option>
                  )}
                  {options.map((datum: any) => (
                    <option value={`${datum.value}`} key={`${datum.value}`}>
                      {datum.label}
                    </option>
                  ))}
                </Form.Select>

                <Form.Text className="text-muted">{hint}</Form.Text>

                {noErrorShift ? (
                  <>
                    {!form.errors[field.name] ? (
                      <div style={{ minHeight: '25px' }}></div>
                    ) : (
                      <div
                        className="text-danger"
                        style={{ minHeight: '25px' }}
                      >
                        {form.errors[field.name]}
                      </div>
                    )}
                  </>
                ) : (
                  <></>
                )}

                {showFieldErrors && !noErrorShift ? (
                  <Form.Control.Feedback type="invalid">
                    {form.errors[field.name]}
                  </Form.Control.Feedback>
                ) : (
                  <></>
                )}
              </FloatingLabel>
            </Form.Group>
          ) : (
            <Form.Group controlId={`${name}_form_group`} className="mb-3">
              {label && <Form.Label className="mb-1">{label}</Form.Label>}
              <Form.Select
                {...field}
                isInvalid={isInvalid}
                feedback={form.errors[field.name]}
                aria-label="state-select"
                name={name}
                {...rest}
              >
                {placeholder ? (
                  <option value="" disabled hidden>
                    {placeholder}
                  </option>
                ) : (
                  <option value="" disabled hidden>
                    select option
                  </option>
                )}
                {options.map((datum: any) => (
                  <option value={`${datum.value}`} key={`${datum.value}`}>
                    {datum.label}
                  </option>
                ))}
              </Form.Select>

              <Form.Text className="text-muted">{hint}</Form.Text>

              {noErrorShift ? (
                <>
                  {!form.errors[field.name] ? (
                    <div style={{ minHeight: '25px' }}></div>
                  ) : (
                    <div className="text-danger" style={{ minHeight: '25px' }}>
                      {form.errors[field.name]}
                    </div>
                  )}
                </>
              ) : (
                <></>
              )}

              {showFieldErrors && !noErrorShift ? (
                <Form.Control.Feedback type="invalid">
                  {form.errors[field.name]}
                </Form.Control.Feedback>
              ) : (
                <></>
              )}
            </Form.Group>
          )
        }}
      </Field>
    </div>
  )
}

export default RwFormikOptionSelect
