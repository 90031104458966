import React from 'react'
import ProductColors from '../../../../../organisms/Product/ProductColors'
import ProductEffects from '../../../../../organisms/Product/ProductEffects'
import ProductFiringPatterns from '../../../../../organisms/Product/ProductFiringPatterns'
import ProductDebrisProfiles from '../../../../../organisms/Product/ProductDebrisProfiles'
import ProductCalibers from '../../../../../organisms/Product/ProductCalibers'
import RwFormikOptionSelect from '../../../../../molecules/RwFormikOptionSelect'
import {
  f3dTypeOptions,
  f3dSubtypeOptions,
  cobraTypeOptions,
  ignitionTypeOptions,
} from '../../../../../../context/products/constants'
import RwFormikInput from '../../../../../molecules/RwFormikInput'
import RwFormikCheckbox from '../../../../../molecules/RwFormikCheckbox'
import RwCard from '../../../../../molecules/RwCard'
import { Card, Container, Row, Col } from 'react-bootstrap'
import {
  COLOR_PREFIX,
  EFFECT_PREFIX,
  FIRING_PATTERN_PREFIX,
  DEBRIS_PROFILE_PREFIX,
} from '../../../../../../constants/constants'

interface ProLineFormProps {
  values: any // need to fix below update types
  productColors: any
  productFiringPatterns: any
  productEffects: any
  productDebrisProfiles: any
}

const ProLineForm: React.FC<ProLineFormProps> = ({
  values,
  productColors,
  productFiringPatterns,
  productEffects,
  productDebrisProfiles,
}) => {
  return (
    <div>
      <Container className="mt-3" fluid>
        <Row>
          <Col lg={12} xl={6} xxl={4}>
            <RwCard className="mb-3">
              <Card.Header className="fw-bold">Details</Card.Header>
              <Card.Body className="p-2">
                <RwFormikInput name="products_model" label="Model" />
                <RwFormikInput name="products_name" label="Name" />
                <RwFormikCheckbox
                  name="is_on_wholesale_site"
                  label="On Wholesale Site"
                />
                <RwFormikInput
                  as="textarea"
                  name="products_description"
                  label="Wholesale Description"
                  height="200px"
                />
                <RwFormikCheckbox
                  name="is_on_proline_site"
                  label="On Pro Line Site"
                />
                <RwFormikInput
                  as="textarea"
                  name="proline_description"
                  label="Pro Line Description"
                  height="200px"
                />
              </Card.Body>
            </RwCard>
          </Col>
          <Col lg={12} xl={6} xxl={4}>
            <RwCard className="mb-3">
              <Card.Header className="fw-bold">Regulatory</Card.Header>
              <Card.Body className="p-2">
                <Container fluid>
                  <Row>
                    <Col sm={12}>
                      <RwFormikInput
                        name="net_explosive_quantity"
                        label="Net Explosive Quantity"
                        type="number"
                      />
                      <RwFormikInput
                        name="products_ex_number"
                        label="EX Number(s)"
                      />
                      <RwFormikInput
                        name="products_un_number"
                        label="UN Number"
                      />
                      <RwFormikInput name="products_vdl" label="VDL" />
                      <RwFormikCheckbox
                        name="pro_use_only"
                        label="Pro Use Only"
                      />
                    </Col>
                  </Row>
                </Container>
              </Card.Body>
            </RwCard>
            <RwCard className="mb-3">
              <Card.Header className="fw-bold">
                Finale 3D / Cobra Specs
              </Card.Header>
              <Card.Body className="p-2">
                <RwFormikOptionSelect
                  placeholder="F3D Type"
                  label="F3D Type"
                  name="f3d_type"
                  options={f3dTypeOptions}
                />
                <RwFormikOptionSelect
                  placeholder="F3D Subtype"
                  label="F3D Subtype"
                  name="f3d_subtype"
                  options={f3dSubtypeOptions}
                />
                <RwFormikOptionSelect
                  placeholder="Cobra Type"
                  label="Cobra Type"
                  name="cobra_type"
                  options={cobraTypeOptions}
                />
              </Card.Body>
            </RwCard>
          </Col>
          <Col lg={12} xl={6} xxl={4}>
            <RwCard className="mb-3">
              <Card.Header className="fw-bold">Performance</Card.Header>
              <Card.Body className="p-2">
                <Container fluid>
                  <Row>
                    <Col sm={12}>
                      <RwFormikCheckbox
                        name="has_performance_attributes"
                        label="Has Performance Attributes"
                      />
                      <RwFormikCheckbox
                        name="is_outdoor_use_only"
                        label="Outdoor Use Only"
                      />
                      <RwFormikInput
                        name="products_duration"
                        label="Duration (seconds)"
                        type="number"
                      />
                      <RwFormikInput
                        name="products_shots"
                        type="number"
                        label="Shots"
                      />
                      <RwFormikInput
                        name="performance_height"
                        label="Performance Height (feet)"
                        type="number"
                      />
                      <RwFormikInput
                        name="performance_prefire_time"
                        label="Prefire (seconds)"
                        type="number"
                      />
                      <RwFormikOptionSelect
                        placeholder="None"
                        name="products_ignition"
                        label="Ignition Type"
                        options={ignitionTypeOptions}
                      />
                      <RwFormikInput
                        name="safety_distance"
                        label="Safety Distance (feet)"
                        type="number"
                      />
                      <ProductCalibers
                        label="Calibers"
                        calibers={values?.calibers}
                      />
                    </Col>
                  </Row>
                </Container>
              </Card.Body>
            </RwCard>
          </Col>
          <Col lg={12} xl={6} xxl={4}>
            <RwCard className="mb-3">
              <Card.Header className="fw-bold">Effects</Card.Header>
              <Card.Body className="p-2">
                <Container fluid>
                  <Row>
                    <Col sm={12}>
                      <ProductEffects
                        effects={productEffects}
                        prefix={EFFECT_PREFIX}
                        edit={true}
                        values={values}
                      />
                    </Col>
                  </Row>
                </Container>
              </Card.Body>
            </RwCard>
          </Col>
          <Col lg={12} xl={6} xxl={4}>
            <RwCard className="mb-3">
              <Card.Header className="fw-bold">Colors</Card.Header>
              <Card.Body className="p-2">
                <Container fluid>
                  <Row>
                    <Col sm={12}>
                      <ProductColors
                        colors={productColors}
                        prefix={COLOR_PREFIX}
                      />
                    </Col>
                  </Row>
                </Container>
              </Card.Body>
            </RwCard>
            <RwCard className="mb-3">
              <Card.Header className="fw-bold">Firing Patterns</Card.Header>
              <Card.Body className="p-2">
                <Container fluid>
                  <Row>
                    <Col sm={12}>
                      <ProductFiringPatterns
                        firing_patterns={productFiringPatterns}
                        prefix={FIRING_PATTERN_PREFIX}
                      />
                    </Col>
                  </Row>
                </Container>
              </Card.Body>
            </RwCard>
            <RwCard className="mb-3">
              <Card.Header className="fw-bold">Debris Profiles</Card.Header>
              <Card.Body className="p-2">
                <Container fluid>
                  <Row>
                    <Col sm={12}>
                      <ProductDebrisProfiles
                        debris_profiles={productDebrisProfiles}
                        prefix={DEBRIS_PROFILE_PREFIX}
                      />
                    </Col>
                  </Row>
                </Container>
              </Card.Body>
            </RwCard>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default ProLineForm
