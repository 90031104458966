import {} from './constants'
import { initialState } from './WarehouseProvider'
import { IWarehouseState, IWarehouseAction } from './types'

export default function warehouseReducer(
  state: IWarehouseState = initialState,
  action: IWarehouseAction,
): IWarehouseState {
  const payload = action.payload

  switch (action.type) {
    default:
      return state
  }
}
