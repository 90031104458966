import React, { JSXElementConstructor, ReactElement } from 'react'
import { Navigate } from 'react-router-dom'
import { IUserData } from '../queries/user/types'
import { useQueryClient } from '@tanstack/react-query'
import { userKeys } from '../queries/user/keyFactory'
import { isAuthorized } from '../helpers/Authorization'

export interface IProtectedRoute {
  permissions: Array<string>
  children:
    | Array<ReactElement<any, string | JSXElementConstructor<any>>>
    | ReactElement<any, string | JSXElementConstructor<any>>
}

const ProtectedRoute: React.FC<IProtectedRoute> = ({
  permissions,
  children,
}) => {
  const queryClient = useQueryClient()
  const user: IUserData = queryClient.getQueryData(userKeys.user) as IUserData

  if (permissions && !isAuthorized(user, permissions)) {
    return <Navigate to={'/not-authorized'} replace />
  }

  return <>{children}</>
}

export default ProtectedRoute
