import React, { useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import ProductPricingSummary from './product-pricing-summary'
import { usePricingContext } from '../../../../../context/pricing/PricingProvider'

export const BasketOfGoodsDetailBreadcrumb: React.FC = () => {
  const { basketOfGoodsItem } = usePricingContext()

  return (
    <span>
      {basketOfGoodsItem?.products_model
        ? `${basketOfGoodsItem.products_model} - ${
            basketOfGoodsItem.products_name || 'Unnamed Product'
          }`
        : 'Loading...'}
    </span>
  )
}

function Detail() {
  const { basket_of_goods_id, version_id } = useParams()
  const navigate = useNavigate()

  const handleClose = () => {
    navigate(`/accounting/product-pricing-projection-versions/${version_id}`)
  }

  const { basketOfGoodsItem, getBasketOfGoodsItem } = usePricingContext()

  useEffect(() => {
    if (basket_of_goods_id) {
      getBasketOfGoodsItem(basket_of_goods_id)
    }
  }, [basket_of_goods_id])

  if (!basketOfGoodsItem) {
    return <></>
  }

  return (
    <ProductPricingSummary title={'Basket of Goods'} product={basketOfGoodsItem} handleClose={handleClose} />
  )
}

export default Detail
