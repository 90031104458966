import { useQuery } from '@tanstack/react-query'
import { API_INVENTORY_URL } from '../../context/apiconfig'
import { inventoryKeys } from './keyFactory'
import {
  IInventoryFilter,
  IInventoryTakerFilter,
  IInventoryTakerLogFilter,
} from './types'
import { api } from '../../helpers/Api'
import { IInventoryProduct } from './types'
import {
  IWarehouseLocation,
  IWarehouseLocationStage,
} from '../../context/inventory/types'

export function useInventoryList(filter: IInventoryFilter) {
  return useQuery<IInventoryProduct[], Error>({
    queryKey: inventoryKeys.inventoryList(
      filter.products_status,
      filter.search_text,
      filter.sort_by,
      //add more here
    ),
    queryFn: async () => {
      try {
        let response = await api.post(`${API_INVENTORY_URL}list`, {
          ...filter,
        })
        return response.data
      } catch (error) {
        console.error('Error fetching inventory list:', error)
        throw error
      }
    },
    keepPreviousData: true,
  })
}

export function useInventoryTakerList(filter: IInventoryTakerFilter) {
  return useQuery<IWarehouseLocationStage[], Error>({
    queryKey: inventoryKeys.inventoryTakerList(
      filter.scanned,
      filter.mismatch,
      filter.verified,
      filter.is_update_pushed,
      filter.search_text,
      filter.even_odd,
      filter.sort_by,
    ),
    queryFn: async () => {
      try {
        let response = await api.post(`${API_INVENTORY_URL}taker-list`, {
          ...filter,
        })
        return response.data
      } catch (error) {
        console.error('Error fetching inventory list:', error)
        throw error
      }
    },
    keepPreviousData: true,
  })
}

export function useInventoryTakerLogList(filter: IInventoryTakerLogFilter) {
  return useQuery<IWarehouseLocationStage[], Error>({
    queryKey: inventoryKeys.inventoryTakerLogList(
      filter.physical_inventory_id,
      filter.search_text,
      filter.even_odd,
      filter.sort_by,
    ),
    queryFn: async () => {
      try {
        let response = await api.post(`${API_INVENTORY_URL}taker-log-list`, {
          ...filter,
        })
        return response.data
      } catch (error) {
        console.error('Error fetching inventory log list:', error)
        throw error
      }
    },
    keepPreviousData: true,
  })
}
