import React from 'react'
import { Link } from 'react-router-dom'
import { HEADER_HEIGHT_DESKTOP } from '../../../constants/constants'

interface IHeaderLink {
  icon: React.ReactElement
  title: string
  link_to: string
  disabled?: boolean
  onClick?: () => void
}

const HeaderLink: React.FC<IHeaderLink> = ({
  icon,
  title,
  link_to,
  onClick,
  disabled = false,
}) => {
  return !disabled ? (
    <Link
      to={link_to}
      className="text-decoration-none text-light"
      onClick={onClick}
    >
      <div
        className="rw-header-link text-center my-auto px-3 pt-2"
        style={{ height: HEADER_HEIGHT_DESKTOP }}
      >
        <div className="">
          <div className="fs-5">{icon}</div>
          <div style={{ lineHeight: '12px' }}>
            <small>{title}</small>
          </div>
        </div>
      </div>
    </Link>
  ) : (
    <div
      className="text-secondary text-center my-auto px-3 pt-2"
      style={{ height: HEADER_HEIGHT_DESKTOP }}
    >
      <div className="">
        <div className="fs-5">{icon}</div>
        <div style={{ lineHeight: '12px' }}>
          <small>{title}</small>
        </div>
      </div>
    </div>
  )
}

export default HeaderLink
