import React from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import CdnImg from '../../../organisms/Common/CdnImg'

interface IStaffAvatarIcon {
  photo_url: string | undefined
  staff_name: string | undefined
  height?: string
  [x: string]: any
}

const StaffAvatarIcon: React.FC<IStaffAvatarIcon> = ({
  photo_url,
  staff_name,
  height = '35px',
  ...rest
}) => {
  let icon = <></>

  return (
    <OverlayTrigger
      placement="top"
      overlay={
        <Tooltip id="task-alert-tooltip">
          <strong>{staff_name}</strong>
        </Tooltip>
      }
    >
      <div {...rest}>
        {photo_url ? (
          <CdnImg height={height} className="rounded" path={photo_url} />
        ) : (
          staff_name
        )}
      </div>
    </OverlayTrigger>
  )
}

export default StaffAvatarIcon
