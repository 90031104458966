import React from 'react'

export interface IRwTableBody {
  className?: string
  [x: string]: any
}

const RwTableBody: React.FC<IRwTableBody> = ({
  children,
  className = '',
  ...rest
}) => {
  return (
    <tbody className={`${className}`} {...rest}>
      {children}
    </tbody>
  )
}

export default RwTableBody
