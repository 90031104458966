import {
  SET_VERIFICATION_CODE_REQUEST,
  SET_VERIFICATION_CODE,
  SET_INITIAL_URL,
  INITIALIZE_USER_STATE,
  SET_USER_DEVICE_INFO,
  SET_DARK_MODE_ENABLED,
  GET_RESET_PASSWORD_METHODS,
} from './constants'
import { initialState } from './UserProvider'
import { IUserAction, IUserState } from './types'

export default function userReducer(
  state: IUserState,
  action: IUserAction,
): IUserState {
  const payload = action.payload

  switch (action.type) {
    case SET_VERIFICATION_CODE_REQUEST:
      return {
        ...state,
        verificationCodeRequest: payload,
      }

    case GET_RESET_PASSWORD_METHODS:
      return {
        ...state,
        resetPasswordMethods: payload,
      }

    case SET_VERIFICATION_CODE:
      return {
        ...state,
        verification_code: payload,
      }

    case SET_INITIAL_URL:
      return {
        ...state,
        initialUrl: payload,
      }

    case SET_USER_DEVICE_INFO:
      return {
        ...state,
        userDeviceInfo: {
          userBrowser: payload.name,
          userBrowserVersion: payload.version,
          userOS: payload.os,
          userType: payload.type,
        },
      }

    case INITIALIZE_USER_STATE:
      return {
        ...initialState,
      }

    case SET_DARK_MODE_ENABLED:
      return {
        ...state,
        isDarkMode: payload,
      }

    default:
      return state
  }
}
