import React from 'react'
import Button from 'react-bootstrap/Button'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import Offcanvas from 'react-bootstrap/Offcanvas'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { generalBsBreakpoint } from '../../../constants/constants'
import RwIcon from '../../molecules/RwIcon'
import { DropdownButton } from 'react-bootstrap'

import { useQueryClient, useMutation } from '@tanstack/react-query'
import { IUserData } from '../../../queries/user/types'
import { userKeys } from '../../../queries/user/keyFactory'
import { useUserContext } from '../../../context/user/UserProvider'
import { BsFillMoonFill, BsSunFill } from 'react-icons/bs'
import RwIconTextLink from '../../molecules/RwIconTextLink'
import { logoutUser } from '../../../queries/user'
import SideNav from '../Menu/SideNav'
import AccountControls from './AccountControls'
import AccountDropdownItems from './AccountDropdownItems'

const headerNavBarBreakpoint = generalBsBreakpoint

interface IMainNavMobileProps {
  show: boolean
  handleClose: any
}

const MainNavMobile: React.FC<IMainNavMobileProps> = ({
  show,
  handleClose,
}) => {
  const queryClient = useQueryClient()
  const user = queryClient.getQueryData(userKeys.user) as IUserData
  const status = queryClient.getQueryData(userKeys.status()) as boolean
  const { initializeUserState, setDarkMode, getDarkMode, isDarkMode } =
    useUserContext()
  const navigate = useNavigate()
  const location = useLocation()

  const logoutUserMutation = useMutation(logoutUser, {
    onSettled: () => {
      initializeUserState()
      navigate('/')
    },
  })

  const logout = () => {
    logoutUserMutation.mutate({ queryClient })
  }

  const toggleDarkMode = () => {
    setDarkMode(!isDarkMode)
  }

  return (
    <>
      <Navbar.Offcanvas
        id={`offcanvasNavbar-expand-${headerNavBarBreakpoint}`}
        aria-labelledby={`offcanvasNavbarLabel-expand-lg`}
        show={show}
        onHide={handleClose}
      >
        <Offcanvas.Header closeButton className="border-bottom border-1">
          <div className="pt-2">
            {status ? (
              <>
                <Nav.Item
                  className={`col d-${headerNavBarBreakpoint}-none ms-auto pe-2 pt-0 m-0 box-shadow-none`}
                >
                  <DropdownButton
                    title={
                      <span className="text-body">
                        {user?.name} <RwIcon icon="User" />
                      </span>
                    }
                    id="user_nav_dropdown"
                    align="end"
                    className="no-focus-dropdown-button"
                    variant={isDarkMode ? 'dm-bg' : 'white'}
                  >
                    <>
                      <h5 className="p-1">My Account</h5>
                      <AccountDropdownItems />
                      <RwIconTextLink
                        containerClassProps=""
                        textProps="Sign Out"
                        onClickProps={logout}
                        icon={<RwIcon icon="SignOut" />}
                      />
                    </>
                  </DropdownButton>
                </Nav.Item>
              </>
            ) : (
              <Nav.Item>
                {location.pathname !== '/login' && (
                  <Link to="/login">
                    <Button
                      variant={isDarkMode ? 'dm-proline-primary' : 'primary'}
                      style={{ zIndex: 9999999999 }}
                      className={`${isDarkMode ? 'border-white' : ''}`}
                    >
                      <RwIcon icon="User" /> Login
                    </Button>
                  </Link>
                )}
              </Nav.Item>
            )}
          </div>
        </Offcanvas.Header>

        <Offcanvas.Body className="p-0">
          <SideNav />
          <Button
            variant={isDarkMode ? 'secondary' : 'primary'}
            onClick={toggleDarkMode}
            className="ms-3 mt-3 mb-3"
          >
            <span>{isDarkMode ? <BsSunFill /> : <BsFillMoonFill />}</span>
            <span className={'ms-2'}>
              {isDarkMode ? 'Disable ' : 'Enable'} Dark Mode
            </span>
          </Button>
        </Offcanvas.Body>
      </Navbar.Offcanvas>
    </>
  )
}

export default MainNavMobile
