import React, { ReactNode } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { IProductCaliber } from '../../../context/products/types'
import { FieldArray } from 'formik'
import RwButton from '../../molecules/RwButton/RwButton'
import RwFormikInput from '../../molecules/RwFormikInput'
import { BsFillTrashFill } from 'react-icons/bs'
import { uniqueId } from 'lodash'
import { useUserContext } from '../../../context/user/UserProvider'
import { useQueryClient } from '@tanstack/react-query'
import { userKeys } from '../../../queries/user/keyFactory'
import { IUserData } from '../../../queries/user/types'

export interface IProductCalibers {
  label?: string | ReactNode
  calibers: Array<IProductCaliber>
  [x: string]: any
}

const ProductCalibers: React.FC<IProductCalibers> = ({
  label = null,
  calibers,
}) => {
  const { isDarkMode } = useUserContext()
  const queryClient = useQueryClient()
  const user = queryClient.getQueryData(userKeys.user) as IUserData
  return (
    <>
      {label && <div className="mb-1">{label}</div>}
      <div
        className={`border rounded p-3 mb-3 ${
          isDarkMode ? 'bg-dark' : 'bg-white'
        }`}
      >
        <FieldArray name="calibers">
          {({ push, remove, form }) => {
            return (
              <Container fluid>
                {calibers && calibers.length > 0 && (
                  <Row>
                    <Col xs={5}>Caliber</Col>
                    <Col xs={6}>Units</Col>
                    <Col xs={1}></Col>
                  </Row>
                )}

                {calibers.map((caliber: IProductCaliber, index: number) => {
                  return (
                    <Row key={caliber.id}>
                      <Col xs={5}>
                        <RwFormikInput
                          name={`calibers.${index}.caliber_value`}
                          type="number"
                        />
                      </Col>
                      <Col xs={5}>
                        <RwFormikInput
                          name={`calibers.${index}.caliber_units`}
                        />
                      </Col>
                      <Col xs={2}>
                        <RwButton
                          className="cursor-pointer"
                          variant="danger"
                          onClick={() => remove(index)}
                        >
                          <BsFillTrashFill className="fs-5" />
                        </RwButton>
                      </Col>
                    </Row>
                  )
                })}
                <RwButton
                  size="sm"
                  onClick={() => push({ id: uniqueId('new') })}
                >
                  Add
                </RwButton>
              </Container>
            )
          }}
        </FieldArray>
      </div>
    </>
  )
}

export default ProductCalibers
