import React from 'react'
import { Stack } from 'react-bootstrap'
import { useUserContext } from '../../../context/user/UserProvider'

export interface IRwButtonGroup {
  [x: string]: any
}

const RwButtonGroup: React.FC<IRwButtonGroup> = ({ children, ...rest }) => {
  const { isDarkMode } = useUserContext()

  return (
    <Stack className="mt-2" gap={3} direction="horizontal" {...rest}>
      {children}
    </Stack>
  )
}

export default RwButtonGroup
