import React, { useEffect } from 'react'
import { BsFileRuled, BsFillGrid3X3GapFill } from 'react-icons/bs'
import HeaderLink from './HeaderLink'
import { useSiteContext } from '../../../context/site/SiteProvider'
import { INavMenuItem } from '../../../context/site/types'
import RwIcon from '../../molecules/RwIcon'
import { isAuthorized } from '../../../helpers/Authorization'
import { useQueryClient } from '@tanstack/react-query'
import { userKeys } from '../../../queries/user/keyFactory'
import { IUserData } from '../../../queries/user/types'

const HeaderLinks: React.FC = () => {
  const { topNavMenuItems } = useSiteContext()
  const queryClient = useQueryClient()

  const user = queryClient.getQueryData(userKeys.user) as IUserData

  return (
    <>
      {topNavMenuItems?.map((item: INavMenuItem, index: number) => {
        const hasAccess = isAuthorized(user, item.permissions)
        return (
          <HeaderLink
            title={item.title}
            icon={<RwIcon icon={item.icon} />}
            link_to={item.link_to}
            onClick={() => {}}
            key={index}
            disabled={!hasAccess}
          />
        )
      })}
    </>
  )
}

export default HeaderLinks
