import React, { useEffect, useState } from 'react'
import {
  Button,
  ListGroup,
  ListGroupItem,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap'
import useDebounce from '../../../hooks/useDebounce'
import { DEFAULT_DEBOUNCE_DELAY } from '../../../helpers'
import RwInputGroup from '../../molecules/RwInputGroup'
import { BsX } from 'react-icons/bs'
import { useRoleSearch } from '../../../queries/iam'
import { IRoleFilter } from '../../../queries/iam/types'
import { BsCheckSquare, BsSquare } from 'react-icons/bs'
import { useUserContext } from '../../../context/user/UserProvider'
import RwNavBar from '../../molecules/RwNav/RwNavBar'
import RwNavItem from '../../molecules/RwNav/RwNavItem'
import { IRoleData } from '../../../context/iam/types'

interface IRoleSearch {
  emptyText?: string
  minSearchLength?: number
  maxHeight?: string
  handleAdd: (items: Array<IRoleData>) => void
  existing?: Array<IRoleData>
}

const RoleSearch: React.FC<IRoleSearch> = ({
  emptyText = 'No Roles',
  minSearchLength = 3,
  maxHeight = '',
  handleAdd,
  existing = null,
}) => {
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [selectedItems, setSelectedItems] = useState<Array<IRoleData>>([])

  const debouncedSearchTerm = useDebounce(searchTerm, DEFAULT_DEBOUNCE_DELAY)

  const [filter, setFilter] = useState<IRoleFilter>({
    search_text: debouncedSearchTerm,
    sort_by: 'role_name',
    exclude_ids: existing
      ? existing?.map((role: IRoleData) => {
          return role.id
        })
      : [],
  })

  const { isDarkMode } = useUserContext()

  const {
    data: roles,
    refetch,
    isLoading,
    isFetching,
    isPreviousData,
    status,
  } = useRoleSearch(filter)

  useEffect(() => {
    const elems = document.getElementsByName(
      'search_text',
    ) as NodeListOf<HTMLInputElement>
    if (elems) {
      elems.forEach((elem: HTMLInputElement) => {
        elem.focus()
      })
    }
  }, [])

  useEffect(() => {
    if (
      debouncedSearchTerm.length >= minSearchLength ||
      debouncedSearchTerm.length === 0
    ) {
      setSelectedItems([])
      setFilter({
        ...filter,
        search_text: debouncedSearchTerm,
      })
    }
  }, [debouncedSearchTerm])

  const handleClearSearch = () => {
    setSelectedItems([])
    setFilter({
      ...filter,
      search_text: '',
    })
    const elems = document.getElementsByName(
      'search_text',
    ) as NodeListOf<HTMLInputElement>
    if (elems) {
      elems.forEach((elem: HTMLInputElement) => {
        elem.value = ''
      })
    }
  }

  const handleRowClick = (role: IRoleData) => {
    const existing = selectedItems.filter((selectedItem: IRoleData) => {
      if (selectedItem.id === role.id) {
        return selectedItem
      }
    })
    if (existing && existing.length > 0) {
      removeFromSelected(role)
    } else {
      addToSelected(role)
    }
  }

  const addToSelected = (role: IRoleData) => {
    setSelectedItems([role, ...selectedItems])
  }

  const removeFromSelected = (role: IRoleData) => {
    if (role) {
      setSelectedItems(
        selectedItems.filter((selectedItem) => {
          return selectedItem.id !== role.id
        }),
      )
    }
  }

  const selectAll = () => {
    let allRoles: Array<IRoleData> = []
    roles?.forEach((role: IRoleData) => {
      allRoles.push(role)
    })
    setSelectedItems(allRoles)
  }

  const selectNone = () => {
    setSelectedItems([])
  }

  const clearButton = (
    <Button variant="outline-secondary" onClick={handleClearSearch}>
      <OverlayTrigger
        placement={'right'}
        overlay={<Tooltip id={`tooltip-right`}>Clear</Tooltip>}
      >
        <div>
          <BsX />
        </div>
      </OverlayTrigger>
    </Button>
  )

  return (
    <div>
      <div className="px-3 pt-2 border-bottom">
        <RwInputGroup
          name="search_text"
          suffix={clearButton}
          onChange={(e: any) => {
            if (
              e.target.value.length >= minSearchLength ||
              e.target.value.length === 0
            ) {
              setSearchTerm(e.target.value)
            }
          }}
        />
      </div>
      <RwNavBar>
        <RwNavItem
          variant="checked"
          title="Select All"
          onClick={selectAll}
          disabled={roles?.length === 0}
        />
        <RwNavItem
          variant="unchecked"
          title="Select None"
          onClick={selectNone}
          disabled={roles?.length === 0}
        />

        <RwNavItem
          fill
          variant="save"
          title="Save"
          disabled={selectedItems.length === 0}
          onClick={() => {
            handleAdd(selectedItems)
          }}
        />
      </RwNavBar>
      <ListGroup variant="flush">
        {roles?.length === 0 ? (
          <div className="d-flex justify-content-center p-3">{emptyText}</div>
        ) : (
          roles?.map((role: IRoleData) => {
            const existing = selectedItems.filter((selectedItem: IRoleData) => {
              if (selectedItem.id === role.id) {
                return selectedItem
              }
            })

            const selected = existing && existing.length > 0

            return (
              <ListGroupItem
                key={role.id}
                className={`${
                  selected ? (isDarkMode ? 'bg-dark' : 'bg-light') : ''
                } border-bottom`}
                action
                onClick={() => {
                  handleRowClick(role)
                }}
              >
                <span className="me-2">
                  {selected ? (
                    <BsCheckSquare className="fs-5 text-success" />
                  ) : (
                    <BsSquare className="fs-5" />
                  )}
                </span>
                {role.role_name}
              </ListGroupItem>
            )
          })
        )}
      </ListGroup>
    </div>
  )
}

export default RoleSearch
