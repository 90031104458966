export const SET_INVENTORY_FILTER = 'SET_INVENTORY_FILTER'
export const SET_INVENTORY_TAKER_FILTER = 'SET_INVENTORY_TAKER_FILTER'
export const SET_INVENTORY_TAKER_LOG_FILTER = 'SET_INVENTORY_TAKER_LOG_FILTER'
export const GET_PHYSICAL_INVENTORY = 'GET_PHYSICAL_INVENTORY'
export const GET_PHYSICAL_INVENTORY_LIST = 'GET_PHYSICAL_INVENTORY_LIST'

export const PRODUCT_STATUSES = {
  INACTIVE: '0',
  ACTIVE: '1',
  ARCHIVED: '2',
}
