import React, { useState, ReactElement } from 'react'
import { Form, FloatingLabel, NavDropdown, Row, Col } from 'react-bootstrap'
import { Link, useHref } from 'react-router-dom'
import { IconContext } from 'react-icons'

export interface RwIconTextLinkProps {
  containerClassProps: string
  textProps: string
  toLinkProps?: string
  onClickProps?: () => void
  hrefProps?: string
  icon: ReactElement
  target?: string
  [x: string]: any
}

const RwIconTextLink: React.FC<RwIconTextLinkProps> = ({
  containerClassProps,
  textProps,
  toLinkProps = '',
  onClickProps,
  hrefProps,
  icon,
  ...rest
}) => {
  if (hrefProps) {
    return (
      <NavDropdown.Item href={hrefProps}>
        <Row>
          <Col
            xs="2"
            className="d-flex justify-content-center d-flex align-items-center"
          >
            <IconContext.Provider value={{ className: 'react-icons' }}>
              {icon}
            </IconContext.Provider>
          </Col>
          <Col xs="8" className="d-flex align-items-center">
            {textProps}
          </Col>
        </Row>
      </NavDropdown.Item>
    )
  } else {
    return (
      <NavDropdown.Item as={Link} to={toLinkProps} onClick={onClickProps}>
        <Row>
          <Col
            xs="2"
            className="d-flex justify-content-center d-flex align-items-center"
          >
            <IconContext.Provider value={{ className: 'react-icons' }}>
              {icon}
            </IconContext.Provider>
          </Col>
          <Col xs="8" className="d-flex align-items-center">
            {textProps}
          </Col>
        </Row>
      </NavDropdown.Item>
    )
  }
}

export default RwIconTextLink
