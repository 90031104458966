import React from 'react'
import { Button } from 'react-bootstrap'
import { useUserContext } from '../../../context/user/UserProvider'
import { useQueryClient } from '@tanstack/react-query'
import { userKeys } from '../../../queries/user/keyFactory'
import { IUserData } from '../../../queries/user/types'
import { isAuthorized } from '../../../helpers/Authorization'

export interface IRwSubmitButton {
  permission?: string
  disabled?: boolean
  [x: string]: any
}

const RwSubmitButton: React.FC<IRwSubmitButton> = ({
  permission = null,
  disabled = false,
  children,
  ...rest
}) => {
  const { isDarkMode } = useUserContext()
  const queryClient = useQueryClient()
  const user = queryClient.getQueryData(userKeys.user) as IUserData

  const hasAccess = !permission || isAuthorized(user, [permission])

  return (
    <Button
      disabled={disabled || !hasAccess}
      variant={isDarkMode ? 'secondary' : 'primary'}
      type="submit"
      {...rest}
    >
      {children}
    </Button>
  )
}

export default RwSubmitButton
