import React from 'react'
import RwFormikOptionSelect from '../../molecules/RwFormikOptionSelect'
import { Container, Row, Col } from 'react-bootstrap'
import { IWarehouseStaffToWarehouseRole } from '../../../context/staff/types'
import { ISelectOption } from '../../../types/interfaces'
import RwButton from '../../molecules/RwButton/RwButton'
import { BsFillTrashFill } from 'react-icons/bs'
import { FieldArray, FieldArrayRenderProps } from 'formik'
import { useUserContext } from '../../../context/user/UserProvider'
import { uniqueId } from 'lodash'
import { useQueryClient } from '@tanstack/react-query'
import { userKeys } from '../../../queries/user/keyFactory'
import { IUserData } from '../../../queries/user/types'
import RwFormikCheckbox from '../../molecules/RwFormikCheckbox'

export interface IWarehouseRoles {
  warehouseRoles: Array<IWarehouseStaffToWarehouseRole>
  warehouseRoleOptions: Array<ISelectOption>
  warehouseStaffId: string
  [x: string]: any
}

const WarehouseRoles: React.FC<IWarehouseRoles> = ({
  warehouseRoles,
  warehouseRoleOptions,
  warehouseStaffId,
}) => {
  const { isDarkMode } = useUserContext()
  const queryClient = useQueryClient()
  const user = queryClient.getQueryData(userKeys.user) as IUserData

  return (
    <>
      <div className="mb-1">Warehouse Roles</div>

      <div
        className={`border rounded p-3 mb-3 ${
          isDarkMode ? 'bg-dark' : 'bg-white'
        }`}
      >
        <FieldArray name="warehouse_roles">
          {(arrayHelpers: FieldArrayRenderProps) => (
            <Container fluid>
              {warehouseRoles.map(
                (
                  warehouseRole: IWarehouseStaffToWarehouseRole,
                  index: number,
                ) => (
                  <Row key={warehouseRole.id}>
                    <Col xs={4}>
                      <RwFormikCheckbox
                        name={`warehouse_roles.${index}.is_primary`}
                        label="Primary Role"
                      />
                    </Col>
                    <Col xs={6}>
                      <RwFormikOptionSelect
                        label=""
                        placeholder="Choose Role"
                        name={`warehouse_roles.${index}.warehouse_role_id`}
                        options={warehouseRoleOptions}
                      />
                    </Col>
                    <Col xs={2}>
                      <RwButton
                        className="cursor-pointer"
                        variant="danger"
                        onClick={() => arrayHelpers.remove(index)}
                      >
                        <BsFillTrashFill className="fs-5" />
                      </RwButton>
                    </Col>
                  </Row>
                ),
              )}
              <RwButton
                size="sm"
                onClick={() =>
                  arrayHelpers.push({
                    id: uniqueId(),
                    warehouse_role_id: '',
                    is_primary: false,
                    warehouse_staff_id: warehouseStaffId,
                  })
                }
              >
                Add
              </RwButton>
            </Container>
          )}
        </FieldArray>
      </div>
    </>
  )
}

export default WarehouseRoles
