import {
  GET_QUOTE_MONTH_SETTINGS,
  GET_QUOTE_MILEAGE_DISCOUNTS_SETTINGS,
  GET_PICKUP_DATE_TIMES_SETTINGS,
} from './constants'
import { IDeliveryAction, IDeliveryState } from './types'

export default function deliveryReducer(
  state: IDeliveryState,
  action: IDeliveryAction,
): IDeliveryState {
  const payload = action.payload
  switch (action.type) {
    case GET_QUOTE_MONTH_SETTINGS:
      return {
        ...state,
        deliveryMonthSettings: payload,
      }
    case GET_QUOTE_MILEAGE_DISCOUNTS_SETTINGS:
      return {
        ...state,
        deliveryMileageDiscountsSettings: payload,
      }
      case GET_PICKUP_DATE_TIMES_SETTINGS:
        return {
          ...state,
          pickupDateTimesSettings: payload,
        }
    default:
      return state
  }
}

