import React from 'react'
import { Spinner } from 'react-bootstrap'
import Modal from 'react-bootstrap/Modal'

interface ISpinnerModal {
  showModal: boolean
  setShowModal: (newShowValue: boolean) => void
  title?: string
  className?: string
}

const SpinnerModal: React.FC<ISpinnerModal> = ({
  showModal,
  setShowModal,
  title = 'Loading...',
  className = '',
}) => {
  return (
    <Modal
      show={showModal}
      animation={false}
      onHide={() => setShowModal(false)}
    >
      <Modal.Header className={className} closeButton={true}>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>

      <Modal.Body className={className}>
        <Spinner animation="border" />
      </Modal.Body>
    </Modal>
  )
}
export default SpinnerModal
