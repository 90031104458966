import React from 'react'
import { NavDropdown } from 'react-bootstrap'
import RwIconTextLink from '../../molecules/RwIconTextLink'
import RwIcon from '../../molecules/RwIcon'

const AccountDropdownItems: React.FC = () => {
  return (
    <>
      <RwIconTextLink
        containerClassProps=""
        textProps="Profile"
        toLinkProps="/account/profile"
        icon={<RwIcon icon="AddressCard" />}
      />

      <RwIconTextLink
        containerClassProps=""
        textProps="Password"
        toLinkProps="/account/password"
        icon={<RwIcon icon="Key" />}
      />
      <RwIconTextLink
        containerClassProps=""
        textProps="Preferences"
        iconDetails="fa-solid fa-envelope-circle-check"
        toLinkProps="/account/preferences"
        icon={<RwIcon icon="Preferences" />}
      />

      <NavDropdown.Divider />
    </>
  )
}

export default AccountDropdownItems
