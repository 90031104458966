import React, { useState, ChangeEvent } from 'react'
import { Form, Modal, Stack } from 'react-bootstrap'
import RwCloseButton from '../../../molecules/RwButton/RwCloseButton'
import * as Yup from 'yup'
import { Formik } from 'formik'
import RwSubmitButton from '../../../molecules/RwButton/RwSubmitButton'
import { passwordValidationSchema } from '../../../../constants/constants'
import PasswordHint from '../../../molecules/PasswordHint'
import { IChangePasswordData } from '../../../../context/user/types'
import RwFormikInputGroup from '../../../molecules/RwFormikInputGroup'
import EyeButton from '../../Login/EyeButton'
import RwButton from '../../../molecules/RwButton/RwButton'

export interface IPasswordModal {
  show: boolean
  handleClose: () => void
  handleSave: (values: IChangePasswordData) => void
  [x: string]: any
}

const PasswordModal: React.FC<IPasswordModal> = ({
  show,
  handleClose,
  handleSave,
  ...rest
}) => {
  const [maskNewViaPasswordType, setMaskNewViaPasswordType] = useState(true)

  const validationSchema = Yup.object().shape({
    new_password: passwordValidationSchema,
    new_password_confirmation: Yup.string()
      .required('Required')
      .oneOf([Yup.ref('new_password'), null], 'Passwords must match'),
  })

  const initialValues = {
    new_password: '',
    new_password_confirmation: '',
  }

  const onSubmit = (values: any) => {
    handleSave({
      ...values,
    })
  }

  const handleEyeClickNew = () => {
    setMaskNewViaPasswordType(!maskNewViaPasswordType)
  }

  const handleCloseWindow = () => {
    handleClose()
  }

  const [autoGenerated, setAutoGenerated] = useState(false)

  // Generate random password with at least 8 characters, 1 uppercase, 1 lowercase, 1 number, 1 special character
  const generateRandomPassword = ({
    length = 10,
    includeUppercase = true,
    includeLowercase = true,
    includeNumbers = true,
    includeSpecialCharacters = true,
  }: {
    length?: number
    includeUppercase?: boolean
    includeLowercase?: boolean
    includeNumbers?: boolean
    includeSpecialCharacters?: boolean
  } = {}): string => {
    const getRandomCharacter = (charset: string): string =>
      charset[Math.floor(Math.random() * charset.length)]

    let characterSets: string[] = []
    if (includeUppercase) characterSets.push('ABCDEFGHJKLMNPQRSTUVWXYZ')
    if (includeLowercase) characterSets.push('abcdefghjklmnpqrstuvwxyz')
    if (includeNumbers) characterSets.push('23456789')

    // Special characters are handled separately to ensure only one is added
    const specialCharacters = '!@#$%&*?'
    let specialCharacter = ''

    if (includeSpecialCharacters) {
      specialCharacter = getRandomCharacter(specialCharacters)
    }

    if (characterSets.length === 0 && !includeSpecialCharacters) {
      throw new Error('At least one character set must be selected.')
    }

    let password = ''
    let allCharacters = characterSets.join('')

    // Add one character from each selected set (excluding special characters)
    characterSets.forEach((set) => {
      password += getRandomCharacter(set)
    })

    // Add the special character if included
    if (includeSpecialCharacters) {
      password += specialCharacter
    }

    // Fill the remaining length of the password
    for (let i = password.length; i < length; i++) {
      password += getRandomCharacter(allCharacters)
    }

    return password.split('').sort(() => 0.5 - Math.random()).join('') // Shuffle to randomize the character order
  }

  return (
    <Modal className="rw-change-password-modal" show={show} animation={false}>
      <Modal.Header>
        <Modal.Title className="w-100">
          <div className="d-flex">
            <div>Change/Set Password</div>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          initialValues={initialValues}
          enableReinitialize
        >
          {({
            handleSubmit,
            handleChange,
            isValid,
            dirty,
            setFieldValue,
            resetForm,
            values,
            errors,
          }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <div className="p-4">
                <RwFormikInputGroup
                  name="new_password"
                  label="New Password"
                  type={maskNewViaPasswordType ? 'password' : 'text'} // to enable custom eye/mask
                  autoComplete="off"
                  suffix={
                    <EyeButton
                      showPassword={maskNewViaPasswordType}
                      handleClick={handleEyeClickNew}
                    />
                  }
                  hint={
                    <PasswordHint passwordInputValue={values.new_password} />
                  }
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    setAutoGenerated(false) // Call this function when the input changes
                    handleChange(event) // Ensure the original onChange function still works
                  }}
                />
                <RwFormikInputGroup
                  name="new_password_confirmation"
                  label="Re-Enter New Password"
                  type={maskNewViaPasswordType ? 'password' : 'text'} // to enable custom eye/mask
                  autoComplete="off"
                  suffix={
                    <EyeButton
                      showPassword={maskNewViaPasswordType}
                      handleClick={handleEyeClickNew}
                    />
                  }
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    setAutoGenerated(false) // Call this function when the input changes
                    handleChange(event) // Ensure the original onChange function still works
                  }}
                />
              </div>
              <Stack direction="horizontal" gap={3}>
                
                <RwSubmitButton disabled={(!dirty || !isValid) && !autoGenerated}>
                  Save
                </RwSubmitButton>
                <RwCloseButton onClick={handleCloseWindow}>
                  Cancel
                </RwCloseButton>
                <RwButton
                  onClick={() => {
                    const newPassword = generateRandomPassword()
                    setFieldValue('new_password', newPassword)
                    setFieldValue('new_password_confirmation', newPassword)
                    setMaskNewViaPasswordType(false)
                    setAutoGenerated(true)
                  }}
                >
                  Generate Random Password
                </RwButton>
              </Stack>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  )
}

export default PasswordModal