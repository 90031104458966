import React, { ReactNode } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import RwFormikCheckbox from '../../molecules/RwFormikCheckbox'
import { IProductColor } from '../../../context/products/types'
import ColorSwatch from '../../atoms/ColorSwatch'
import { useUserContext } from '../../../context/user/UserProvider'

export interface IProductColors {
  colors: Array<IProductColor>
  prefix: string
  label?: string | ReactNode
  [x: string]: any
}

const ProductColors: React.FC<IProductColors> = ({
  colors,
  prefix,
  label = null,
}) => {
  const { isDarkMode } = useUserContext()

  if (!colors) {
    return <></>
  }

  return (
    <>
      {label && <div className="mb-1">{label}</div>}
      <div
        className={`border rounded p-3 mb-3 ${
          isDarkMode ? 'bg-dark' : 'bg-white'
        }`}
      >
        <Container fluid>
          <Row>
            {colors.map((color: IProductColor) => {
              return (
                <Col sm={12} md={6} key={color.id}>
                  <RwFormikCheckbox
                    name={`${prefix}${color.id}`}
                    label={
                      <ColorSwatch
                        color={color.color_value}
                        label={color.color_name}
                        inline
                      />
                    }
                  />
                </Col>
              )
            })}
          </Row>
        </Container>
      </div>
    </>
  )
}

export default ProductColors
