import React, { useEffect } from 'react'
import { generalBsBreakpoint } from '../../../constants/constants'
import HeaderSpacer from './HeaderSpacer'
import { useSiteContext } from '../../../context/site/SiteProvider'
import { useUserContext } from '../../../context/user/UserProvider'
import TopNav from './TopNav'
import { isEmbedded } from '../../../helpers'

const headerNavBarBreakpoint = generalBsBreakpoint

const HeaderNavBar: React.FC = () => {
  const { getDarkMode, isDarkMode } = useUserContext()
  const {
    showTopNav,
    userShowTopNav,
    mainNavMenuItems,
    topNavMenuItems,
    getMainNavMenuItems,
    getTopNavMenuItems,
  } = useSiteContext()

  useEffect(() => {
    if (!mainNavMenuItems) {
      getMainNavMenuItems()
    }
    if (!topNavMenuItems) {
      getTopNavMenuItems()
    }
    getDarkMode()
  }, [])

  const brandLogoPath = isDarkMode
    ? '_spark/_wms/_assets/_images/logo_spark_yellow_flat.png'
    : '_spark/_wms/_assets/_images/logo_spark_yellow_flat.png'

  return (
    <>
      <div className={`d-none d-${headerNavBarBreakpoint}-block`}>
        {!isEmbedded() && showTopNav && userShowTopNav ? (
          <>
            {/* <HeaderSpacer /> */}
            <div
              className={`border-bottom fixed-top ${
                isDarkMode ? 'dm-bg' : 'bg-white'
              }`}
            >
              <TopNav />
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  )
}

export default HeaderNavBar
