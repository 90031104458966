import { useQuery } from '@tanstack/react-query'
import { API_IAM_URL } from '../../context/apiconfig'
import { iamKeys } from './keyFactory'
import { useQueryClient } from '@tanstack/react-query'
import { IPermissionFilter, IRoleFilter, IUserProfileFilter } from './types'
import { api } from '../../helpers/Api'
import {
  IPermissionData,
  IRoleData,
  IUserProfileData,
} from '../../context/iam/types'

export function usePermissionList(filter: IPermissionFilter) {
  const queryClient = useQueryClient()

  return useQuery<IPermissionData[], Error>({
    queryKey: iamKeys.permissionList(filter.search_text, filter.sort_by),
    queryFn: async () => {
      let response = await api.post(`${API_IAM_URL}permissions/list`, {
        ...filter,
      })

      return response.data
    },
    keepPreviousData: true,
  })
}

export function usePermissionSearch(filter: IPermissionFilter) {
  const queryClient = useQueryClient()

  return useQuery<IPermissionData[], Error>({
    queryKey: iamKeys.permissionSearch(
      filter.search_text,
      filter.exclude_ids,
      filter.sort_by,
    ),
    queryFn: async () => {
      let response = await api.post(`${API_IAM_URL}permissions/list`, {
        ...filter,
      })

      return response.data
    },
    keepPreviousData: true,
  })
}

export function useRoleList(filter: IRoleFilter) {
  const queryClient = useQueryClient()

  return useQuery<IRoleData[], Error>({
    queryKey: iamKeys.roleList(filter.search_text, filter.sort_by),
    queryFn: async () => {
      let response = await api.post(`${API_IAM_URL}roles/list`, {
        ...filter,
      })

      return response.data
    },
    keepPreviousData: true,
  })
}

export function useRoleSearch(filter: IRoleFilter) {
  const queryClient = useQueryClient()

  return useQuery<IRoleData[], Error>({
    queryKey: iamKeys.roleSearch(
      filter.search_text,
      filter.exclude_ids,
      filter.sort_by,
    ),
    queryFn: async () => {
      let response = await api.post(`${API_IAM_URL}roles/list`, {
        ...filter,
      })

      return response.data
    },
    keepPreviousData: true,
  })
}

export function useUserProfileList(filter: IUserProfileFilter) {
  const queryClient = useQueryClient()

  return useQuery<IUserProfileData[], Error>({
    queryKey: iamKeys.userProfileList(filter.search_text, filter.sort_by),
    queryFn: async () => {
      let response = await api.post(`${API_IAM_URL}user-profiles/list`, {
        ...filter,
      })

      return response.data
    },
    keepPreviousData: true,
  })
}

export function useUserProfileSearch(filter: IUserProfileFilter) {
  const queryClient = useQueryClient()

  return useQuery<IUserProfileData[], Error>({
    queryKey: iamKeys.userProfileSearch(
      filter.search_text,
      filter.exclude_ids,
      filter.sort_by,
    ),
    queryFn: async () => {
      let response = await api.post(`${API_IAM_URL}user-profiles/list`, {
        ...filter,
      })

      return response.data
    },
    keepPreviousData: true,
  })
}
