import React from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'

interface IModalConfirmation {
  showModal: boolean
  setShowModal: (newShowValue: boolean) => void
  title?: string
  body?: JSX.Element | string
  cancelButtonText?: string
  confirmButtonText?: string
  showClose?: boolean
  handleConfirm: () => void
  className?: string
  showCancel?: boolean
}

const ModalConfirmation: React.FC<IModalConfirmation> = ({
  showModal,
  setShowModal,
  title,
  body,
  cancelButtonText,
  confirmButtonText,
  showClose = true,
  handleConfirm,
  className = '',
  showCancel = true,
}) => {
  const handleCancelClick = () => {
    setShowModal(false)
  }

  const handleConfirmClick = () => {
    setShowModal(false)
    handleConfirm()
  }

  return (
    <Modal show={showModal} onHide={handleCancelClick} animation={false}>
      {title || showClose ? (
        <Modal.Header className={className} closeButton={showClose}>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
      ) : (
        <></>
      )}

      <Modal.Body className={className}>{body}</Modal.Body>
      <Modal.Footer className={className}>
        {showCancel && (
          <Button variant="secondary" onClick={handleCancelClick}>
            {cancelButtonText || 'Cancel'}
          </Button>
        )}
        <Button variant="primary" onClick={handleConfirmClick}>
          {confirmButtonText || 'Confirm'}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
export default ModalConfirmation
