import * as Yup from 'yup'
import { phoneRegExp } from '../constants/constants'
import { validatePhoneNumber } from '.'

export const YUP_PHONE = Yup.string()
  .min(10, 'Must be exactly 10 digits')
  .max(16, 'Must be exactly 10 digits')
  .matches(phoneRegExp, 'Must be exactly 10 digits')
  .test('validator', 'Must be exactly 10 digits', (value) =>
    validatePhoneNumber(value),
  )

export const YUP_EMAIL = Yup.string().email('Invalid Email')
