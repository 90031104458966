import React, { useEffect } from 'react'
import { Navbar } from 'react-bootstrap'
import CdnImg from '../Common/CdnImg'
import { generalBsBreakpoint } from '../../../constants/constants'
import { useSiteContext } from '../../../context/site/SiteProvider'
import { useUserContext } from '../../../context/user/UserProvider'
import HeaderLinks from './HeaderLinks'
import AccountControls from './AccountControls'

const headerNavBarBreakpoint = generalBsBreakpoint

const TopNav: React.FC = () => {
  const { getDarkMode, isDarkMode } = useUserContext()
  const {
    userShowSideNav,
    mainNavMenuItems,
    topNavMenuItems,
    getMainNavMenuItems,
    getTopNavMenuItems,
    setUserShowSideNav,
  } = useSiteContext()

  useEffect(() => {
    if (!mainNavMenuItems) {
      getMainNavMenuItems()
    }
    if (!topNavMenuItems) {
      getTopNavMenuItems()
    }
    getDarkMode()
  }, [])

  const brandLogoPath = isDarkMode
    ? '_spark/_wms/_assets/_images/logo_spark_yellow_flat.png'
    : '_spark/_wms/_assets/_images/logo_spark_yellow_flat.png'

  return (
    <div>
      <Navbar expand={headerNavBarBreakpoint} className="dm-bg py-0 my-0">
        {/* this Logo displays on Breakpoint and larger */}
        <Navbar.Brand
          className={`py-0 my-0 d-none d-${headerNavBarBreakpoint}-block `}
          key="Navbar Brand"
          onClick={() => {
            setUserShowSideNav(!userShowSideNav)
          }}
        >
          <CdnImg
            path={brandLogoPath}
            alt="logo"
            width="50"
            key="Logo"
            className="ps-2 pt-1"
          />
        </Navbar.Brand>
        <HeaderLinks />
        <AccountControls />
      </Navbar>
    </div>
  )
}

export default TopNav
