import {
  GET_INVOICE_EXPORT_INVOICES,
  EXPORT_SELECTED_INVOICES,
  UNMARK_EXPORTED_UNVERIFIED,
} from './constants'
import { IInvoiceExportAction, IAccountingState } from './types'

export default function accountingReducer(
  state: IAccountingState,
  action: IInvoiceExportAction,
): IAccountingState {
  const payload = action.payload
  switch (action.type) {
    case GET_INVOICE_EXPORT_INVOICES:
      return {
        ...state,
        invoiceExportInvoices: payload,
      }

    case EXPORT_SELECTED_INVOICES:
      return {
        ...state,
        exportSelectedInvoiceExportInvoices: payload,
      }

    default:
      return state
  }
}
