import React, { useState, useEffect, useRef } from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import { Editor } from '@tinymce/tinymce-react'
import { useSiteContext } from '../../../../../../context/site/SiteProvider'
import { initiallySelectedAppSettings } from '../../../../../../constants/constants'
import { useUserContext } from '../../../../../../context/user/UserProvider'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import RwFormikInput from '../../../../../molecules/RwFormikInput'
import {
  useSendProductEditorEmail,
  useGetProductEditorEmail,
} from '../../../../../../queries/products/index'
import { useParams } from 'react-router-dom'
import RwIcon from '../../../../../molecules/RwIcon'
import toast from '../../../../../molecules/RwToast'

interface RequestUpdatesModalProps {
  show: boolean
  handleClose: () => void
}

const RequestUpdatesModal: React.FC<RequestUpdatesModalProps> = ({
  show,
  handleClose,
}) => {
  const { getAppSettings, appSettings } = useSiteContext()
  const { isDarkMode } = useUserContext()
  const editorRef = useRef<any>(null)
  const { products_id, tab } = useParams()
  const [editorContent, setEditorContent] = useState('') // Add state for editor content

  useEffect(() => {
    getAppSettings(initiallySelectedAppSettings)
  }, [])

  const { data, isLoading } = useGetProductEditorEmail(products_id, tab)
  const initialValues = {
    subject: data?.subject || '',
    to: data?.to || '',
  }

  const sendProductEditorEmail = useSendProductEditorEmail()

  const validationSchema = Yup.object().shape({
    subject: Yup.string(),
    to: Yup.string().required(),
  })

  const handleSubmit = async (values: {
    subject: string
    to: string
  }) => {
    const response = await sendProductEditorEmail.mutateAsync({
      ...values,
      body: editorContent,
      products_id: products_id || '',
    })

    if (response.data.success) {
      toast.success('Email sent successfully')
    } else {
      toast.error('Email failed to send')
    }
  }

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Request Updates</Modal.Title>
      </Modal.Header>

      {isLoading ? ( // Check if data is loading
        <Modal.Body>
          <p>Loading...</p> {/* Show a loading message or spinner */}
        </Modal.Body>
      ) : (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, isValid }) => (
            <Form>
              <Modal.Body>
                <p>
                  Write a message that will be emailed to staff who have the
                  permissions to edit this tab.
                </p>
                <p>
                  <b>Tab Editors: </b>
                  {data?.tabOwners}
                </p>
                <RwFormikInput
                  name="to"
                  as="textarea"
                  style={{ height: '100px' }}
                  label="To"
                />
                <RwFormikInput name="subject" label="Subject" />

                <Editor
                  apiKey={appSettings?.tiny_key}
                  onInit={(_evt, editor) => (editorRef.current = editor)}
                  initialValue={data?.body}
                  init={{
                    branding: false,
                    skin: isDarkMode ? 'oxide-dark' : 'oxide',
                    content_css: isDarkMode ? 'dark' : 'default',
                    height: 400,
                    plugins:
                      'anchor autolink charmap codesample emoticons link lists searchreplace table visualblocks wordcount linkchecker',
                    toolbar:
                      'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link table code | ' +
                      'spellcheckdialog a11ycheck typography | align lineheight | numlist bullist ' +
                      'indent outdent | emoticons charmap | removeformat | searchreplace',
                  }}
                  onEditorChange={(content) => {
                    setEditorContent(content) // Update editor content state
                  }}
                />
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="primary"
                  type="submit"
                  disabled={!isValid || isSubmitting}
                >
                  {sendProductEditorEmail.isLoading ? 'Sending...' : 'Send'}
                  <RwIcon icon="Email" className="ms-2 fs-4" />
                </Button>
                <Button variant="secondary" onClick={handleClose}>
                  Close
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      )}
    </Modal>
  )
}

export default RequestUpdatesModal
