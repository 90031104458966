import {
  SET_INVENTORY_FILTER,
  SET_INVENTORY_TAKER_FILTER,
  SET_INVENTORY_TAKER_LOG_FILTER,
  GET_PHYSICAL_INVENTORY,
  GET_PHYSICAL_INVENTORY_LIST,
} from './constants'
import { initialState } from './InventoryProvider'
import { IInventoryAction, IInventoryState } from './types'

export default function productReducer(
  state: IInventoryState = initialState,
  action: IInventoryAction,
): IInventoryState {
  const payload = action.payload

  switch (action.type) {
    case SET_INVENTORY_FILTER:
      return {
        ...state,
        inventoryFilter: payload,
      }
    case SET_INVENTORY_TAKER_FILTER:
      return {
        ...state,
        inventoryTakerFilter: payload,
      }
    case SET_INVENTORY_TAKER_LOG_FILTER:
      return {
        ...state,
        inventoryTakerLogFilter: payload,
      }
    case GET_PHYSICAL_INVENTORY_LIST:
      return {
        ...state,
        physicalInventoryList: payload,
      }
    case GET_PHYSICAL_INVENTORY:
      return {
        ...state,
        physicalInventory: payload,
      }
    default:
      return state
  }
}
