import React from 'react'

const Page404: React.FC = () => {
  return (
    <div className="p-5 text-center fs-3 align-middle">
      <br />
      This page does not exist.
      <br />
      <br />
      <br />
      Check the web address in the URL bar, and try again.
    </div>
  )
}

export default Page404
