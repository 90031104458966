import {
  STOP_PROMO,
  PROMO_STATUS,
  PROMO_CENTER,
  STOP_COOKIE,
  COOKIE,
} from './constants'
import { ISettingsAction, ISettingsState } from './types'

export default function settingsReducer(
  state: ISettingsState,
  action: ISettingsAction,
): ISettingsState {
  const payload = action.payload
  switch (action.type) {
    case STOP_PROMO:
      return {
        ...state,
        stopPromo: payload,
      }

    case PROMO_STATUS:
      return {
        ...state,
        promoStatus: payload,
      }
    case PROMO_CENTER:
      return {
        ...state,
        promoCenter: payload,
      }
    case STOP_COOKIE:
      return {
        ...state,
        stopCookie: payload,
      }
    case COOKIE:
      return {
        ...state,
        cookie: payload,
      }

    default:
      return state
  }
}
