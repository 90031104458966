import { createContext, useReducer, useContext } from 'react'
import {} from './constants'
import { IWarehouseState, IOrderPallet } from './types'
import warehouseReducer from './WarehouseReducer'
import { IContextProviderProps } from '../../types/interfaces'
import { API_WAREHOUSE_URL } from '../apiconfig'
import { api } from '../../helpers/Api'
import toast from '../../components/molecules/RwToast'

export const initialState: IWarehouseState = {
  clearWarehouseContext: () => {},
  updateOrderPallet: () => {},
}

export const WarehouseContext = createContext<IWarehouseState>(initialState)
export const useWarehouseContext = () => useContext(WarehouseContext)

export const WarehouseProvider = (
  props: IContextProviderProps,
): JSX.Element => {
  const { children } = props
  const [state, dispatch] = useReducer(warehouseReducer, initialState)

  const clearWarehouseContext = async () => {
    dispatch({
      type: '',
      payload: {},
    })
  }

  const updateOrderPallet = async (pallet: IOrderPallet) => {
    const response = await api.post(`${API_WAREHOUSE_URL}update-order-pallet`, {
      ...pallet,
    })

    if (response.data.success) {
      toast.success(response.data.message)
    } else {
      toast.error(response.data.message || 'Error updating pallet')
    }
  }

  const value = {
    ...state,
    clearWarehouseContext,
    updateOrderPallet,
  }

  return (
    <WarehouseContext.Provider value={value}>
      {children}
    </WarehouseContext.Provider>
  )
}
