import React from 'react'
import Modal from 'react-bootstrap/Modal'
import RwTable from '../../molecules/RwTable'
import RwTableBody from '../../molecules/RwTable/RwTableBody'
import RwTableRow from '../../molecules/RwTable/RwTableRow'
import RwTableCell from '../../molecules/RwTable/RwTableCell'
import { Button } from 'react-bootstrap'
import { IPhysicalInventory } from '../../../context/inventory/types'
import RwTableHead from '../../molecules/RwTable/RwTableHead'

interface ISelectPhysicalInventoryModal {
  showModal: boolean
  setShowModal: (newShowValue: boolean) => void
  title?: string
  className?: string
  items: Array<IPhysicalInventory> | null
  onSelect: (id: string | undefined) => void
}

const SelectPhysicalInventoryModal: React.FC<ISelectPhysicalInventoryModal> = ({
  showModal,
  setShowModal,
  title = 'Select Physical Inventory',
  className = '',
  items,
  onSelect,
}) => {
  return (
    <Modal
      className="rw-physical-inventory-modal"
      show={showModal}
      animation={false}
      onHide={() => setShowModal(false)}
    >
      <Modal.Header className={className} closeButton={true}>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>

      <Modal.Body className={className}>
        <div className="fs-5">
          <RwTable hover>
            <RwTableHead>
              <RwTableRow>
                <RwTableCell as="th">Name</RwTableCell>
                <RwTableCell as="th">Start Date</RwTableCell>
                <RwTableCell as="th">Date Pushed</RwTableCell>
              </RwTableRow>
            </RwTableHead>
            <RwTableBody>
              {items?.map((item: IPhysicalInventory) => {
                return (
                  <RwTableRow
                    key={item.id}
                    onClick={() => {
                      onSelect(item?.id)
                      setShowModal(false)
                    }}
                  >
                    <RwTableCell>{item.name}</RwTableCell>
                    <RwTableCell>{item.begin_date}</RwTableCell>
                    <RwTableCell>{item.end_date}</RwTableCell>
                  </RwTableRow>
                )
              })}
            </RwTableBody>
          </RwTable>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setShowModal(false)}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
export default SelectPhysicalInventoryModal
