import React, { useState } from 'react'

import { useSiteContext } from '../../../context/site/SiteProvider'
import { Navbar } from 'react-bootstrap'
import { useLocation } from 'react-router-dom'
import { isEmbedded, isHideNavPath } from '../../../helpers'
import { generalBsBreakpoint } from '../../../constants/constants'
import MainNavMobile from '../../organisms/Header/MainNavMobile'

const headerNavBarBreakpoint = generalBsBreakpoint

interface IMobileHeaderMenu {}

const MobileHeaderMenu: React.FC = () => {
  const { showTopNav, userShowTopNav, showSideNav, userShowSideNav } =
    useSiteContext()

  const location = useLocation()

  const [show, setShow] = useState<boolean>(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  const showOnMobile =
    !isEmbedded() &&
    showTopNav &&
    userShowTopNav &&
    showSideNav &&
    userShowSideNav

  if (isHideNavPath(location.pathname) || !showOnMobile) {
    return <></>
  }

  return (
    <div className={`d-${headerNavBarBreakpoint}-none`}>
      <Navbar expand={headerNavBarBreakpoint} className="p-0 my-0 ms-auto">
        <Navbar.Toggle
          aria-controls={`offcanvasNavbar-expand-${headerNavBarBreakpoint}`}
          className={`px-2 me-2 shadow-none`}
          onClick={handleShow}
        />
        <MainNavMobile show={show} handleClose={handleClose} />
      </Navbar>
    </div>
  )
}

export default MobileHeaderMenu
