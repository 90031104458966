import { useQueryClient } from '@tanstack/react-query'
import React from 'react'
import { userKeys } from '../../queries/user/keyFactory'
import { IUserData } from '../../queries/user/types'
import { isAuthorized } from '../../helpers/Authorization'

export interface IProtectedForm {
  permission?: string
  children: any
}

const ProtectedForm: React.FC<IProtectedForm> = ({
  children,
  permission = '',
}) => {
  const queryClient = useQueryClient()
  const user = queryClient.getQueryData(userKeys.user) as IUserData
  const hasAccess = !permission || isAuthorized(user, [permission])
  return <fieldset disabled={!hasAccess}>{children}</fieldset>
}

export default ProtectedForm
