export const API_URL_PREFIX =
  process.env.REACT_APP_API_URL_PREFIX === undefined
    ? ''
    : process.env.REACT_APP_API_URL_PREFIX
export const AUTH_URL = `${process.env.REACT_APP_API_URL}${API_URL_PREFIX}auth/`
export const API_URL = `${process.env.REACT_APP_API_URL}${API_URL_PREFIX}api/`
export const PUBLIC_API_URL = `${process.env.REACT_APP_API_URL}${API_URL_PREFIX}public/api/`
export const API_CUSTOMERS_URL = `${process.env.REACT_APP_API_URL}${API_URL_PREFIX}api/customers/`
export const API_INVENTORY_URL = `${process.env.REACT_APP_API_URL}${API_URL_PREFIX}api/inventory/`
export const API_CART_URL = `${process.env.REACT_APP_API_URL}${API_URL_PREFIX}api/carts/`
export const API_DOCUMENTS_URL = `${process.env.REACT_APP_API_URL}${API_URL_PREFIX}api/documents/` // note: DocumentLinks includes the word 'documents' but is a separate public route w/ a token
export const PUBLIC_API_SITE_URL = `${process.env.REACT_APP_API_URL}${API_URL_PREFIX}public/api/site/`
export const API_SITE_URL = `${process.env.REACT_APP_API_URL}${API_URL_PREFIX}api/site/`
export const API_SETTINGS_URL = `${process.env.REACT_APP_API_URL}${API_URL_PREFIX}api/settings/`
export const API_FULFILLMENT_URL = `${process.env.REACT_APP_API_URL}${API_URL_PREFIX}api/fulfillment/`
export const API_INVOICE_URL = `${process.env.REACT_APP_API_URL}${API_URL_PREFIX}api/accounting/invoice/`
export const API_PRODUCTS_URL = `${process.env.REACT_APP_API_URL}${API_URL_PREFIX}api/products/`
export const API_ORDERS_URL = `${process.env.REACT_APP_API_URL}${API_URL_PREFIX}api/orders/`
export const API_AUDIT_URL = `${process.env.REACT_APP_API_URL}${API_URL_PREFIX}api/audit/`
export const API_IAM_URL = `${process.env.REACT_APP_API_URL}${API_URL_PREFIX}api/iam/`
export const API_DEALER_NETWORK_URL = `${process.env.REACT_APP_API_URL}${API_URL_PREFIX}api/dealer-network/`
export const API_WAREHOUSE_URL = `${process.env.REACT_APP_API_URL}${API_URL_PREFIX}api/warehouse/`
export const API_PRICING_URL = `${process.env.REACT_APP_API_URL}${API_URL_PREFIX}api/pricing/`
export const API_SHIPPING_URL = `${process.env.REACT_APP_API_URL}${API_URL_PREFIX}api/shipping/`

