import { createContext, useReducer, useContext } from 'react'
import {
  STOP_PROMO,
  PROMO_STATUS,
  PROMO_CENTER,
  STOP_COOKIE,
  COOKIE,
} from './constants'
import settingsReducer from './SettingsReducer'
import { api } from '../../helpers/Api'
import {
  IFeaturedTileData,
  ISettingsState,
  IFeaturedBannerData,
  IActivateTileData,
} from './types'
import { toast } from 'react-toastify'
import { API_SETTINGS_URL } from '../apiconfig'

export const initialState: ISettingsState = {
  cookie: false,
  stopCookie: false,
  promoCenter: false,
  promoStatus: false,
  stopPromo: false,
  stopPromoAction: () => {},
  promoStatusAction: () => {},
  promoCenterAction: () => {},
  stopCookieAction: () => {},
  cookieAction: () => {},
  postFeaturedTile: () => {},
  editFeaturedTile: () => {},
  activateFeaturedTile: () => {},
  uploadBanner: () => {},
}

export const SettingsContext = createContext(initialState)

export const useSettingsContext = () => useContext(SettingsContext)

export interface ISettingsProviderProps {
  children?: React.ReactNode
}

export const SettingsProvider = (
  props: ISettingsProviderProps,
): JSX.Element => {
  const { children } = props

  const [state, dispatch] = useReducer(settingsReducer, initialState)

  const stopPromoAction = () => {
    // Axios stuff here

    const dispatchObj = dispatch({
      type: STOP_PROMO,
      payload: true,
    })
  }

  const promoStatusAction = () => {
    // Axios stuff here

    const dispatchObj = dispatch({
      type: PROMO_STATUS,
      payload: true,
    })
  }

  const promoCenterAction = () => {
    // Axios stuff here

    const dispatchObj = dispatch({
      type: PROMO_CENTER,
      payload: !state.promoCenter,
    })
  }

  const stopCookieAction = () => {
    // Axios stuff here

    const dispatchObj = dispatch({
      type: STOP_COOKIE,
      payload: true,
    })
  }

  const cookieAction = () => {
    // Axios stuff here

    const dispatchObj = dispatch({
      type: COOKIE,
      payload: true,
    })
  }

  const postFeaturedTile = async (
    featuredTileData: IFeaturedTileData,
    onComplete: () => void,
  ) => {
    const response = await api.post(`${API_SETTINGS_URL}post-featured-tile`, {
      featuredTileData,
    })
    if (!response?.data) {
      toast.error(`${'An error occurred.'}`)
      onComplete()
    } else {
      if (response.data.errors) {
        response.data.errors.forEach((error: string) => {
          toast.error(error)
        })
      } else {
        toast.success(
          `${response?.data?.message || 'Tile added successfully.'}`,
        )
      }
      onComplete()
    }
  }

  const activateFeaturedTile = async (
    featuredTileData: IActivateTileData,
    onComplete: () => void,
  ) => {
    const response = await api.put(
      `${API_SETTINGS_URL}activate-featured-tile`,
      {
        featuredTileData,
      },
    )
    if (!response?.data) {
      toast.error(`${'An error occurred.'}`)
      onComplete()
    } else {
      if (response.data.errors) {
        response.data.errors.forEach((error: string) => {
          toast.error(error)
        })
      } else {
        toast.success(
          `${response?.data?.message || 'Tile activated successfully.'}`,
        )
      }
      onComplete()
    }
  }

  const editFeaturedTile = async (
    featuredTileData: IFeaturedTileData,
    onComplete: () => void,
  ) => {
    const response = await api.put(`${API_SETTINGS_URL}edit-featured-tile`, {
      featuredTileData,
    })
    if (!response?.data) {
      toast.error(`${'An error occurred.'}`)
      onComplete()
    } else {
      if (response.data.errors) {
        response.data.errors.forEach((error: string) => {
          toast.error(error)
        })
      } else {
        toast.success(
          `${response?.data?.message || 'Tile updated successfully.'}`,
        )
      }
      onComplete()
    }
  }

  const uploadBanner = async (
    featuredBannerData: IFeaturedBannerData,
    onComplete: () => void,
  ) => {
    const response = await api.post(`${API_SETTINGS_URL}upload-banner`, {
      featuredBannerData,
    })
    if (!response?.data) {
      toast.error(`${'An error occurred.'}`)
      onComplete()
    } else {
      if (response.data.errors) {
        response.data.errors.forEach((error: string) => {
          toast.error(error)
        })
      } else {
        toast.success(`${response?.data?.message || 'Updated.'}`)
      }
      onComplete()
    }
  }

  return (
    <SettingsContext.Provider
      value={{
        cookie: state.cookie,
        stopCookie: state.stopCookie,
        promoCenter: state.promoCenter,
        promoStatus: state.promoStatus,
        stopPromo: state.stopPromo,
        stopPromoAction,
        promoStatusAction,
        promoCenterAction,
        stopCookieAction,
        cookieAction,
        postFeaturedTile,
        editFeaturedTile,
        activateFeaturedTile,
        uploadBanner,
      }}
    >
      {children}
    </SettingsContext.Provider>
  )
}
