import React, { useEffect, useState } from 'react'
import {
  passwordRequirements,
  passwordValidationTests,
} from '../../constants/constants'

export interface PasswordHintProps {
  passwordInputValue: string
  [x: string]: any
}

const PasswordHint: React.FC<PasswordHintProps> = ({
  passwordInputValue = '',
  ...rest
}) => {
  const passwordValidationTestResults =
    passwordValidationTests(passwordInputValue)

  const renderPasswordRequirements = () => {
    return passwordRequirements.map((requirement, index) => {
      const isGreen = passwordValidationTestResults[index]

      return (
        <span key={index} className={isGreen ? 'text-success' : 'text-muted'}>
          {requirement}
          {index < passwordRequirements.length - 1 ? ', ' : ''}
        </span>
      )
    })
  }

  return (
    <span className="text-muted ps-3">
      * Password must include at least:
      {renderPasswordRequirements()}.
    </span>
  )
}

export default PasswordHint
