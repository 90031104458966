export const iamKeys = {
  permissionList: (
    search_text: string | undefined,
    sort_by: string | undefined,
  ) => [search_text, sort_by] as const,
  permissionSearch: (
    search_text: string | undefined,
    exclude_ids: Array<string> | undefined,
    sort_by: string | undefined,
  ) => [search_text, exclude_ids, sort_by] as const,
  roleList: (search_text: string | undefined, sort_by: string | undefined) =>
    [search_text, sort_by] as const,
  roleSearch: (
    search_text: string | undefined,
    exclude_ids: Array<string> | undefined,
    sort_by: string | undefined,
  ) => [search_text, exclude_ids, sort_by] as const,
  userProfileList: (
    search_text: string | undefined,
    sort_by: string | undefined,
  ) => [search_text, sort_by] as const,
  userProfileSearch: (
    search_text: string | undefined,
    exclude_ids: Array<string> | undefined,
    sort_by: string | undefined,
  ) => [search_text, sort_by] as const,
}
