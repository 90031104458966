import React, { useEffect, useState } from 'react'
import { Button, Collapse, Stack } from 'react-bootstrap'
import { useSiteContext } from '../../../context/site/SiteProvider'
import { useUserContext } from '../../../context/user/UserProvider'
import { BsFillGearFill, BsFillMoonFill, BsSunFill } from 'react-icons/bs'
import { INavMenuItem } from '../../../context/site/types'
import {
  BsChevronDown,
  BsChevronUp,
  BsFillLockFill,
  BsFillPeopleFill,
} from 'react-icons/bs'
import { Desktop } from '../../atoms/responsive'
import { useNavigate, useLocation } from 'react-router-dom'
import {
  hasActiveItem,
  isActiveItem,
  isEmbedded,
  isHideNavPath,
  truncateFilePath,
} from '../../../helpers'
import { isAuthorized } from '../../../helpers/Authorization'
import { useQueryClient } from '@tanstack/react-query'
import { userKeys } from '../../../queries/user/keyFactory'
import { IUserData } from '../../../queries/user/types'
import HeaderSpacer from '../Header/HeaderSpacer'

const SideNav: React.FC = () => {
  const { mainNavMenuItems, showSideNav, userShowSideNav, toggleMenuItemOpen } =
    useSiteContext()

  const { isDarkMode, setDarkMode } = useUserContext()
  const queryClient = useQueryClient()
  const user = queryClient.getQueryData(userKeys.user) as IUserData
  const navigate = useNavigate()
  const location = useLocation()

  const toggleDarkMode = () => {
    setDarkMode(!isDarkMode)
  }

  const navClass = isDarkMode ? 'rw-side-nav-dm' : 'rw-side-nav'

  if (
    isHideNavPath(location.pathname) ||
    !showSideNav ||
    !userShowSideNav ||
    isEmbedded()
  ) {
    return <></>
  }

  return (
    <div className={`${navClass}`}>
      <div className="rw-side-nav-div">
        <div className="rw-side-nav-inner-container">
          <HeaderSpacer />
          <div className="rw-side-nav-upper-div">
            {mainNavMenuItems &&
              (mainNavMenuItems as unknown as any[]).map(
                (item: INavMenuItem) => {
                  const hasActive = hasActiveItem(
                    item,
                    location.pathname,
                    location.search,
                  )
                  // const isOpen = hasActive || item.open
                  const isOpen = item.open
                  const isHighlighted = hasActive && !isOpen
                  const isHighlightedClass = isHighlighted
                    ? 'rw-side-nav-has-active'
                    : ''

                  return isAuthorized(user, item.permissions) ? (
                    <div key={item.id}>
                      <div
                        className={`rw-side-nav-item p-2 py-3 text-uppercase border-top ${isHighlightedClass}`}
                        onClick={() => {
                          toggleMenuItemOpen(item.id)
                        }}
                      >
                        {item.title}
                        <span
                          className="me-1 float-end"
                          style={{ fontSize: '10px' }}
                        >
                          {isOpen ? <BsChevronDown /> : <BsChevronUp />}
                        </span>
                      </div>
                      <Collapse in={isOpen}>
                        <Stack>
                          {item.children?.map(
                            (data: INavMenuItem, index: number) => {
                              const hasAccess = isAuthorized(
                                user,
                                data.permissions,
                              )
                              const isActive = isActiveItem(
                                data,
                                location.pathname,
                                location.search,
                              )

                              return (
                                <div
                                  className={`d-flex ${
                                    hasAccess ? 'rw-side-nav-item' : ''
                                  } ${isActive ? 'selected' : ''} ${
                                    isDarkMode
                                      ? hasAccess
                                        ? 'text-light'
                                        : 'text-secondary'
                                      : hasAccess
                                      ? 'text-primary'
                                      : 'text-secondary'
                                  }`}
                                  key={`${data.title}-${index}`}
                                  id={data.id}
                                  onClick={() => {
                                    if (hasAccess) {
                                      navigate(data.link_to)
                                    }
                                  }}
                                >
                                  <div
                                    className={
                                      isActive
                                        ? 'ind-selected'
                                        : 'ind-unselected'
                                    }
                                  >
                                    &nbsp;
                                  </div>
                                  <div className="p-3 ps-2 w-100">
                                    <div className="d-flex">
                                      <div className="ms-2">{data.title}</div>
                                      {!hasAccess ? (
                                        <div className="ms-auto">
                                          <BsFillLockFill />
                                        </div>
                                      ) : (
                                        <></>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              )
                            },
                          )}
                        </Stack>
                      </Collapse>
                    </div>
                  ) : (
                    <></>
                  )
                },
              )}
          </div>
          <div className="rw-side-nav-lower-div">
            <div
              className={`rw-side-nav-item p-2 py-3 text-uppercase border-top`}
              onClick={toggleDarkMode}
            >
              <span>{isDarkMode ? <BsSunFill /> : <BsFillMoonFill />}</span>
              <span className={'ms-2'}>
                {isDarkMode ? 'Disable' : 'Enable'} Dark Mode
              </span>
            </div>
            <div
              className={`rw-side-nav-item p-2 py-3 text-uppercase border-top`}
              onClick={() => {}}
            >
              <span>
                <BsFillGearFill />
              </span>
              <span className={'ms-2'}>User Settings</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SideNav
