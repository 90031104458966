import React, { useEffect, useState } from 'react'
import {
  Button,
  ListGroup,
  ListGroupItem,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap'
import useDebounce from '../../../hooks/useDebounce'
import { DEFAULT_DEBOUNCE_DELAY } from '../../../helpers'
import RwInputGroup from '../../molecules/RwInputGroup'
import { BsX } from 'react-icons/bs'
import { useUserProfileSearch } from '../../../queries/iam'
import { IUserProfileFilter } from '../../../queries/iam/types'
import { BsCheckSquare, BsSquare } from 'react-icons/bs'
import { useUserContext } from '../../../context/user/UserProvider'
import RwNavBar from '../../molecules/RwNav/RwNavBar'
import RwNavItem from '../../molecules/RwNav/RwNavItem'
import { IUserProfileData } from '../../../context/iam/types'

interface IUserProfileSearch {
  emptyText?: string
  minSearchLength?: number
  maxHeight?: string
  handleAdd: (item: IUserProfileData) => void
}

const UserProfileSearch: React.FC<IUserProfileSearch> = ({
  emptyText = 'No User Profiles',
  minSearchLength = 3,
  maxHeight = '',
  handleAdd,
}) => {
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [selectedItem, setSelectedItem] = useState<IUserProfileData | null>(
    null,
  )

  const debouncedSearchTerm = useDebounce(searchTerm, DEFAULT_DEBOUNCE_DELAY)

  const [filter, setFilter] = useState<IUserProfileFilter>({
    search_text: debouncedSearchTerm,
    sort_by: 'profile_name',
  })

  const { isDarkMode } = useUserContext()

  const {
    data: userProfiles,
    refetch,
    isLoading,
    isFetching,
    isPreviousData,
    status,
  } = useUserProfileSearch(filter)

  useEffect(() => {
    const elems = document.getElementsByName(
      'search_text',
    ) as NodeListOf<HTMLInputElement>
    if (elems) {
      elems.forEach((elem: HTMLInputElement) => {
        elem.focus()
      })
    }
  }, [])

  useEffect(() => {
    if (
      debouncedSearchTerm.length >= minSearchLength ||
      debouncedSearchTerm.length === 0
    ) {
      setSelectedItem(null)
      setFilter({
        ...filter,
        search_text: debouncedSearchTerm,
      })
    }
  }, [debouncedSearchTerm])

  const handleClearSearch = () => {
    setSelectedItem(null)
    setFilter({
      ...filter,
      search_text: '',
    })
    const elems = document.getElementsByName(
      'search_text',
    ) as NodeListOf<HTMLInputElement>
    if (elems) {
      elems.forEach((elem: HTMLInputElement) => {
        elem.value = ''
      })
    }
  }

  const handleRowClick = (userProfile: IUserProfileData) => {
    if (selectedItem?.id === userProfile.id) {
      setSelectedItem(null)
    } else {
      setSelectedItem(userProfile)
    }
  }

  const clearButton = (
    <Button variant="outline-secondary" onClick={handleClearSearch}>
      <OverlayTrigger
        placement={'right'}
        overlay={<Tooltip id={`tooltip-right`}>Clear</Tooltip>}
      >
        <div>
          <BsX />
        </div>
      </OverlayTrigger>
    </Button>
  )

  return (
    <div>
      <div className="px-3 pt-2 border-bottom">
        <RwInputGroup
          name="search_text"
          suffix={clearButton}
          onChange={(e: any) => {
            if (
              e.target.value.length >= minSearchLength ||
              e.target.value.length === 0
            ) {
              setSearchTerm(e.target.value)
            }
          }}
        />
      </div>
      <RwNavBar>
        <RwNavItem
          fill
          variant="save"
          title="Save"
          disabled={!selectedItem}
          onClick={() => {
            if (selectedItem) {
              handleAdd(selectedItem)
            }
          }}
        />
      </RwNavBar>
      <ListGroup variant="flush">
        {userProfiles?.length === 0 ? (
          <div className="d-flex justify-content-center p-3">{emptyText}</div>
        ) : (
          userProfiles?.map((userProfile: IUserProfileData) => {
            const selected = selectedItem?.id === userProfile.id

            return (
              <ListGroupItem
                key={userProfile.id}
                className={`${
                  selected ? (isDarkMode ? 'bg-dark' : 'bg-light') : ''
                } border-bottom`}
                action
                onClick={() => {
                  handleRowClick(userProfile)
                }}
              >
                <span className="me-2">
                  {selected ? (
                    <BsCheckSquare className="fs-5 text-success" />
                  ) : (
                    <BsSquare className="fs-5" />
                  )}
                </span>
                {userProfile.profile_name}
              </ListGroupItem>
            )
          })
        )}
      </ListGroup>
    </div>
  )
}

export default UserProfileSearch
