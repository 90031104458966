import {
  GET_CUSTOMER,
  GET_CUSTOMER_NAME,
  GET_TASK,
  SET_TASK_FILTER,
  SET_TASKS_FILTER,
  SET_SHOW_TASK_SEQ_NBR,
  GET_RETAIL_DEALERS_SELECT_LIST,
} from './constants'
import { initialState } from './CustomerProvider'
import { ICustomerAction, ICustomerState } from './types'

export default function productReducer(
  state: ICustomerState = initialState,
  action: ICustomerAction,
): ICustomerState {
  const payload = action.payload

  switch (action.type) {
    case GET_CUSTOMER:
      return {
        ...state,
        customer: payload,
      }

    case GET_CUSTOMER_NAME:
      return {
        ...state,
        customerName: payload,
      }

    case GET_TASK:
      return {
        ...state,
        task: payload,
      }

    case SET_TASK_FILTER:
      return {
        ...state,
        taskFilter: payload,
      }

    case SET_TASKS_FILTER:
      return {
        ...state,
        tasksFilter: payload,
      }

    case SET_SHOW_TASK_SEQ_NBR:
      return {
        ...state,
        showTaskSeqNbr: payload,
      }

    case GET_RETAIL_DEALERS_SELECT_LIST:
      return {
        ...state,
        retailDealerSelectList: payload,
      }

    default:
      return state
  }
}
