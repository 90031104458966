import { Field } from 'formik'
import React, { JSXElementConstructor, ReactElement, ReactNode } from 'react'
import { FloatingLabel, Form, InputGroup } from 'react-bootstrap'

export interface RwFormikInputGroupProps {
  label?: string | ReactNode
  hint?: string | ReactNode
  type?: string
  name?: string
  id?: string
  prefix?: ReactElement<any, string | JSXElementConstructor<any>>
  suffix?: ReactElement<any, string | JSXElementConstructor<any>>
  flush?: boolean
  floatingLabel?: boolean
  [x: string]: any
}

const RwFormikInputGroup: React.FC<RwFormikInputGroupProps> = (
  props: RwFormikInputGroupProps,
) => {
  const {
    type = 'text',
    label,
    hint = '',
    name = props.name || props.id,
    prefix = null,
    suffix = null,
    flush = false,
    floatingLabel = false,
    ...rest
  } = props

  const newPrefix = prefix
    ? React.cloneElement(prefix, {
        style: { height: '37px' },
        tabIndex: 99,
      })
    : null

  const newSuffix = suffix
    ? React.cloneElement(suffix, {
        style: { height: '37px' },
        className: 'rounded-end',
        tabIndex: 99,
      })
    : null

  const marginClass = flush ? '' : 'mb-2'

  return (
    <div className={marginClass}>
      <Field name={name}>
        {({ field, form, meta }: any) => {
          const isValid = !form.errors[field.name]
          const isInvalid = form.touched[field.name] && !isValid
          return label && floatingLabel ? (
            <InputGroup>
              {newPrefix}
              <FloatingLabel label={label} className="mb-2">
                <Form.Control
                  {...field}
                  type={type}
                  isInvalid={isInvalid}
                  feedback={form.errors[field.name]}
                  placeholder={label ? label : ''}
                  name={name}
                  {...rest}
                />
                <Form.Control.Feedback type="invalid" className="ms-2">
                  <>{form.errors[field.name]}</>
                </Form.Control.Feedback>
                {typeof hint === 'string' && hint.length > 0 && (
                  <Form.Text className="text-muted ms-2">{hint}</Form.Text>
                )}
                {React.isValidElement(hint) && hint}
              </FloatingLabel>
              {newSuffix}
            </InputGroup>
          ) : (
            <Form.Group controlId={`${name}_form_group`}>
              {label && <Form.Label className="mb-1">{label}</Form.Label>}
              <InputGroup>
                {newPrefix}
                <Form.Control
                  {...field}
                  type={type}
                  isInvalid={isInvalid}
                  feedback={form.errors[field.name]}
                  placeholder={floatingLabel ? label : ''}
                  name={name}
                  {...rest}
                />
                {newSuffix}
                <Form.Control.Feedback type="invalid" className="ms-2">
                  <>{form.errors[field.name]}</>
                </Form.Control.Feedback>
              </InputGroup>
              {typeof hint === 'string' && hint.length > 0 && (
                <Form.Text className="text-muted ms-2">{hint}</Form.Text>
              )}
              {React.isValidElement(hint) && hint}
            </Form.Group>
          )
        }}
      </Field>
    </div>
  )
}

export default RwFormikInputGroup
