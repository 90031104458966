import { Field } from 'formik'
import React, { ReactNode } from 'react'
import { Form } from 'react-bootstrap'

export interface RwFormikCheckboxProps {
  label: string | ReactNode
  hint?: string
  type?: string
  name?: string
  id?: string
  value?: string
  className?: string
  flush?: boolean
  [x: string]: any
}

const RwFormikCheckbox: React.FC<RwFormikCheckboxProps> = (
  props: RwFormikCheckboxProps,
) => {
  const {
    type = 'checkbox',
    label,
    hint = '',
    name = props.name || props.id || null,
    value,
    id = `${name}_form_group`,
    className = '',
    flush = false,
    ...rest
  } = props

  const marginClass = flush ? '' : 'mb-3'

  return (
    <Field name={name}>
      {({ field, form, meta }: any) => {
        const isValid = !form.errors[field.name]
        const isInvalid = form.touched[field.name] && !isValid
        return (
          <Form.Group controlId={id}>
            <Form.Check
              {...field}
              type={type}
              isInvalid={isInvalid}
              feedback={form.errors[field.name]}
              placeholder={label}
              name={name}
              value={value}
              label={label}
              checked={field.value !== undefined ? field.value : false}
              className={`${marginClass} ${className}`}
              {...rest}
            />
            <Form.Text className="text-muted">{hint}</Form.Text>
            <Form.Control.Feedback type="invalid">
              <>{form.errors[field.name]}</>
            </Form.Control.Feedback>
          </Form.Group>
        )
      }}
    </Field>
  )
}

export default RwFormikCheckbox
