import React, { useEffect } from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { useUserContext } from '../context/user/UserProvider'
import { IUserData } from '../queries/user/types'
import { useQueryClient } from '@tanstack/react-query'
import { userKeys } from '../queries/user/keyFactory'

export interface PrivateRouteProps {
  redirectPath?: any
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({
  redirectPath = '/login',
}) => {
  const queryClient = useQueryClient()
  const user = queryClient.getQueryData(userKeys.user) as IUserData
  const { setInitialUrl } = useUserContext()
  const pathAndSearchUrl = window.location.pathname + window.location.search
  useEffect(() => {
    setInitialUrl(pathAndSearchUrl)
  }, [pathAndSearchUrl])

  if (!user && pathAndSearchUrl) {
    return <Navigate to={redirectPath} replace />
  }

  return <Outlet />
}

export default PrivateRoute
