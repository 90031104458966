export const GET_PRODUCT_FOR_PRICING = 'GET_PRODUCT_FOR_PRICING'
export const GET_PRICE_SUPPLIER = 'GET_PRICE_SUPPLIER'
export const GET_PRICE_PRODUCTS_MULTIPLIER_SETTINGS =
  'GET_PRICE_PRODUCTS_MULTIPLIER_SETTINGS'

export const GET_PRICE_PRODUCT_MULTIPLIER_SETTINGS =
  'GET_PRICE_PRODUCT_MULTIPLIER_SETTINGS'
export const GET_PRICE_FOREX_DETAIL_ITEM = 'GET_PRICE_FOREX_DETAIL_ITEM'
export const GET_PRICE_INBOUND_TEMPLATE_ITEMS_DETAIL_ITEM =
  'GET_PRICE_INBOUND_TEMPLATE_ITEMS_DETAIL_ITEM'

export const GET_PRICE_INBOUND_TEMPLATE_ITEMS_CONTAINER_CHARGES =
  'GET_PRICE_INBOUND_TEMPLATE_ITEMS_CONTAINER_CHARGES'

export const GET_PRICING_NOTES = 'GET_PRICING_NOTES'
export const GET_BASKET_OF_GOODS_ITEM = 'GET_BASKET_OF_GOODS_ITEM'
export const SET_SUPPLIER_MANUFACTURER_DISCOUNT_FILTERS =
  'SET_SUPPLIER_MANUFACTURER_DISCOUNT_FILTERS'
export const SET_MANUFACTURERS_FILTERS = 'SET_MANUFACTURERS_FILTERS'
export const SET_VERSION_FILTERS = 'SET_VERSION_FILTERS'
export const GET_VERSION = 'GET_VERSION'
export const GET_INBOUND_OCEAN_FREIGHT_OPTIONS =
  'GET_INBOUND_OCEAN_FREIGHT_OPTIONS'
export const GET_MANUFACTURERS_PRICING = 'GET_MANUFACTURERS_PRICING'
export const GET_PRICING_SUPPLIERS = 'GET_PRICING_SUPPLIERS'
export const GET_PRICING_MANUFACTURERS = 'GET_PRICING_MANUFACTURERS'
export const SET_CATEGORY_MULTIPLIERS_FILTERS =
  'SET_CATEGORY_MULTIPLIERS_FILTERS'
export const PRICING_STATUSES = {
  INACTIVE: '0',
  ACTIVE: '1',
  ALL: '99',
}
export const MANUFACTURERS_STATUSES = {
  INACTIVE: '0',
  ACTIVE: '1',
}

export const VERSION_STATUSES = {
  INACTIVE: '0',
  ACTIVE: '1',
  ALL: '99',
}

export const GET_PRICE_INBOUND_FREIGHT_DETAIL_ITEM =
  'GET_PRICE_INBOUND_FREIGHT_DETAIL_ITEM'
export const GET_PRICING_CATEGORIES = 'GET_PRICING_CATEGORIES'
