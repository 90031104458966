import { createContext, useReducer, useContext } from 'react'
import {
  GET_CUSTOMER,
  GET_CUSTOMER_NAME,
  GET_TASK,
  SET_TASK_FILTER,
  SET_TASKS_FILTER,
  SET_SHOW_TASK_SEQ_NBR,
  GET_RETAIL_DEALERS_SELECT_LIST,
} from './constants'
import {
  ICustomerState,
  ICustomerData,
  ITaskData,
  ITaskDocumentData,
} from './types'
import customerReducer from './CustomerReducer'
import { IContextProviderProps } from '../../types/interfaces'
import { API_CUSTOMERS_URL, API_DEALER_NETWORK_URL } from '../apiconfig'
import { api } from '../../helpers/Api'
import toast from '../../components/molecules/RwToast'
import { ITaskFilter } from '../../queries/customers/types'
import { useGetAuthUser } from '../../queries/user'
import axios, { AxiosRequestConfig } from 'axios'
import authHeader from '../services/AuthHeader'

export const defaultCustomerTaskFilter: ITaskFilter = {
  customers_id: '',
  status: '',
  task_type: '',
  search_text: '',
  sort_by: 'activity_date',
  is_for_customer: true,
}

export const defaultTaskFilter: ITaskFilter = {
  customers_id: '',
  status: '',
  task_type: '',
  search_text: '',
  sort_by: 'activity_date',
  is_for_customer: false,
}

export const initialState: ICustomerState = {
  customer: null,
  customerName: null,
  task: null,
  taskFilter: defaultCustomerTaskFilter,
  tasksFilter: defaultTaskFilter,
  showTaskSeqNbr: localStorage.getItem('showTaskSeqNbr') === 'true',
  retailDealerSelectList: [],
  getCustomer: () => {},
  getCustomerName: () => {},
  updateCustomer: () => {},
  getTask: () => {},
  newTask: () => {},
  setTask: () => {},
  updateTask: () => {},
  updateTasks: () => {},
  deleteTask: () => {},
  deleteTasks: () => {},
  completeTasks: () => {},
  setTaskFilter: () => {},
  setTasksFilter: () => {},
  clearTaskContext: () => {},
  uploadTaskDocument: () => {},
  downloadTaskDocument: () => {},
  deleteTaskDocument: () => {},
  setShowTaskSeqNbr: () => {},
  getRetailDealerSelectList: () => {},
  pushRetailInventory: () => {},
}

export const CustomerContext = createContext<ICustomerState>(initialState)

export const useCustomerContext = () => useContext(CustomerContext)

export const CustomerProvider = (props: IContextProviderProps): JSX.Element => {
  const { children } = props
  const [state, dispatch] = useReducer(customerReducer, initialState)
  const { data: userData } = useGetAuthUser()

  if (userData) {
    initialState.tasksFilter.staff_id = userData.staff_id
  }

  const getCustomer = async (customers_id: string) => {
    const response = await api.get(`${API_CUSTOMERS_URL}${customers_id}`)

    dispatch({
      type: GET_CUSTOMER,
      payload: response.data,
    })
  }

  const getCustomerName = async (customers_id: string) => {
    const response = await api.get(
      `${API_CUSTOMERS_URL}${customers_id}/customers-name`,
    )

    dispatch({
      type: GET_CUSTOMER_NAME,
      payload: response.data,
    })
  }

  const updateCustomer = async (customer: ICustomerData) => {
    const response = await api.post(`${API_CUSTOMERS_URL}save`, customer)

    if (response?.data) {
      if (response.data.errors) {
        response.data.errors.forEach((error: string) => {
          toast.error(error)
        })
      } else {
        toast.success(`${response?.data?.message || 'Updated.'}`)
        dispatch({
          type: GET_CUSTOMER,
          payload: response?.data,
        })
      }
    }
  }

  const getTask = async (id: string) => {
    const response = await api.get(`${API_CUSTOMERS_URL}tasks/${id}`)
    dispatch({
      type: GET_TASK,
      payload: response.data,
    })
  }

  const newTask = async (task: ITaskData | null) => {
    const response = await api.post(`${API_CUSTOMERS_URL}tasks/new`, task)

    dispatch({
      type: GET_TASK,
      payload: response.data,
    })
  }

  const setTask = async (task: ITaskData) => {
    dispatch({
      type: GET_TASK,
      payload: task,
    })
  }

  const clearTaskContext = async () => {
    dispatch({
      type: GET_TASK,
      payload: null,
    })
  }

  const updateTask = async (task: ITaskData, callback: () => void) => {
    const response = await api.post(`${API_CUSTOMERS_URL}tasks/save`, task)

    if (response?.data) {
      if (response.data.errors) {
        response.data.errors.forEach((error: string) => {
          toast.error(error)
        })
      } else {
        toast.success(`${response?.data?.message || 'Updated.'}`)
        dispatch({
          type: GET_TASK,
          payload: response?.data,
        })
        callback()
      }
    }
  }

  const updateTasks = async (tasks: Array<ITaskData>, callback: () => void) => {
    const response = await api.post(`${API_CUSTOMERS_URL}tasks/save-many`, {
      tasks: tasks,
    })

    if (response?.data) {
      if (response.data.errors) {
        response.data.errors.forEach((error: string) => {
          toast.error(error)
        })
      } else {
        toast.success(`${response?.data?.message || 'Updated.'}`)
        callback()
      }
    }
  }

  const deleteTask = async (id: string, callback: () => void) => {
    const response = await api.post(`${API_CUSTOMERS_URL}tasks/delete`, { id })
    await callback()
  }

  const deleteTasks = async (ids: Array<string>, callback: () => void) => {
    const response = await api.post(`${API_CUSTOMERS_URL}tasks/delete`, { ids })
    await callback()
  }

  const completeTasks = async (ids: Array<string>, callback: () => void) => {
    const response = await api.post(`${API_CUSTOMERS_URL}tasks/complete`, {
      ids,
    })
    await callback()
  }

  const setTaskFilter = async (taskFilter: ITaskFilter) => {
    dispatch({
      type: SET_TASK_FILTER,
      payload: taskFilter,
    })
  }

  const setTasksFilter = async (tasksFilter: ITaskFilter) => {
    dispatch({
      type: SET_TASKS_FILTER,
      payload: tasksFilter,
    })
  }

  const uploadTaskDocument = async (
    taskDocument: any,
    onSuccess: () => void,
    onError: () => void,
  ) => {
    const formData = new FormData()
    formData.append('filepath', taskDocument.filepath)
    formData.append('task_id', taskDocument.task_id)
    formData.append('title', taskDocument.title)
    formData.append('description', taskDocument.description)

    const headersValue: AxiosRequestConfig = { headers: authHeader() }
    const response = await axios.post(
      `${API_CUSTOMERS_URL}tasks/upload/document`,
      formData,
      headersValue,
    )

    if (response?.data) {
      if (response.data.errors) {
        response.data.errors.forEach((error: string) => {
          toast.error(error)
          onError()
        })
      } else {
        onSuccess()
        toast.success('File Uploaded')
      }
    }
  }

  const downloadTaskDocument = async (id: string) => {
    const response = await axios.get(
      `${API_CUSTOMERS_URL}tasks/download/document/${id}`,
      { responseType: 'arraybuffer' },
    )

    if (response.data) {
      const blob = new Blob([response.data], {
        type: response.headers['content-type'],
      })
      const link = document.createElement('a')
      const filename = response.headers['content-disposition']
        .split('filename=')[1]
        .replace(/['"]+/g, '')

      link.href = window.URL.createObjectURL(blob)
      link.download = filename
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    } else {
      toast.error(response.data.message)
    }
  }

  const deleteTaskDocument = async (id: string, callback: () => void) => {
    const response = await api.get(
      `${API_CUSTOMERS_URL}tasks/delete/document/${id}`,
    )
    await callback()
  }

  const setShowTaskSeqNbr = async (showTaskSeqNbr: boolean) => {
    localStorage.setItem('showTaskSeqNbr', showTaskSeqNbr.toString())
    dispatch({
      type: SET_SHOW_TASK_SEQ_NBR,
      payload: showTaskSeqNbr,
    })
  }

  const getRetailDealerSelectList = async () => {
    const response = await api.get(
      `${API_DEALER_NETWORK_URL}dealers/select-list`,
    )
    dispatch({
      type: GET_RETAIL_DEALERS_SELECT_LIST,
      payload: response.data,
    })
  }

  const pushRetailInventory = async (
    orders_id: string,
    callback: () => void,
  ) => {
    const response = await api.post(
      `${API_DEALER_NETWORK_URL}inventory-push/push`,
      { orders_id },
    )

    if (!response.data.is_success) {
      toast.error(response.data.errormsg)
    } else {
      toast.success('Inventory Allocation Push Successful')
    }

    callback()
  }

  const value = {
    ...state,
    getCustomer,
    getCustomerName,
    updateCustomer,
    getTask,
    newTask,
    setTask,
    updateTask,
    updateTasks,
    deleteTask,
    deleteTasks,
    completeTasks,
    setTaskFilter,
    setTasksFilter,
    clearTaskContext,
    uploadTaskDocument,
    downloadTaskDocument,
    deleteTaskDocument,
    setShowTaskSeqNbr,
    getRetailDealerSelectList,
    pushRetailInventory,
  }

  return (
    <CustomerContext.Provider value={value}>
      {children}
    </CustomerContext.Provider>
  )
}
