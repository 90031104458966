import React from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { MdOutlineHistory } from 'react-icons/md'

interface IUpdateHistoryIcon {
  [x: string]: any
}

const UpdateHistoryIcon: React.FC<IUpdateHistoryIcon> = ({ ...rest }) => {
  let icon = <></>

  return (
    <OverlayTrigger
      placement="top"
      overlay={
        <Tooltip id="task-alert-tooltip">
          <strong>Update History</strong>
        </Tooltip>
      }
    >
      <div {...rest}>
        <MdOutlineHistory className="fs-4" />
      </div>
    </OverlayTrigger>
  )
}

export default UpdateHistoryIcon
