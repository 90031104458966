import React from 'react'
import RwCard from '../../../../molecules/RwCard'
import { Card, Row, Col } from 'react-bootstrap'
import RwDisplayField from '../../../../molecules/RwDisplayField'
import RwNavBar from '../../../../molecules/RwNav/RwNavBar'
import RwNavItem from '../../../../molecules/RwNav/RwNavItem'
import PricingAlgorithm from './pricing-algorithm'
import { IProductPricingItem } from '../../../../../queries/pricing/types'

export interface ProductPricingSummaryProps {
  product: IProductPricingItem
  handleClose: () => void
  title: string
}

const ProductPricingSummary: React.FC<ProductPricingSummaryProps> = ({
  product,
  handleClose,
  title,
}) => {
  if (!product) {
    return <></>
  }

  return (
    <div>
      <RwNavBar>
        <RwNavItem variant="close" onClick={handleClose} />{' '}
      </RwNavBar>
      <RwCard className={'m-3'}>
        <Card.Header className="fw-bold">{title} Detail</Card.Header>
        <Card.Body className="p-2">
          <Row>
            <Col md={4}>
              <RwDisplayField value={product.products_id} label="Products ID" />
            </Col>
            <Col md={4}>
              <RwDisplayField
                value={product.products_model}
                label="Products Model"
              />
            </Col>
            <Col md={4}>
              <RwDisplayField
                value={product.products_name}
                label="Products Name"
              />
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <RwDisplayField value={product.version_id} label="Version ID" />
            </Col>
            <Col md={4}>
              <RwDisplayField
                value={product.manufacturers_name}
                label="Manufacturer"
              />
            </Col>
            <Col md={4}>
              <RwDisplayField value={product.suppliers_name} label="Supplier" />
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <RwDisplayField value={product.products_fob} label="FOB Cost" />
            </Col>
            <Col md={4}>
              <RwDisplayField value={product.base_price} label="Base Price" />
            </Col>
            <Col md={4}>
              <RwDisplayField
                value={product.suggested_base_price}
                label="Suggested Base Price"
              />
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <RwDisplayField value={product.margin} label="Estimated Gross Margin" />
            </Col>
            <Col md={4}>
              <RwDisplayField
                value={product.products_cbm}
                label="Product CBM"
              />
            </Col>
            <Col md={4}>
              <RwDisplayField
                value={product.estimated_landed_cost}
                label="Estimated Landed Cost"
              />
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              {!product.is_multiple_products && (
                <PricingAlgorithm product={product} />
              )}
            </Col>
          </Row>
        </Card.Body>
      </RwCard>
    </div>
  )
}

export default ProductPricingSummary
