export const isAuthorized = (user: any, permissions: Array<string>) => {
  let authorized = true
  if (!user || user.staff_login === undefined) {
    return false
  }

  if (permissions !== undefined) {
    const userPermissions =
      user.staff_login.permissions !== undefined
        ? user.staff_login.permissions
        : null
    permissions?.forEach((permission: string) => {
      if (!userPermissions?.includes(permission)) {
        authorized = false
        return
      }
    })
  }

  return authorized
}
