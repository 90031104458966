import React from 'react'
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap'
import RwIcon from '../../molecules/RwIcon'

export interface IEyeButton {
  handleClick: () => void
  showPassword: boolean
}

const EyeButton: React.FC<IEyeButton> = ({ handleClick, showPassword }) => {
  return (
    <Button variant="outline-secondary" onClick={handleClick}>
      <OverlayTrigger
        placement={'right'}
        overlay={
          <Tooltip>{showPassword ? 'Show Password' : 'Hide Password'}</Tooltip>
        }
      >
        <div>
          <RwIcon icon={showPassword ? 'Eye' : 'EyeHide'} className="fa-xl" />
        </div>
      </OverlayTrigger>
    </Button>
  )
}

export default EyeButton
