import React from 'react'
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap'
import RwIcon from '../RwIcon'

export interface RwIconButtonProps {
  handleClick: () => void
  style?: React.CSSProperties
  className?: string
  icon_name?: string
  button_text?: string
  tool_tip?: string
  [x: string]: any
}

const RwIconButton: React.FC<RwIconButtonProps> = ({
  handleClick,
  style,
  className,
  icon_name,
  button_text,
  tool_tip,
  ...rest
}) => {
  return (
    <Button
      variant="outline-secondary"
      onClick={handleClick}
      style={style}
      className={className}
      {...rest}
    >
      {tool_tip ? (
        <OverlayTrigger
          placement="right"
          overlay={<Tooltip id="tooltip-right">{tool_tip}</Tooltip>}
        >
          <div>
            <RwIcon
              icon={icon_name ?? 'default'}
              className="fa-xl icon-button-icon-spacing"
            />
            {button_text}
          </div>
        </OverlayTrigger>
      ) : (
        <div>
          <RwIcon
            icon={icon_name ?? 'default'}
            className="fa-xl icon-button-icon-spacing"
          />
          {button_text}
        </div>
      )}
    </Button>
  )
}

export default RwIconButton
