import React, { useState } from 'react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import RwFormikInput from '../../molecules/RwFormikInput'
import { useAddNewProduct } from '../../../queries/products'
import toast from '../../molecules/RwToast'
import { useLocation, useNavigate } from 'react-router-dom'

interface AddProductModalProps {
  show: boolean
  handleClose: () => void
}

const initialValues = { products_model: '' }
const validationSchema = Yup.object({
  products_model: Yup.string()
    .required('SKU is required')
    .max(12, 'SKU must be under 13 characters'),
})

const AddProductModal: React.FC<AddProductModalProps> = ({
  show,
  handleClose,
}) => {
  const addNewProduct = useAddNewProduct()
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const location = useLocation()

  const handleSubmit = async (values: { products_model: string }) => {
    setLoading(true)

    const response = await addNewProduct.mutateAsync(values.products_model)

    if (response?.data) {
      if (response.data.errors) {
        response.data.errors.forEach((error: string) => {
          toast.error(error)
        })
      } else {
        toast.success(`${response?.data?.message || 'New product added.'}`)
        navigate(`${location.pathname}/${response.data.products_id}/purchasing`)
        handleClose()
      }
      setLoading(false)
    }
  }

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Add Product</Modal.Title>
      </Modal.Header>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, isValid, dirty, errors }) => (
          <Form>
            <Modal.Body>
              <div className="form-group">
                <RwFormikInput
                  name="products_model"
                  label="Enter new product SKU:"
                  hint="Example: GM100"
                />
              </div>
            </Modal.Body>

            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
              <Button
                type="submit"
                variant="primary"
                disabled={!dirty || !isValid || isSubmitting}
              >
                {loading ? 'Adding...' : 'Add'}
              </Button>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  )
}

export default AddProductModal
