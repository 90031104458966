import { GET_STAFF_ACCOUNT, GET_SALES_STAFF_SELECT_OPTIONS, GET_WAREHOUSE_ROLES } from './constants'
import { IStaffAction, IStaffState } from './types'

export default function staffReducer(
  state: IStaffState,
  action: IStaffAction,
): IStaffState {
  const payload = action.payload
  switch (action.type) {
    case GET_STAFF_ACCOUNT:
      return {
        ...state,
        staffAccount: payload,
      }
    case GET_WAREHOUSE_ROLES:
      return {
        ...state,
        warehouseRoles: payload,
      }
  

    case GET_SALES_STAFF_SELECT_OPTIONS:
      return {
        ...state,
        salesStaffSelectOptions: payload,
      }

    default:
      return state
  }
}
