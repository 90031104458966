import { createContext, useReducer, useContext, useEffect } from 'react'
import {
  SET_VERIFICATION_CODE_REQUEST,
  SET_VERIFICATION_CODE,
  SET_INITIAL_URL,
  SET_USER_DEVICE_INFO,
  INITIALIZE_USER_STATE,
  SET_DARK_MODE_ENABLED,
  GET_RESET_PASSWORD_METHODS,
} from './constants'
import userReducer from './UserReducer'
import {
  IUserState,
  IChangePasswordData,
  IResetPasswordData,
  IVerificationCodeRequest,
} from './types'
import { AUTH_URL } from '../apiconfig'
import { useNavigate } from 'react-router-dom'
import { IContextProviderProps } from '../../types/interfaces'
import toast from '../../components/molecules/RwToast'
import { useCookies } from 'react-cookie'
import { api } from '../../helpers/Api'

const { detect } = require('detect-browser')
const browser = detect()

export const initialState: IUserState = {
  verification_code: null,
  verificationCodeRequest: null,
  initialUrl: '',
  userDeviceInfo: null,
  isDarkMode: false,
  resetPasswordMethods: [],
  resetPasswordInit: () => {},
  setVerificationCode: () => {},
  setVerificationCodeRequest: () => {},
  requestPasswordReset: () => {},
  resetPassword: () => {},
  changePassword: () => {},
  setInitialUrl: () => {},
  setUserDeviceInfo: () => {},
  initializeUserState: () => {},
  getDarkMode: () => {},
  setDarkMode: () => {},
}

export const UserContext = createContext(initialState)

export const useUserContext = () => useContext(UserContext)

export const UserProvider = (props: IContextProviderProps): JSX.Element => {
  const { children } = props

  const [state, dispatch] = useReducer(userReducer, initialState)

  const navigate = useNavigate()

  const [cookies, setCookie, removeCookie] = useCookies(['darkModeEnabled'])

  const setVerificationCode = async (code: string) => {
    dispatch({
      type: SET_VERIFICATION_CODE,
      payload: code,
    })
  }

  const setVerificationCodeRequest = async (
    verificationCodeRequest: IVerificationCodeRequest,
  ) => {
    dispatch({
      type: SET_VERIFICATION_CODE_REQUEST,
      payload: verificationCodeRequest,
    })
  }

  const resetPasswordInit = async (username: string) => {
    const response = await api.post(`${AUTH_URL}reset-password-init`, {
      username: username,
    })

    if (!response?.data?.success) {
      toast.error(`${response.data.errors.join(' ') || 'An error occurred.'}`)
    } else if (response?.data?.success) {
      dispatch({
        type: GET_RESET_PASSWORD_METHODS,
        payload: response.data.data,
      })

      navigate('/login/reset-password-method')
    }
  }

  const requestPasswordReset = async (
    verificationCodeRequest: IVerificationCodeRequest | null,
  ) => {
    const response = await api.post(`${AUTH_URL}reset-password`, {
      username: verificationCodeRequest?.username,
      method: verificationCodeRequest?.method,
    })

    if (!response?.data?.success) {
      toast.error(`${response.data.errors.join(' ') || 'An error occurred.'}`)
    } else if (response?.data?.success) {
      toast.success('Code has been sent.')
      navigate('/login/reset-password/verification')
    }
  }

  const resetPassword = async (resetPasswordData: IResetPasswordData) => {
    const response = await api.post(
      `${AUTH_URL}reset-password-change`,
      resetPasswordData,
    )

    if (!response?.data?.success) {
      toast.error(`${response.data.errors.join(' ') || 'An error occurred.'}`)
    } else if (response?.data?.success) {
      toast.success('Password reset complete.')
      navigate('/login')
    }
  }

  useEffect(() => {
    if (browser) {
      setUserDeviceInfo(browser)
    } // needs detect-browser library (above)
  }, [])

  const changePassword = async (
    changePasswordData: IChangePasswordData,
    resetForm: Function,
  ) => {
    const response = await api.post(
      `${AUTH_URL}change-password`,
      changePasswordData,
    )

    if (!response?.data?.success) {
      toast.error(`${response.data.errors.join(' ') || 'An error occurred.'}`)
    } else if (response?.data?.success) {
      toast.success('Password changed.')
      resetForm()
    }
  }

  const setInitialUrl = async (url?: any) => {
    dispatch({
      type: SET_INITIAL_URL,
      payload: url,
    })
  }

  const setUserDeviceInfo = async (deviceInfo: object) => {
    dispatch({
      type: SET_USER_DEVICE_INFO,
      payload: deviceInfo,
    })
  }

  const initializeUserState = async () => {
    dispatch({
      type: INITIALIZE_USER_STATE,
      payload: null,
    })
    getDarkMode()
  }

  const getDarkMode = () => {
    const isDarkMode = cookies.darkModeEnabled
    if (isDarkMode === undefined) {
      return setDarkMode(false)
    }
    setDarkMode(isDarkMode)
  }

  const setDarkMode = (isDarkMode: boolean) => {
    setCookie('darkModeEnabled', isDarkMode)
    if (isDarkMode) {
      document.documentElement.setAttribute('data-bs-theme', 'dark')
    } else {
      document.documentElement.setAttribute('data-bs-theme', 'light')
    }
    dispatch({
      type: SET_DARK_MODE_ENABLED,
      payload: isDarkMode,
    })
  }

  const value = {
    ...state,
    resetPasswordInit,
    requestPasswordReset,
    resetPassword,
    changePassword,
    setVerificationCodeRequest,
    setVerificationCode,
    setInitialUrl,
    setUserDeviceInfo,
    initializeUserState,
    getDarkMode,
    setDarkMode,
  }

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>
}
