import React from 'react'
import { Button } from 'react-bootstrap'
import { useUserContext } from '../../../context/user/UserProvider'
import { isAuthorized } from '../../../helpers/Authorization'
import { useQueryClient } from '@tanstack/react-query'
import { userKeys } from '../../../queries/user/keyFactory'
import { IUserData } from '../../../queries/user/types'

export interface IRwButton {
  disabled?: boolean
  permission?: string
  [x: string]: any
}

const RwButton: React.FC<IRwButton> = ({
  children,
  disabled = false,
  permission = null,
  ...rest
}) => {
  const { isDarkMode } = useUserContext()
  const queryClient = useQueryClient()
  const user = queryClient.getQueryData(userKeys.user) as IUserData
  const hasAccess = !permission || isAuthorized(user, [permission])
  const isDisabled = disabled || !hasAccess

  return (
    <Button
      variant={isDarkMode ? 'secondary' : 'primary'}
      disabled={isDisabled}
      {...rest}
    >
      {children}
    </Button>
  )
}

export default RwButton
