import React from 'react'
import { useState, useEffect, useMemo } from 'react'
import InputGroup from 'react-bootstrap/InputGroup'
import { Form, Button } from 'react-bootstrap'
import { BsFillPlusCircleFill, BsFillDashCircleFill } from 'react-icons/bs'
import { debounce } from 'lodash'
import { blurInputOnKeyDown, DEFAULT_DEBOUNCE_DELAY } from '../../../helpers'
import { BsExclamationCircle, BsExclamationTriangle } from 'react-icons/bs'
import { useUserContext } from '../../../context/user/UserProvider'

interface InputQuantityControlProps {
  maxQuantity: number
  data: any
  message?: string
  messageType?: string
  debounceDelay?: number
  disabled?: boolean
  handleQuantityChange: (data: any) => void
}

const InputQuantityControl: React.FC<InputQuantityControlProps> = (
  props: InputQuantityControlProps,
) => {
  const {
    maxQuantity,
    data,
    message = null,
    messageType = null,
    debounceDelay = DEFAULT_DEBOUNCE_DELAY,
    disabled,
    handleQuantityChange,
  } = props

  const [count, setCount] = useState(data.quantity)
  const [inputValue, setInputValue] = useState(data.quantity)

  const { isDarkMode } = useUserContext()

  useEffect(() => {
    if (data.quantity === 0) {
      setCount(data.quantity)
    }
  }, [data.quantity])

  useEffect(() => {
    if (count > maxQuantity) {
      setCount(data.quantity)
    }
  }, [data])

  const incNum = () => {
    const incQuantity = count + 1
    setCount(incQuantity)
    debouncedEventHandler(incQuantity)
  }

  const decNum = () => {
    const decQuantity = count > 0 ? count - 1 : 0
    if (count !== decQuantity) {
      setCount(decQuantity)
      debouncedEventHandler(decQuantity)
    }
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value
    const inputQuantity =
      val === '' || val === '0' ? 0 : Math.abs(parseInt(val.replace(/^0+/, '')))
    e.target.value = inputQuantity.toString()
    debouncedEventHandler(inputQuantity)
    setCount(inputQuantity)
  }

  const callEventHandler = (quantity: number) => {
    handleQuantityChange({
      ...data,
      quantity: quantity,
    })
  }

  const debouncedEventHandler = useMemo(
    () => debounce(callEventHandler, debounceDelay),
    [],
  )

  const MessageText = () => {
    let textColorStyle = ''
    let icon = null
    switch (messageType) {
      case 'error':
        textColorStyle = isDarkMode ? 'text-light' : 'text-danger'
        icon = <BsExclamationCircle className="me-1" />
        break
      case 'warning':
        textColorStyle = isDarkMode ? 'text-light' : 'text-warning'
        icon = <BsExclamationTriangle className="me-1" />
        break
      default:
        textColorStyle = ''
    }
    return (
      <div className={`text-center ${textColorStyle}`}>
        {icon}
        {message}
      </div>
    )
  }

  const buttonStyle: any = !disabled
    ? { lineHeight: '20px' }
    : { visibility: 'hidden' }

  return (
    <div className="mb-1 input-quantity-control">
      <InputGroup>
        {!disabled ? (
          <Button
            id="button-dec"
            variant={isDarkMode ? 'secondary' : 'primary'}
            onClick={decNum}
            className="fs-4"
            style={buttonStyle}
            disabled={disabled}
          >
            <BsFillDashCircleFill />
          </Button>
        ) : (
          <></>
        )}

        <Form.Control
          key={inputValue}
          aria-label="quantity"
          aria-describedby="button-dec"
          type="number"
          className="text-center p-0 m-0"
          value={count}
          htmlSize={10}
          onChange={handleInputChange}
          id="quantity"
          disabled={disabled}
          onKeyDown={(e) => blurInputOnKeyDown(e)}
          inputMode="numeric"
        />
        <Button
          id="button-dec"
          variant={isDarkMode ? 'secondary' : 'primary'}
          onClick={incNum}
          aria-describedby="quantity"
          className="fs-4"
          style={buttonStyle}
          disabled={disabled}
        >
          <BsFillPlusCircleFill />
        </Button>
      </InputGroup>
      <MessageText />
    </div>
  )
}

export default InputQuantityControl
