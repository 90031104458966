import React from 'react'
import { createContext, useReducer, useContext } from 'react'
import { IShippingState, IOrderFilters, IShipAddress } from './types'
import {
  defaultOrderFilters,
  SET_ORDER_FILTERS,
  CREATE_SHIPMENT_ORDER,
  GET_CUSTOMER_SHIPPING_ADDRESSES,
  SET_CC_PATH,
} from './constants'
import shippingReducer from './ShippingReducer'
import { api } from '../../helpers/Api'
import { API_SHIPPING_URL } from '../apiconfig'
import axios, { AxiosRequestConfig } from 'axios'
import toast from '../../components/molecules/RwToast'
import authHeader from '../services/AuthHeader'

export const initialState: IShippingState = {
  orderFilters: defaultOrderFilters,
  createShipmentOrder: null,
  shippingAddresses: [],
  ccPath: '',
  clearShippingContext: () => {},
  setOrderFilters: () => {},
  getOrderForCreateShipment: () => {},
  verifyShippingAddress: () => {},
  getCustomerShippingAddresses: () => {},
  setCcPath: () => {},
  clearCreateShipmentModalContext: () => {},
}

export const ShippingContext = createContext(initialState)

export const useShippingContext = () => useContext(ShippingContext)

export interface IShippingProviderProps {
  children?: React.ReactNode
}

export const ShippingProvider = (
  props: IShippingProviderProps,
): JSX.Element => {
  const { children } = props

  const [state, dispatch] = useReducer(shippingReducer, initialState)

  const clearShippingContext = () => {
    dispatch({
      type: SET_ORDER_FILTERS,
      payload: defaultOrderFilters,
    })
    clearCreateShipmentModalContext()
    clearShippingAddressesContext()
  }

  const clearShippingAddressesContext = () => {
    dispatch({
      type: GET_CUSTOMER_SHIPPING_ADDRESSES,
      payload: [],
    })
  }

  const clearCreateShipmentModalContext = () => {
    dispatch({
      type: CREATE_SHIPMENT_ORDER,
      payload: null,
    })
  }

  const verifyShippingAddress = async (shippingAddress: IShipAddress) => {
    const response = await api.post(
      `${API_SHIPPING_URL}verify-address`,
      shippingAddress,
    )

    if (response.data.success) {
      toast.success(response.data.message || 'Address verified successfully')
    } else {
      toast.error(response.data.errors.join('\n') || 'Error verifying address')
    }
  }

  const getOrderForCreateShipment = async (ordersId: string) => {
    const response = await api.get(
      `${API_SHIPPING_URL}${ordersId}/create-details`,
    )

    if (response.data.success) {
      dispatch({
        type: CREATE_SHIPMENT_ORDER,
        payload: response.data.data,
      })
    } else {
      toast.error(response.data.message || 'Error getting order details')
    }
  }

  const getCustomerShippingAddresses = async (customers_id: number) => {
    const headersValue: AxiosRequestConfig = { headers: authHeader() }
    const response = await axios.get(
      `${API_SHIPPING_URL}shipping-addresses/${customers_id}`,
      headersValue,
    )

    dispatch({
      type: GET_CUSTOMER_SHIPPING_ADDRESSES,
      payload: response.data,
    })
  }

  const setOrderFilters = async (orderFilters: IOrderFilters) => {
    dispatch({
      type: SET_ORDER_FILTERS,
      payload: orderFilters,
    })
  }

  const setCcPath = (path: string) => {
    dispatch({
      type: SET_CC_PATH,
      payload: path,
    })
  }

  const value = {
    ...state,
    setOrderFilters,
    setCcPath,
    clearShippingContext,
    getOrderForCreateShipment,
    verifyShippingAddress,
    getCustomerShippingAddresses,
    clearCreateShipmentModalContext,
  }

  return (
    <ShippingContext.Provider value={value}>
      {children}
    </ShippingContext.Provider>
  )
}
