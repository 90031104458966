import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Button, Navbar, Nav, Offcanvas, DropdownButton } from 'react-bootstrap'
import RwIconTextLink from '../../molecules/RwIconTextLink'
import RwIcon from '../../molecules/RwIcon'
import { generalBsBreakpoint } from '../../../constants/constants'
import { logoutUser } from '../../../queries/user'
import { useNavigate } from 'react-router-dom'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { IUserData } from '../../../queries/user/types'
import { userKeys } from '../../../queries/user/keyFactory'
import { useUserContext } from '../../../context/user/UserProvider'
import AccountDropdownItems from './AccountDropdownItems'
const headerNavBarBreakpoint = generalBsBreakpoint

const AccountControls: React.FC = () => {
  const queryClient = useQueryClient()
  const user = queryClient.getQueryData(userKeys.user) as IUserData
  const status = queryClient.getQueryData(userKeys.status()) as boolean

  const { initializeUserState, isDarkMode } = useUserContext()

  const navigate = useNavigate()

  const logoutUserMutation = useMutation(logoutUser, {
    onSettled: () => {
      initializeUserState()
      navigate('/login')
    },
  })

  const logoutAndCloseUserMutation = useMutation(logoutUser, {
    onSettled: () => {
      initializeUserState()
      window.close()
    },
  })
  const logout = async () => {
    await logoutUserMutation.mutate({ queryClient })
  }

  const logoutAndClose = async () => {
    logoutAndCloseUserMutation.mutate({ queryClient })
  }

  const location = useLocation()

  return (
    <Navbar.Offcanvas
      id={`offcanvasNavbar-expand-${headerNavBarBreakpoint}`}
      aria-labelledby={`offcanvasNavbarLabel-expand-lg`}
      placement="end"
    >
      <Offcanvas.Body>
        <Navbar.Collapse
          id="basic-navbar-nav"
          className="justify-content-end text-end"
        >
          <Nav className="me-auto justify-content-end p-0"></Nav>

          {!status ? (
            <Nav className="justify-content-end">
              {location.pathname !== '/login' && (
                <Link to="/login" className="px-4 ">
                  <Button
                    variant={isDarkMode ? 'dm-proline-primary' : 'primary'}
                    className={`${isDarkMode ? 'border-white' : ''}`}
                  >
                    <RwIcon icon="User" /> Login
                  </Button>
                </Link>
              )}
            </Nav>
          ) : (
            <>
              <Nav className="justify-content-end ps-3 dm-bg">
                <div className="d-inline-block">
                  <DropdownButton
                    title={
                      <span className="text-light">
                        {user?.name} <RwIcon icon="User" />
                      </span>
                    }
                    id="user_nav_dropdown"
                    align="end"
                    className={`no-focus-dropdown-button`}
                    variant="dm-bg"
                  >
                    <>
                      <h5 className="p-1">My Account</h5>
                      <AccountDropdownItems />
                      <RwIconTextLink
                        containerClassProps=""
                        textProps="Sign Out"
                        onClickProps={logout}
                        icon={<RwIcon icon="SignOut" />}
                      />
                    </>
                  </DropdownButton>
                </div>
              </Nav>
            </>
          )}
        </Navbar.Collapse>
      </Offcanvas.Body>
    </Navbar.Offcanvas>
  )
}

export default AccountControls
