import { createContext, useReducer, useContext } from 'react'
import {
  GET_PERMISSION,
  SET_PERMISSIONS_FILTER,
  GET_ROLE,
  SET_ROLES_FILTER,
  GET_USER,
  GET_USER_PROFILE,
  SET_USER_PROFILES_FILTER,
} from './constants'
import {
  IIamState,
  IPermissionData,
  IRoleData,
  IUserProfileData,
} from './types'
import iamReducer from './IamReducer'
import { IContextProviderProps } from '../../types/interfaces'
import { API_IAM_URL } from '../apiconfig'
import { api } from '../../helpers/Api'
import toast from '../../components/molecules/RwToast'
import {
  IPermissionFilter,
  IRoleFilter,
  IUserProfileFilter,
} from '../../queries/iam/types'

export const initialState: IIamState = {
  permission: null,
  permissionsFilter: {},
  role: null,
  rolesFilter: {},
  user: null,
  userProfile: null,
  userProfilesFilter: {},
  getPermission: () => {},
  setPermission: () => {},
  updatePermission: () => {},
  deletePermission: () => {},
  deletePermissions: () => {},
  setPermissionsFilter: () => {},
  clearPermissionContext: () => {},
  getRole: () => {},
  setRole: () => {},
  updateRole: () => {},
  deleteRole: () => {},
  deleteRoles: () => {},
  setRolesFilter: () => {},
  clearRoleContext: () => {},
  addRolePermissions: () => {},
  removeRolePermissions: () => {},
  getUser: () => {},
  addUserRoles: () => {},
  addUserRolesFromProfile: () => {},
  removeUserRoles: () => {},
  getUserProfile: () => {},
  setUserProfile: () => {},
  updateUserProfile: () => {},
  deleteUserProfile: () => {},
  deleteUserProfiles: () => {},
  setUserProfilesFilter: () => {},
  clearUserProfileContext: () => {},
  addUserProfileRoles: () => {},
  removeUserProfileRoles: () => {},
}

export const IamContext = createContext<IIamState>(initialState)

export const useIamContext = () => useContext(IamContext)

export const IamProvider = (props: IContextProviderProps): JSX.Element => {
  const { children } = props
  const [state, dispatch] = useReducer(iamReducer, initialState)

  const getPermission = async (id: string) => {
    const response = await api.get(`${API_IAM_URL}permissions/${id}`)
    dispatch({
      type: GET_PERMISSION,
      payload: response.data,
    })
  }

  const setPermission = async (permission: IPermissionData | null) => {
    dispatch({
      type: GET_PERMISSION,
      payload: permission,
    })
  }

  const clearPermissionContext = async () => {
    dispatch({
      type: GET_PERMISSION,
      payload: null,
    })
  }

  const updatePermission = async (
    permission: IPermissionData,
    callback: () => void,
  ) => {
    const response = await api.post(
      `${API_IAM_URL}permissions/save`,
      permission,
    )

    if (response?.data) {
      if (response.data.errors) {
        response.data.errors.forEach((error: string) => {
          toast.error(error)
        })
      } else {
        toast.success(`${response?.data?.message || 'Updated.'}`)
        dispatch({
          type: GET_PERMISSION,
          payload: response?.data,
        })
        callback()
      }
    }
  }

  const deletePermission = async (id: string, callback: () => void) => {
    const response = await api.post(`${API_IAM_URL}permissions/delete`, { id })
    await callback()
  }

  const deletePermissions = async (
    ids: Array<string>,
    callback: () => void,
  ) => {
    const response = await api.post(`${API_IAM_URL}permissions/delete`, { ids })
    await callback()
  }

  const setPermissionsFilter = async (permissionsFilter: IPermissionFilter) => {
    dispatch({
      type: SET_PERMISSIONS_FILTER,
      payload: permissionsFilter,
    })
  }

  const getRole = async (id: string) => {
    const response = await api.get(`${API_IAM_URL}roles/${id}`)
    dispatch({
      type: GET_ROLE,
      payload: response.data,
    })
  }

  const setRole = async (role: IRoleData | null) => {
    dispatch({
      type: GET_ROLE,
      payload: role,
    })
  }

  const clearRoleContext = async () => {
    dispatch({
      type: GET_ROLE,
      payload: null,
    })
  }

  const updateRole = async (role: IRoleData, callback: () => void) => {
    const response = await api.post(`${API_IAM_URL}roles/save`, role)

    if (response?.data) {
      if (response.data.errors) {
        response.data.errors.forEach((error: string) => {
          toast.error(error)
        })
      } else {
        toast.success(`${response?.data?.message || 'Updated.'}`)
        dispatch({
          type: GET_ROLE,
          payload: response?.data,
        })
        callback()
      }
    }
  }

  const deleteRole = async (id: string, callback: () => void) => {
    const response = await api.post(`${API_IAM_URL}roles/delete`, { id })
    await callback()
  }

  const deleteRoles = async (ids: Array<string>, callback: () => void) => {
    const response = await api.post(`${API_IAM_URL}roles/delete`, { ids })
    await callback()
  }

  const setRolesFilter = async (rolesFilter: IRoleFilter) => {
    dispatch({
      type: SET_ROLES_FILTER,
      payload: rolesFilter,
    })
  }

  const addRolePermissions = async (
    role_id: string,
    permission_ids: Array<String>,
    callback: () => void,
  ) => {
    const response = await api.post(`${API_IAM_URL}roles/add-permissions`, {
      role_id,
      permission_ids,
    })
    await callback()
  }

  const removeRolePermissions = async (
    role_id: string,
    permission_ids: Array<String>,
    callback: () => void,
  ) => {
    const response = await api.post(`${API_IAM_URL}roles/remove-permissions`, {
      role_id,
      permission_ids,
    })
    await callback()
  }

  const getUser = async (staff_id: string) => {
    const response = await api.get(`${API_IAM_URL}users/${staff_id}`)
    dispatch({
      type: GET_USER,
      payload: response.data,
    })
  }

  const addUserRoles = async (
    staff_id: string,
    role_ids: Array<String>,
    callback: () => void,
  ) => {
    const response = await api.post(`${API_IAM_URL}users/add-roles`, {
      staff_id,
      role_ids,
    })
    await callback()
  }

  const addUserRolesFromProfile = async (
    staff_id: string,
    profile_id: string,
    callback: () => void,
  ) => {
    const response = await api.post(
      `${API_IAM_URL}users/add-roles-from-profile`,
      {
        staff_id,
        id: profile_id,
      },
    )
    await callback()
  }

  const removeUserRoles = async (
    staff_id: string,
    role_ids: Array<String>,
    callback: () => void,
  ) => {
    const response = await api.post(`${API_IAM_URL}users/remove-roles`, {
      staff_id,
      role_ids,
    })
    await callback()
  }

  const getUserProfile = async (id: string) => {
    const response = await api.get(`${API_IAM_URL}user-profiles/${id}`)
    dispatch({
      type: GET_USER_PROFILE,
      payload: response.data,
    })
  }

  const setUserProfile = async (userProfile: IUserProfileData | null) => {
    dispatch({
      type: GET_USER_PROFILE,
      payload: userProfile,
    })
  }

  const clearUserProfileContext = async () => {
    dispatch({
      type: GET_USER_PROFILE,
      payload: null,
    })
  }

  const updateUserProfile = async (
    userProfile: IUserProfileData,
    callback: () => void,
  ) => {
    const response = await api.post(
      `${API_IAM_URL}user-profiles/save`,
      userProfile,
    )

    if (response?.data) {
      if (response.data.errors) {
        response.data.errors.forEach((error: string) => {
          toast.error(error)
        })
      } else {
        toast.success(`${response?.data?.message || 'Updated.'}`)
        dispatch({
          type: GET_USER_PROFILE,
          payload: response?.data,
        })
        callback()
      }
    }
  }

  const deleteUserProfile = async (id: string, callback: () => void) => {
    const response = await api.post(`${API_IAM_URL}user-profiles/delete`, {
      id,
    })
    await callback()
  }

  const deleteUserProfiles = async (
    ids: Array<string>,
    callback: () => void,
  ) => {
    const response = await api.post(`${API_IAM_URL}user-profiles/delete`, {
      ids,
    })
    await callback()
  }

  const setUserProfilesFilter = async (
    userProfilesFilter: IUserProfileFilter,
  ) => {
    dispatch({
      type: SET_USER_PROFILES_FILTER,
      payload: userProfilesFilter,
    })
  }

  const addUserProfilePermissions = async (
    user_profile_id: string,
    role_ids: Array<String>,
    callback: () => void,
  ) => {
    const response = await api.post(`${API_IAM_URL}user-profiles/add-roles`, {
      user_profile_id,
      role_ids,
    })
    await callback()
  }

  const removeUserProfilePermissions = async (
    user_profile_id: string,
    role_ids: Array<String>,
    callback: () => void,
  ) => {
    const response = await api.post(
      `${API_IAM_URL}user-profiles/remove-roles`,
      {
        user_profile_id,
        role_ids,
      },
    )
    await callback()
  }
  const addUserProfileRoles = async (
    id: string,
    role_ids: Array<String>,
    callback: () => void,
  ) => {
    const response = await api.post(`${API_IAM_URL}user-profiles/add-roles`, {
      id,
      role_ids,
    })
    await callback()
  }

  const removeUserProfileRoles = async (
    id: string,
    role_ids: Array<String>,
    callback: () => void,
  ) => {
    const response = await api.post(
      `${API_IAM_URL}user-profiles/remove-roles`,
      {
        id,
        role_ids,
      },
    )
    await callback()
  }

  const value = {
    ...state,
    getPermission,
    setPermission,
    updatePermission,
    deletePermission,
    deletePermissions,
    setPermissionsFilter,
    clearPermissionContext,
    getRole,
    setRole,
    updateRole,
    deleteRole,
    deleteRoles,
    setRolesFilter,
    clearRoleContext,
    addRolePermissions,
    removeRolePermissions,
    getUser,
    addUserRoles,
    addUserRolesFromProfile,
    removeUserRoles,
    getUserProfile,
    setUserProfile,
    updateUserProfile,
    deleteUserProfile,
    deleteUserProfiles,
    setUserProfilesFilter,
    clearUserProfileContext,
    addUserProfilePermissions,
    removeUserProfilePermissions,
    addUserProfileRoles,
    removeUserProfileRoles,
  }

  return <IamContext.Provider value={value}>{children}</IamContext.Provider>
}
