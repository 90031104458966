import { useQueryClient } from '@tanstack/react-query'
import { Field, ErrorMessage } from 'formik'
import { get } from 'lodash'
import React, { ReactNode } from 'react'
import { FloatingLabel, Form, InputGroup } from 'react-bootstrap'
import { userKeys } from '../../queries/user/keyFactory'
import { IUserData } from '../../queries/user/types'
import { isAuthorized } from '../../helpers/Authorization'

export interface RwFormikInputProps {
  label?: string | ReactNode
  hint?: string | ReactNode
  type?: string
  name?: string
  id?: string
  flush?: boolean
  height?: string
  floatingLabel?: boolean
  permission?: string
  disabled?: boolean
  [x: string]: any
}

const RwFormikInput: React.FC<RwFormikInputProps> = (
  props: RwFormikInputProps,
) => {
  const {
    type = 'text',
    label,
    hint = '',
    name = props.name || props.id || '',
    flush = false,
    height = null,
    floatingLabel = false,
    permission = null,
    disabled = false,
    ...rest
  } = props

  const marginClass = flush ? '' : 'mb-2'
  const heightStyle = height ? { height: height } : {}
  const queryClient = useQueryClient()
  const user = queryClient.getQueryData(userKeys.user) as IUserData

  const hasAccess = !permission || isAuthorized(user, [permission])
  const isDisabled = disabled || !hasAccess

  return (
    <div className={marginClass}>
      <Field name={name}>
        {({ field, form, meta }: any) => {
          const formErrors = get(form.errors, field.name)
          const isValid = !formErrors
          const isInvalid = get(form.touched, field.name) && !isValid
          return label && floatingLabel ? (
            <Form.Group controlId={`${name}_form_group`}>
              <InputGroup>
                <FloatingLabel label={label} className="mb-2">
                  <Form.Control
                    {...field}
                    type={type}
                    isInvalid={isInvalid}
                    feedback={formErrors}
                    placeholder={label}
                    name={name}
                    style={{ ...heightStyle }}
                    disabled={isDisabled}
                    {...rest}
                  />

                  {typeof hint === 'string' && hint.length > 0 && (
                    <Form.Text className="text-muted">{hint}</Form.Text>
                  )}
                  {React.isValidElement(hint) && hint}
                  <ErrorMessage name={field.name} />
                  <Form.Control.Feedback type="invalid" className="ms-2">
                    <>{formErrors}</>
                  </Form.Control.Feedback>
                </FloatingLabel>
              </InputGroup>
            </Form.Group>
          ) : (
            <Form.Group controlId={`${name}_form_group`} className="mb-3">
              {label && <Form.Label className="mb-1">{label}</Form.Label>}
              <Form.Control
                {...field}
                type={type}
                isInvalid={isInvalid}
                feedback={formErrors}
                placeholder={floatingLabel ? label : ''}
                name={name}
                style={{ ...heightStyle }}
                disabled={isDisabled}
                {...rest}
              />
              {typeof hint === 'string' && hint.length > 0 && (
                <Form.Text className="text-muted">{hint}</Form.Text>
              )}
              {React.isValidElement(hint) && hint}

              <Form.Control.Feedback type="invalid">
                <>{formErrors}</>
              </Form.Control.Feedback>
            </Form.Group>
          )
        }}
      </Field>
    </div>
  )
}

export default RwFormikInput
