import React, { ReactNode } from 'react'
import { Card } from 'react-bootstrap'
import { useUserContext } from '../../../context/user/UserProvider'

interface IRwCard {
  children: ReactNode
  className?: string
  flush?: boolean
  [x: string]: any
}

const RwCard: React.FC<IRwCard> = ({
  children,
  className = '',
  flush = false,
  ...rest
}) => {
  const { isDarkMode } = useUserContext()
  const marginClass = flush ? '' : 'mb-3'

  return (
    <div className={marginClass}>
      <Card
        className={`${className} ${isDarkMode ? 'dm-card-bg' : 'bg-light'}`}
      >
        {children}
      </Card>
    </div>
  )
}

export default RwCard
