import React from 'react'
import { HEADER_HEIGHT_DESKTOP } from '../../../constants/constants'
import { useSiteContext } from '../../../context/site/SiteProvider'
import { isEmbedded } from '../../../helpers'

const HeaderSpacer: React.FC = () => {
  const { showTopNav, userShowTopNav } = useSiteContext()

  return (
    <>
      {!isEmbedded() && showTopNav && userShowTopNav ? (
        <div
          className={`spacer-under-header-nav-bar`}
          style={{
            minHeight: `${HEADER_HEIGHT_DESKTOP}px`,
            height: `${HEADER_HEIGHT_DESKTOP}px`,
          }}
        />
      ) : (
        <></>
      )}
    </>
  )
}

export default HeaderSpacer
