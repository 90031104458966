import React, { useState } from 'react'
import { Form, Modal } from 'react-bootstrap'
import { useUserContext } from '../../../../context/user/UserProvider'
import AvatarEditor from 'react-avatar-editor'
import RwButton from '../../../molecules/RwButton/RwButton'
import RwCloseButton from '../../../molecules/RwButton/RwCloseButton'
import { CDN_URI } from '../../../../constants/cdn'
import Dropzone from 'react-dropzone'

export interface IAvatarModal {
  show: boolean
  handleClose: () => void
  handleSave: (image_data: string) => void
  [x: string]: any
}

const AvatarModal: React.FC<IAvatarModal> = ({
  show,
  handleClose,
  handleSave,
  ...rest
}) => {
  const { isDarkMode } = useUserContext()

  let editor: any

  const [picture, setPicture] = useState({
    cropperOpen: false,
    img: '',
    zoom: 2,
    croppedImg: `${CDN_URI}_images/icons/76Icons-02.png`,
  })

  const handleSliderChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPicture({
      ...picture,
      zoom: parseFloat(e.target.value),
    })
  }

  const setEditorRef = (ed: any) => {
    editor = ed
  }

  const handleSaveClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    const canvasScaled = editor.getImageScaledToCanvas()
    const croppedImg = canvasScaled.toDataURL()
    handleSave(croppedImg)
  }

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let url = e.target.files ? URL.createObjectURL(e.target.files[0]) : ''
    setPicture({
      ...picture,
      img: url,
      cropperOpen: true,
    })
  }

  const handleFileDrop = (file: any) => {
    let url = file ? URL.createObjectURL(file) : ''
    setPicture({
      ...picture,
      img: url,
      cropperOpen: true,
    })
  }

  return (
    <Modal show={show} animation={false}>
      <Modal.Header>
        <Modal.Title>Change Avatar</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <RwButton>
          <input type="file" accept="image/*" onChange={handleFileChange} />
        </RwButton>
        <Dropzone
          onDrop={(dropped) => handleFileDrop(dropped[0])}
          noClick
          noKeyboard
        >
          {({ getRootProps, getInputProps }) => (
            <div {...getRootProps()}>
              <AvatarEditor
                ref={setEditorRef}
                image={picture.img}
                width={300}
                height={300}
                border={50}
                color={[255, 255, 255, 0.6]} // RGBA
                scale={picture.zoom}
                rotate={0}
              />
              <input {...getInputProps()} />
            </div>
          )}
        </Dropzone>
        <Form.Range
          className="text-light"
          value={picture.zoom}
          min={1}
          max={10}
          step={0.1}
          onChange={handleSliderChange}
        />
      </Modal.Body>
      <Modal.Footer>
        <RwButton onClick={handleSaveClick}>Save</RwButton>
        <RwCloseButton onClick={handleClose} />
      </Modal.Footer>
    </Modal>
  )
}

export default AvatarModal
